import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setWorldId, setWorldName } from "../../reducers/reducers";
import styled from "styled-components";
import { FaArrowRight, FaBars, FaBell, FaCheckCircle, FaChevronLeft, FaChevronRight, FaComments, FaTrash, FaUpload } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import {  ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from "../../Firebase/Firebase";
import { Helmet } from "react-helmet";
 




function EditWorld() {
  const {worldId} = useParams();
  const skillerId = localStorage.getItem('skillerId');
 
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {

    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getWorldTrailer`, {
          params: { worldId , skillerId}
        });
        setElements(response.data);
 
      } catch (error) {
 
      }
    


  }
  fetchResources();
 


  const getWorldCategories = async() => {
    const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterWorlds')
    setCategories(response.data)
 
 }
 getWorldCategories();
 const getWorld = async() => {
  const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldForEdit', {params: {worldId}})
  setWorldNameI(response.data.worldName)
  setWorldDescription(response.data.worldDescription)
  setThumbnail(response.data.thumbnail)
  setPrice(response.data.price)
  setPublished(response.data.published)
  if(response.data.price){
    setIsPaid(true)
  }
  setAdvisorAmount(response.data.advisorAmount)
  if(response.data.advisorAmount){
    setIsWorldAllowingAdvisors(true)
  }
  setCategory(response.data.category)

  setWorld(response.data)
}
getWorld();
  const fetchProfilePicture = async () => {
    try {
        const response = await axios.get(
            'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
            {
              pramas: {skillerId},
               responseType: 'blob' }
        );



        if (response.data.size > 0) {
            // Blob has data, create an object URL
            const url = URL.createObjectURL(response.data);
            setProfilePictureSrc(url);
        } else {
            // Blob is empty, use the Firebase image URL
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    } catch (error) {

        // Optionally set a fallback image if there's an error
        setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
    }
};



fetchProfilePicture();
}, []);

        const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [world, setWorld] = useState('')
 
  const [profilePictureSrc, setProfilePictureSrc] = useState('')
  const [activeTab, setActiveTab] = useState('world')
 
 
 
  const handleLogout = async () => {
    // Clear localStorage

    localStorage.clear();

    // Clear specific keys if clear() doesn't work
    localStorage.removeItem("authenticated");
    localStorage.removeItem("permission");
    localStorage.removeItem("skillerToken");
    localStorage.removeItem("lastExternalReferrer");
    localStorage.removeItem("lastExternalReferrerTime");

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear all cookies
    const clearAllCookies = () => {
        document.cookie.split(";").forEach((cookie) => {
            const cookieName = cookie.split("=")[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        });
    };
    clearAllCookies();


    // Delay to ensure clearing is complete
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Redirect to login page
    navigate("/skiller-signIn");
};



 
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};


const handleWorldTab = () => {
  setActiveTab('world')
}
const handleTrailerTab = () => {
  setActiveTab('trailer')
}









 
const setWorldPublished = async() => {
  await axios.patch('https://sell-skill.com/api/endpoints/setWorldPublished', {worldId, skillerId})
}
 
 
const [worldName, setWorldNameI] = useState("");
    const [sidebarVisible, setSidebarVisible] = useState(true);
const [worldDescription, setWorldDescription] = useState("");
const [thumbnail, setThumbnail] = useState(null);
const [isPaid, setIsPaid] = useState(false); // Added for free/paid choice
const [price, setPrice] = useState("");
const [isWorldAllowingAdvisors, setIsWorldAllowingAdvisors] = useState(false); // Updated for advisor choice
const [advisorAmount, setAdvisorAmount] = useState("");
const [category, setCategory] = useState([]);
const [others, setOthers] = useState("");
const dispatch = useDispatch();
const navigate = useNavigate();
const [categories, setCategories] = useState([])
const [published, setPublished] = useState(true)
 
const [stepsCompleted, setStepsCompleted] = useState({
  worldName: false,
  worldDescription: false,
  thumbnail: false,
  price: false,
  advisorAmount: false,
  categories: false,
  price: false, // New
  advisorAmount: false, // New  
});
    const [showModal, setShowModal] = useState(false);

const handleShowModal = () => {

    setShowModal(true);
};
const handleThumbnailChange = (e) => {
  const file = e.target.files[0];
  if (file && file.size > 5 * 1024 * 1024) {
    alert("File size must be less than 5MB.");
    return;
  }
  setThumbnail(file);
  setStepsCompleted({ ...stepsCompleted, thumbnail: true });
};

const handleCategoryClick = (cat) => {
  setCategory((prevCategories) =>
    prevCategories.includes(cat)
      ? prevCategories.filter((c) => c !== cat)
      : [...prevCategories, cat]
  );
  setStepsCompleted({ ...stepsCompleted, categories: true });
};

const handleAddOther = () => {
  if (others.trim() && !category.includes(others.trim())) {
    setCategory((prevCategories) => [...prevCategories, others.trim()]);
    setOthers("");
    setStepsCompleted({ ...stepsCompleted, categories: true });
  }
};

const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};


useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

const [elements, setElements] = useState([]);
  const [lessonTitle, setLessonTitle] = useState('');
  const [lessonDescription, setLessonDescription] = useState('');

  const addElementToPage = (type) => {
    setElements([
      ...elements,
      { id: uuidv4(), type, content: '', choices: [], correctAnswer: '', order: elements.length + 1 },
    ]);
  };

  const removeElement = (index) => {
    const updatedElements = elements
      .filter((_, i) => i !== index)
      .map((el, i) => ({ ...el, order: i + 1 }));
    setElements(updatedElements);
  };

  const updateElementContent = (index, content) => {
    const updatedElements = [...elements];
    updatedElements[index].content = content;
    setElements(updatedElements);
  };

  const handleFileUpload = async (index, file) => {
    try {
      const fileRef = ref(storage,  `instructor_content/${file.name}_${uuidv4()}`);
      const uploadTask = uploadBytesResumable(fileRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Calculate progress percentage
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgress((prev) => ({ ...prev, [index]: progress }));
        },
        (error) => {
 
          setUploadProgress((prev) => ({ ...prev, [index]: 0 })); // Reset progress bar in case of error
        },
        async () => {
          // Upload completed successfully
          const downloadURL = await getDownloadURL(fileRef);
          setUploadProgress((prev) => ({ ...prev, [index]: 0 })); // Reset progress bar after upload is complete
          updateElementContent(index, downloadURL);
        }
      );
    } catch (error) {
 
      setUploadProgress((prev) => ({ ...prev, [index]: 0 })); // Reset progress bar in case of error
    }
  };
  

  const renderElement = (element, index) => {
    return (
      <ElementWrapper key={element.id}>
        {(() => {
          switch (element.type) {
            case 'video':
            case 'image':
            case 'pdf':
              return (
                <>
                  <Input
                    type="file"
                    accept={element.type === 'video' ? 'video/*' : element.type === 'photo' ? 'image/*' : '.pdf'}
                    onChange={(e) => handleFileUpload(index, e.target.files[0])}
                  />
                  {uploadProgress[index] > 0 && (
                    <ProgressBar>
                      <Progress style={{ width: `${uploadProgress[index]}%` }} />
                    </ProgressBar>
                  )}
                </>
              );
            case 'text':
              return (
                <TextArea
                  placeholder="Enter text content"
                  value={element.content}
                  onChange={(e) => updateElementContent(index, e.target.value)}
                />
              );
            default:
              return null;
          }
        })()}
      </ElementWrapper>
    );
  };
  

  const levelNumber = useSelector((state) => state.allow.levelNumber);
  const lessonNumber = useSelector((state) => state.allow.lessonNumber);

  const saveCourseToFirebase = async () => {
    try {
      const modifiedElements = elements.map((el) => ({
        type: el.type,
        content: el.content,
        choices: el.choices || [],
        correctAnswer: el.correctAnswer || '',
        order: el.order,
      }));

      const response = await fetch('https://sell-skill.com/api/endpoints/uploadWorldTrailer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          worldId :worldId,
          elements: modifiedElements,
          worldName,
          levelNumber,
          lessonNumber,
          lessonTitle,
          lessonDescription,
          skillerId
        }),
      });

      const result = await response.json();
      console.log('Course saved:', result);
      navigate('/add-level');
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };

 
 

const handleSubmit = async (e) => {
  e.preventDefault();
  if (Number(advisorAmount) > Number(price)) {
    alert('Advisor amount must be less than the level price.');
    return;
  }
  if (isPaid && (!price || isNaN(price))) {
    alert("Please enter a valid price.");
    return;
  }

  if (isWorldAllowingAdvisors && (!advisorAmount || isNaN(advisorAmount))) {
    alert("Please enter a valid advisor amount.");
    return;
  }

  const formData = new FormData();
  formData.append("worldId", worldId);
  formData.append("worldName", worldName);
  formData.append("skillerId", skillerId);
  formData.append("price", isPaid ? price : 0);
  formData.append("advisorAmount", isWorldAllowingAdvisors ? advisorAmount : 0);
  formData.append("worldDescription", worldDescription);
  if(thumbnail){
  formData.append("thumbnail", thumbnail);}
  formData.append("isWorldAllowingAdvisors", isWorldAllowingAdvisors);
  formData.append("category", JSON.stringify(category));

  try {
    const response = await axios.patch(
      "https://sell-skill.com/api/endpoints/editWorld",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (response.status === 200) {
      dispatch(setWorldId(worldId));
      dispatch(setWorldName(worldName));
      navigate(`/add-level/${worldId}`);
    } else {
      alert("Failed to add the world. Please try again.");
    }
  } catch (error) {
 
    alert("An error occurred. Please check your inputs and try again.");
  }
};

const handleConfirmPublish = async () => {
 
      await setWorldPublished();
      setShowModal(false);
      
  
};

  return (
      <PageContainer>
          <Helmet>
    <title>Edit World | sell skill</title>
  </Helmet>


    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>




        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>

{    sidebarVisible &&          <Sidebar>
  {   activeTab === "world" &&           <>  <h3>Fields</h3>
    {[
      { name: "World Name", key: "worldName" },
      { name: "World Description", key: "worldDescription" },
      { name: "Thumbnail", key: "thumbnail" },
      { name: "Price", key: "price" },
      { name: "Advisor Amount", key: "advisorAmount" },
      { name: "Categories", key: "categories" },
    ].map((step) => (
      <SidebarItem key={step.key}>
        <span>{step.name}</span>
        {stepsCompleted[step.key] && <CompletedMark />}
      </SidebarItem>
    ))}</>}


  {   activeTab === "trailer" &&           <>  {['Video', 'image', 'Text', 'PDF'].map((type) => (
              <SidebarItem key={type} onClick={() => addElementToPage(type.toLowerCase())}>
                {type}
              </SidebarItem>
            ))}</>}
  </Sidebar>}


      {
          !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
           {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
       </ToggleSidebarButton>
  }
<ContentWrapper>
  <MainContent>


                  <Header>
      <TabContainer>
          <Tab
              active={activeTab === 'world'}
              onClick={handleWorldTab}
          >
              World
          </Tab>
          <Tab
              active={activeTab === 'trailer'}
              onClick={ handleTrailerTab}
          >
              Trailer
          </Tab>
          <Tab
              active={activeTab === 'trailer'}
              onClick={ () => navigate(`/edit-world/invite-advisors/${worldId}`)}
          >
             Invite advisors
          </Tab>
      </TabContainer>
                
                {!Boolean(world.published) && (
                    <UploadButtonWrapper>

                        <UploadButton onClick={() => handleShowModal()}>
                            <FaUpload style={{ marginRight: '5px' }} /> Publish
                        </UploadButton>
                    </UploadButtonWrapper>
                )}
                
            


  </Header>
  {    activeTab === "world" &&    <form onSubmit={handleSubmit}>
    
  <Button
                  style={{
                    backgroundColor: "#27ae60", // Green color
                    borderColor: "#27ae60",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem", // Add space between text and icon
                    padding: "0.5rem 1rem",
                  }}
                  onClick={() => navigate('/add-level')}
                >
                 levels<FaArrowRight />
                </Button>
            <FormElement>
              <label>World Name:</label>
              <input
                type="text"
                placeholder={world.worldName || "enter worldName"}
                value={worldName}
                onChange={(e) => {
                  setWorldNameI(e.target.value);
                  setStepsCompleted({ ...stepsCompleted, worldName: e.target.value.length > 0 });
                }}
                
              />
            </FormElement>
            <FormElement>
              <label>World Description:</label>
              <textarea
                              placeholder={world.worldDescription || "enter worldDescription"}
                value={worldDescription}
                onChange={(e) => {
                  setWorldDescription(e.target.value);
                  setStepsCompleted({ ...stepsCompleted, worldDescription: e.target.value.length > 0 });
                }}
                
              ></textarea>
            </FormElement>
            <FormElement>
              <label>Thumbnail:</label>
              <FileInputContainer>
                <input
                  type="file"
                  id="thumbnail"
                  onChange={handleThumbnailChange}
                  accept="image/*"
                  
                />
                <label htmlFor="thumbnail">Change Thumbnail</label>
              </FileInputContainer>
            </FormElement>
            <FormElement>
            <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={styles.th}>Option</th>
            <th style={styles.th}>Yes</th>
            <th style={styles.th}>No</th>
            <th style={styles.th}>Price</th>
          </tr>
        </thead>
        <tbody>
          {/* World Type */}
          <tr>
            <td style={styles.td}>Is this world paid?</td>
            <td style={styles.td}>
              <input type="radio" name="worldType" checked={isPaid} onChange={() => setIsPaid(true)} />
            </td>
            <td style={styles.td}>
              <input type="radio" name="worldType" checked={!isPaid} onChange={() => setIsPaid(false)} />
            </td>
            <td style={styles.td}>
              {isPaid && (
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="price..."
                  min="0"
                />
              )}
            </td>
          </tr>

 

          {/* Allow other advisors */}
 
        </tbody>
      </table>
    </div>


              </FormElement>
 
              <div>
                {categories?.map(
                  (cat) => (
                    <CategoryButton
                      type="button"
                      key={cat}
                      selected={category?.includes(cat)}
                      onClick={() => handleCategoryClick(cat)}
                    >
                      {cat}
                    </CategoryButton>
                  )
                )}
              </div>
           
            <AddWorldButton type="submit">Save Changes</AddWorldButton>
          </form>}

          {
            activeTab === "trailer" && <>
            <div>World Trailer</div>
            {elements.map((element, index) => (
              <ElementContainer key={element.id}>
                {renderElement(element, index)}
                <RemoveButton onClick={() => removeElement(index)}><FaTrash/></RemoveButton>
              </ElementContainer>
            ))}
            <SaveButton onClick={saveCourseToFirebase}>Save Trailer</SaveButton>
            </>
          }

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Publish</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to publish this world?</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmPublish}>
            Yes, Publish
        </Button>
    </Modal.Footer>
</Modal>
        </MainContent>
        </ContentWrapper>
      </PageContainer>
  );
}

export default EditWorld;




const AddWorldButton = styled.button`
  width: 100%;  
  padding: 15px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1a242f;
  }
`


const PageContainer = styled.div`
    background-color: #f8f9fa; /* Light background color */
    min-height: 100vh; /* Full viewport height */
    padding: 60px 20px 20px; /* Top padding for navbar, and general spacing on sides */
    box-sizing: border-box; /* Ensure padding is included in width and height */
    display: flex; /* Allows flexible layout for child elements */
    flex-direction: column; /* Stack children vertically */
`;

 


 

const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #27ae60;
  transition: width 0.4s ease;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ElementContainer = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  position: relative;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;





const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.1em;
  cursor: pointer; /* Add pointer effect for better UX */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent; /* No initial color */
  color: inherit; /* Maintain default color */

  ${({ activeTab }) =>
    activeTab === 'trailer' &&
    `
    &:hover {
      font-size: 1.2em; /* Slightly larger size */
      background-color: #27ae60; /* Green background */
      color: white; /* White text for better contrast */
      transition: all 0.3s ease; /* Smooth transition */
    }
  `}
`;


 

const SaveButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #219150;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;


const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 15px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;

const MainContent = styled.div`
  margin-left: 205px; /* Align content to start after the sidebar */
  @media (max-width: 768px) {
    margin-left: 0; /* No margin for small screens */
  }
`;


const ContentWrapper = styled.div`
padding-top: 120px;
  padding-left: 205px; /* Adjust to accommodate the sidebar width */
    @media (max-width: 768px) {
    padding-top: 90px;
        padding-left: 30px;
    }

`;






const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;




const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 60px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;



const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 207px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;











 const CompletedMark = styled(FaCheckCircle)`
   margin-left: 10px;
   font-size: 1.2em;
   color: #27ae60; /* Green for the check mark */
 `;
 const FileInputContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 5px;

   input {
     display: none;
   }

   label {
     background-color: #27ae60;
     color: white;
     padding: 10px 20px;
     border-radius: 5px;
     cursor: pointer;
     transition: background-color 0.3s ease;

     &:hover {
       background-color: #1e8449;
     }
   }
 `;

 const CategoryButton = styled.button`
   margin: 5px;
   padding: 10px 15px;
   background-color: ${(props) => (props.selected ? "#27ae60" : "#ecf0f1")};
   color: ${(props) => (props.selected ? "white" : "#2c3e50")};
   border: 1px solid ${(props) => (props.selected ? "#27ae60" : "#bdc3c7")};
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease;

   &:hover {
     background-color: ${(props) => (props.selected ? "#1e8449" : "#d5d8dc")};
     color: ${(props) => (props.selected ? "white" : "#2c3e50")};
   }
 `;

 const FormElement = styled.div`
 margin-bottom: 20px;

 label {
   display: block;
   margin-bottom: 5px;
   font-weight: bold;
 }

 input,
 textarea,
 button {
   width: 100%;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   margin-top: 5px;

   @media (max-width: 768px) {
     width: 90%; /* Reduce the width for smaller screens */
   }

   @media (max-width: 480px) {
     width: 80%; /* Further reduce width for very small screens */
   }
 }

 button[type="button"] {
   width: auto;
   background-color: #27ae60;
   color: white;
   border: none;
   cursor: pointer;

   &:hover {
     background-color: #1e8449;
   }
 }
`;


const styles = {
  th: {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#f4f4f4",
  },
  td: {
    border: "1px solid #ddd",
    padding: "10px",
  },
};






