import React, {  useState } from "react";
import { Button, Card, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import styled, { createGlobalStyle } from "styled-components";
import {  ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../Firebase/Firebase";
import { FaFilePdf, FaImage } from "react-icons/fa";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
    font-family: Arial, Helvetica, sans-serif;
    color: #2c3e50;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const MainCard = styled(Card)`
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  max-width: 700px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
`;

const UploadCard = styled(Card)`
  background-color: #2c3e50;
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;

  &:hover {
    background-color: #27ae60;
    color: #ffffff;
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

const UploadButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #27ae60;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #219150;
    color: white;
  }
`;

const Input = styled.input`
  display: none;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const ImagePreviewSquare = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid #27ae60;
`;

function Certifications() {
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();
  const skillerId = localStorage.getItem('skillerId');
  const uploadToFirebase = async (file, folder) => {
    const storageRef = ref(storage, `${folder}/${uuidv4()}_${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleAddCertification = async (type, file, title, description) => {
    setLoading(true); // Start loading
    try {
      const folder = type === "image" ? "image-certificates" : "pdf-certificates";
      const firebaseUrl = await uploadToFirebase(file, folder);

      setCertifications((prev) => [
        ...prev,
        { type, file, title, description, url: firebaseUrl, id: uuidv4() },
      ]);
    } catch (error) {
 
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleNextClick = async () => {
    setLoading(true); // Start loading
    const imageCertifications = certifications.filter((cert) => cert.type === "image");
    const pdfCertifications = certifications.filter((cert) => cert.type === "pdf");

    try {
      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerPdfCertificate", {
        skillerId : skillerId,
        certifications: pdfCertifications,
      });

      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerImageCertificate", {
        skillerId : skillerId,
        certifications: imageCertifications,
      });

      navigate("/picture");
    } catch (error) {
     
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const imagePreviews = certifications.filter((cert) => cert.type === "image");

  const imageCount = imagePreviews.length;
  const pdfCount = certifications.filter((cert) => cert.type === "pdf").length;



  return (
    <StyledContainer>
            <Helmet>
        <title>Upload Your Certifications | Sell-Skill</title>
        <meta name="description" content="Upload and showcase your certifications to enhance your profile on Sell-Skill." />
        <meta name="keywords" content="certifications, skills, upload documents, Sell-Skill, online learning, professional growth" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Upload Your Certifications | Sell-Skill" />
        <meta property="og:description" content="Upload and showcase your certifications to enhance your profile on Sell-Skill." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sell-skill.com/certifications" />
        <meta property="og:image" content="https://sell-skill.com/static/logo192.png" />
        <link rel="canonical" href="https://sell-skill.com/certifications" />
      </Helmet>
      <GlobalStyle />
      <MainCard>
        <Title>Certifications Upload</Title>

        {loading && (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Spinner animation="border" variant="success" /> Uploading, please wait...
          </div>
        )}

        <UploadCard onClick={() => document.getElementById("image-upload").click()}>
          <FaImage /> <b>Upload IMAGE</b>
          {imageCount > 0 && <NotificationBadge>{imageCount}</NotificationBadge>}
        </UploadCard>
        <Input
          id="image-upload"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            const file = e.target.files[0];
            const title = prompt("Enter the title for the certificate:");
            const description = prompt("Enter the description for the certificate:");
            handleAddCertification("image", file, title, description);
          }}
        />

        <ImagePreviewContainer>
          {imagePreviews.map((cert, index) => (
            <ImagePreviewSquare
              key={index}
              src={URL.createObjectURL(cert.file)}
              alt={cert.title}
            />
          ))}
        </ImagePreviewContainer>

        <UploadCard onClick={() => document.getElementById("pdf-upload").click()}>
          <FaFilePdf /> <b>Upload PDF</b>
          {pdfCount > 0 && <NotificationBadge>{pdfCount}</NotificationBadge>}
        </UploadCard>
        <Input
          id="pdf-upload"
          type="file"
          accept="application/pdf"
          onChange={(e) => {
            const file = e.target.files[0];
            const title = prompt("Enter the title for the PDF:");
            const description = prompt("Enter the description for the PDF:");
            handleAddCertification("pdf", file, title, description);
          }}
        />

        <UploadButton onClick={handleNextClick} disabled={loading}>
          {loading ? "Uploading..." : "Next"}
        </UploadButton>
      </MainCard>
    </StyledContainer>
  );
}

export default Certifications;