import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const ProgrammingFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
 
       
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FProgrammingFullWidthSlider%2Fhq720.jpg?alt=media&token=98ec8a22-9d2b-4613-92d9-6a39d9601138" alt="Slide 1" className="slider-image" />
 
                </div>
        
               
                   
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FProgrammingFullWidthSlider%2F1_g82d4nr7pnVj7IQDT996pw.jpg?alt=media&token=750165bf-8baa-4931-abb6-48a434370e60" alt="Slide 1" className="slider-image" />
 
                </div>

            
            </Slider>
        </div>
    );
};

export default ProgrammingFullWidthSlider;
