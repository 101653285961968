import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const DigitalMarketingFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
 
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FMarketingFullWidthSlider%2F2085686_7b51_11.webp?alt=media&token=b72e6d90-c9aa-4810-b478-ffee17a7bf19" alt="Slide 2" className="slider-image" />
 
                </div>
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FMarketingFullWidthSlider%2F1691662_4ecb_32.webp?alt=media&token=ecb81d43-39c3-4f94-a87b-f638a9bad22c" alt="Slide 3" className="slider-image" />
 
                </div>
                






  
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FMarketingFullWidthSlider%2F4387912_746e_2.webp?alt=media&token=6235dbe9-36bf-4db4-a8ca-fdf52592654c" alt="Slide 3" className="slider-image" />
 
 
                </div>

       

          
   
            </Slider>
        </div>
    );
};

export default DigitalMarketingFullWidthSlider;
