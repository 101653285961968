import React from 'react'

function ClientsAndEmployees() {
  return (
    <div>
      clients and employees
    </div>
  )
}

export default ClientsAndEmployees
