import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
 
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ref, getDownloadURL,   uploadBytesResumable } from 'firebase/storage';
import styled from 'styled-components';
import './InsideLesson.css'
import { FaBars, FaBell,  FaChevronLeft, FaChevronRight, FaComments, FaTrash } from 'react-icons/fa';
import { storage } from '../../Firebase/Firebase';
import { Helmet } from 'react-helmet';
 
function InsideLesson() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const {worldId, levelId, lessonId} = useParams()

////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const skillerId = localStorage.getItem('skillerId');
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('');
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};


 
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {

    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { 
                  params: {skillerId},
                  responseType: 'blob' }
            );
 
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
 
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
 
  localStorage.clear();
 
  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

 
  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee


  const [resources, setResources] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedResourceType, setSelectedResourceType] = useState('')
  const [newResource, setNewResource] = useState({ type: '', content: '', choices: [], correctAnswer: '' });
 
  const navigate = useNavigate();
  const handleSidebarSelection = (type) => {
    setSelectedResourceType(type);
    setNewResource({ type, content: '', choices: [], correctAnswer: '', file: null });
    setShowModal(true);
  };
  
 
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
          params: { worldId, levelId, lessonId }
        });
        setResources(response.data);
      } catch (error) {
 
      }
    };

    fetchResources();
  }, [worldId, levelId, lessonId]);






  const handleFileUpload = async (file) => {
    try {
        const fileRef = ref(storage, `lesson-resources/${uuidv4()}_${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Calculate progress percentage
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setUploadProgress(progress);
            },
            (error) => {
          
                setUploadProgress(0); // Reset progress bar in case of error
            },
            async () => {
                // Upload completed successfully
                const downloadURL = await getDownloadURL(fileRef);
                setUploadProgress(0); // Reset progress bar after upload is complete
                return downloadURL;
            }
        );

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed', null, reject, async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(downloadURL);
            });
        });
    } catch (error) {
 
        setUploadProgress(0); // Reset progress bar in case of error
        return null;
    }
};   

  // const handleFileUpload = async (file) => {
  //   try {
     
  //     const fileRef = ref(storage, `lesson-resources/${uuidv4()}_${file.name}`);
  //     await uploadBytes(fileRef, file);
  //     return await getDownloadURL(fileRef);
  //   } catch (error) {
  //     console.error('Error uploading file:', error);
  //     return null;
  //   }
  //};   

  const addResource = async () => {
    if (newResource.type !== 'text' && newResource.type !== 'quiz' && newResource.file) {
      const url = await handleFileUpload(newResource.file);
      setResources([...resources, { ...newResource, content: url }]);
    } else {
      setResources([...resources, { ...newResource }]);
    }
    setNewResource({ type: '', content: '', choices: [], correctAnswer: '', file: null });
    setShowModal(false);
  };

  const deleteResource = (index) => {
    setResources(resources.filter((_, i) => i !== index));
  };

  const saveResources = async () => {
    try {
 
      await axios.patch('https://sell-skill.com/api/endpoints/editResources', {
        skillerId,
        worldId,
        levelId,
        lessonId,
        elements: resources,
      });
      alert('Resources saved successfully!');
    } catch (error) {
 
    }
  };

  const renderResource = (resource, index) => {       
    switch (resource.type) {
      case 'video':
        return (
          <div key={index} className="resource-card">
            <h3>Video</h3>
     
            <div className="video-wrapper">
  <video controls src={resource.url} className="resource-video" />
</div>


            <Button 
  style={{ 
    display: 'block', 
    margin: '10px auto', 
    width: '80%' 
  }} 
  variant="danger" 
  onClick={() => deleteResource(index)}
>
<FaTrash/>
</Button>

          </div>
        );
        case 'image':
          return (
            <div key={index} className="resource-card">
              <h3>Image</h3>
              <img src={resource.content || resource.url} className="resource-image" alt="Resource" />


              <Button 
  style={{ 
    display: 'block', 
    margin: '10px auto', 
    width: '80%' 
  }} 
  variant="danger" 
  onClick={() => deleteResource(index)}
>
<FaTrash/>
</Button>

            </div>
          );
        
      case 'pdf':
        return (
          <div key={index} className="resource-card">
            <h3>PDF</h3>
            <a href={resource.content} target="_blank" rel="noopener noreferrer">View PDF</a>
            <Button 
  style={{ 
    display: 'block', 
    margin: '10px auto', 
    width: '80%' 
  }} 
  variant="danger" 
  onClick={() => deleteResource(index)}
>
<FaTrash/>
</Button>

          </div>
        );
      case 'text':
        return (
          <div key={index} className="resource-card">
            <h3>Text</h3>
            <p>{resource.content}</p>
            <Button 
  style={{ 
    display: 'block', 
    margin: '10px auto', 
    width: '80%' 
  }} 
  variant="danger" 
  onClick={() => deleteResource(index)}
>
  <FaTrash/>
</Button>

          </div>
        );
        case 'quiz':
          return (
            <div key={index} className="resource-card">
              <h3>Quiz</h3>
              {/* Ensure content is a string */}
              <p>{typeof resource.content === 'string' ? resource.content : JSON.stringify(resource.content)}</p>
              <ul>
                {/* Map through choices and ensure they're rendered as strings */}
                {Array.isArray(resource.choices) ? resource.choices.map((choice, i) => (
                  <li key={i}>{choice}</li>
                )) : <p>Invalid choices</p>}
              </ul>
              <p>Correct Answer: {resource.correctAnswer}</p>
              <Button 
                style={{ 
                  display: 'block', 
                  margin: '10px auto', 
                  width: '80%' 
                }} 
                variant="danger" 
                onClick={() => deleteResource(index)}
              >
                <FaTrash/>
              </Button>
            </div>
          );
        
      default:
        return null;
    }
  };

  return (
    <>
  <Helmet>
    <title>Add Resources | sell skill</title>
  </Helmet>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>



        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>
        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
    {/* Sidebar */}
{  sidebarVisible &&   <Sidebar>
      <SidebarItem onClick={() => handleSidebarSelection('video')}>Video/audio</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('image')}>Photo</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('pdf')}>PDF</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('text')}>Text</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('quiz')}>Quiz</SidebarItem>
    </Sidebar>}
               {
                  !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
                   {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
               </ToggleSidebarButton>
          }
    {/* Main Content */}
    <ContentWrapper>
    <MainContent>
      <h1>Lesson Resources</h1>
      <div className="resources-container" style={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    maxWidth: 'calc(100% - 320px)', // Adjust based on sidebar width
    overflow: 'hidden', // Prevent overflow
    margin: '0 auto', // Center content
    padding: '10px',
}}>
  {resources.map((resource, index) => renderResource(resource, index))}
</div>

 
      <Button variant="success" onClick={saveResources}>Save</Button>


    {/* Modal */}
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <ModalHeader>
        <Modal.Title>Add New Resource</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Form>
          {['video', 'image', 'pdf'].includes(selectedResourceType) && (
            <Form.Group>
              <Form.Label>File</Form.Label>
              <Form.Control type="file" onChange={(e) => setNewResource({ ...newResource, file: e.target.files[0] })} />
            </Form.Group>

            
          )}
          {uploadProgress > 0 && (
  <div className="progress-bar">
    <div
      className="progress"
      style={{ width: `${uploadProgress}%`, backgroundColor: '#27ae60', height: '10px' }}
    ></div>
  </div>
)}

          {selectedResourceType === 'text' && (
            <Form.Group>
              <Form.Label>Content</Form.Label>
              <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
            </Form.Group>
          )}
          {selectedResourceType === 'quiz' && (
            <>
              <Form.Group>
                <Form.Label>Question</Form.Label>
                <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Choices</Form.Label>
                <Form.Control type="text" placeholder="Separate choices with commas" onChange={(e) => setNewResource({ ...newResource, choices: e.target.value.split(',') })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Correct Answer</Form.Label>
                <Form.Control type="text" value={newResource.correctAnswer} onChange={(e) => setNewResource({ ...newResource, correctAnswer: e.target.value })} />
              </Form.Group>
            </>
          )}
        </Form>
      </ModalBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        <Button variant="primary" onClick={addResource}>Add</Button>
      </Modal.Footer>
    </Modal>
    </MainContent>
    </ContentWrapper>
  </>
  );
}

export default InsideLesson;


  

 

const ModalHeader = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
    padding: 15px;
`;

const ModalBody = styled.div`
  padding: 20px;

    padding: 25px;
  line-height: 1.6;
`;



 

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.1em;
  cursor: pointer; /* Add pointer effect for better UX */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent; /* No initial color */
  color: inherit; /* Maintain default color */

  ${({ activeTab }) =>
    activeTab === 'trailer' &&
    `
    &:hover {
      font-size: 1.2em; /* Slightly larger size */
      background-color: #27ae60; /* Green background */
      color: white; /* White text for better contrast */
      transition: all 0.3s ease; /* Smooth transition */
    }
  `}
`;

 

const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;

 

const MainContent = styled.div`
  margin-left: 205px; /* Align content to start after the sidebar */
  @media (max-width: 768px) {
    margin-left: 0; /* No margin for small screens */
  }
`;


const ContentWrapper = styled.div`
padding-top: 300px;
  padding-left: 205px; /* Adjust to accommodate the sidebar width */
    @media (max-width: 768px) {
    padding-top: 170px;
        padding-left: 30px;
    }

`;






const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;



 

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 70px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 200px; /* Narrower sidebar for small screens */
        top: 150px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 180; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 140px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 147px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;





 
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { v4 as uuidv4 } from 'uuid';
// import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';

// function InsideLesson() {
//   const [resources, setResources] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [newResource, setNewResource] = useState({ type: '', content: '', choices: [], correctAnswer: '' });
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const worldName = useSelector((state) => state.allow.worldName);
//   const levelNumber = useSelector((state) => state.allow.levelNumber);
//   const lessonNumber = useSelector((state) => state.allow.lessonNumber);
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);
//   const lessonId = useSelector((state) => state.allow.lessonId);

//   useEffect(() => {
//     const fetchResources = async () => {
//       try {
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
//           params: { worldId, levelId, lessonId }
//         });
//         setResources(response.data);
//       } catch (error) {
//         console.error("Error fetching resources:", error);
//       }
//     };

//     fetchResources();
//   }, [worldId, levelId, lessonId]);

//   const handleFileUpload = async (file) => {
//     try {
//       const storage = getStorage();
//       const fileRef = ref(storage, `lesson-resources/${uuidv4()}_${file.name}`);
//       await uploadBytes(fileRef, file);
//       return await getDownloadURL(fileRef);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       return null;
//     }
//   };

//   const addResource = async () => {
//     if (newResource.type !== 'text' && newResource.type !== 'quiz' && newResource.file) {
//       const url = await handleFileUpload(newResource.file);
//       setResources([...resources, { ...newResource, content: url }]);
//     } else {
//       setResources([...resources, { ...newResource }]);
//     }
//     setNewResource({ type: '', content: '', choices: [], correctAnswer: '', file: null });
//     setShowModal(false);
//   };

//   const deleteResource = (index) => {
//     setResources(resources.filter((_, i) => i !== index));
//   };

//   const saveResources = async () => {
//     try {
//       console.log('req.body going through edit resources ==========================---=-=---=-=-=-> > > > > > > >  ',worldId, levelId, lessonId, 'resources ==================-=-=-=-> > > > ',resources)
//       await axios.patch('https://sell-skill.com/api/endpoints/editResources', {
//         worldId,
//         levelId,
//         lessonId,
//         elements: resources,
//       });
//       alert('Resources saved successfully!');
//     } catch (error) {
//       console.error("Error saving resources:", error);
//     }
//   };

//   const renderResource = (resource, index) => {
//     switch (resource.type) {
//       case 'video':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Video</h3>
//             <video controls src={resource.content} width="100%" />
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'photo':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Image</h3>
//             <img src={resource.content} alt="Resource" style={{ width: '100%' }} />
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'pdf':
//         return (
//           <div key={index} className="resource-card">
//             <h3>PDF</h3>
//             <a href={resource.content} target="_blank" rel="noopener noreferrer">View PDF</a>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'text':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Text</h3>
//             <p>{resource.content}</p>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'quiz':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Quiz</h3>
//             <p>{resource.content}</p>
//             <ul>
//               {resource.choices.map((choice, i) => (
//                 <li key={i}>{choice}</li>
//               ))}
//             </ul>
//             <p>Correct Answer: {resource.correctAnswer}</p>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <h1>Lesson Resources</h1>
//       <Button variant="primary" onClick={() => setShowModal(true)}>Add Resource</Button>
//       <div className="resources-container">
//         {resources.map((resource, index) => renderResource(resource, index))}
//       </div>
//       <Button variant="success" onClick={saveResources}>Save</Button>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add New Resource</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group>
//               <Form.Label>Type</Form.Label>
//               <Form.Control as="select" value={newResource.type} onChange={(e) => setNewResource({ ...newResource, type: e.target.value })}>
//                 <option value="">Select Type</option>
//                 <option value="video">Video</option>
//                 <option value="photo">photo</option>
//                 <option value="pdf">PDF</option>
//                 <option value="text">Text</option>
//                 <option value="quiz">Quiz</option>
//               </Form.Control>
//             </Form.Group>

//             {['video', 'photo', 'pdf'].includes(newResource.type) && (
//               <Form.Group>
//                 <Form.Label>File</Form.Label>
//                 <Form.Control type="file" onChange={(e) => setNewResource({ ...newResource, file: e.target.files[0] })} />
//               </Form.Group>
//             )}

//             {newResource.type === 'text' && (
//               <Form.Group>
//                 <Form.Label>Content</Form.Label>
//                 <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
//               </Form.Group>
//             )}

//             {newResource.type === 'quiz' && (
//               <>
//                 <Form.Group>
//                   <Form.Label>Question</Form.Label>
//                   <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>Choices</Form.Label>
//                   <Form.Control type="text" placeholder="Separate choices with commas" onChange={(e) => setNewResource({ ...newResource, choices: e.target.value.split(',') })} />
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>Correct Answer</Form.Label>
//                   <Form.Control type="text" value={newResource.correctAnswer} onChange={(e) => setNewResource({ ...newResource, correctAnswer: e.target.value })} />
//                 </Form.Group>
//               </> 
//             )}
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
//           <Button variant="primary" onClick={addResource}>Add</Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default InsideLesson;
