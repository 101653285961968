import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Form } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const MainCard = styled(Card)`
  background-color: white; /* White for the card background */
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50; /* Dark text */
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: 10px 0;
  background-color: #27ae60; /* Green button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #1e8449; /* Darker green on hover */
    color: white;
  }
`;

const Input = styled.input`
  display: none;
`;

 
const ImagePreview = styled.img`
  display: block;
  margin: 20px auto;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #27ae60; /* Green border */
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;

function Picture() {
  const [picture, setPicture] = useState(null);
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/cover-picture");
  };
  const skillerId = localStorage.getItem('skillerId')

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("picture", file);
      formData.append("name", file.name);
      formData.append("skillerId", skillerId)

      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerPicture", formData);
      setPicture(file);
    } catch (error) {
      console.error("Error uploading picture:", error);
    }
  };

  return (
    <PageContainer>
            <Helmet>
        <title>Upload Your Profile Picture | Sell-Skill</title>
        <meta name="description" content="Upload a profile picture to personalize your Sell-Skill account and build credibility." />
        <meta name="keywords" content="profile picture, upload image, Sell-Skill, user profile, personal branding" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Upload Your Profile Picture | Sell-Skill" />
        <meta property="og:description" content="Upload a profile picture to personalize your Sell-Skill account and build credibility." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sell-skill.com/picture" />
        <meta property="og:image" content="https://sell-skill.com/static/logo192.jpg" />
        <link rel="canonical" href="https://sell-skill.com/picture" />
      </Helmet>
    <StyledContainer>
      <MainCard>
        <Title>A Nice Profile Picture</Title>
        <Form>
          <StyledButton as="label" htmlFor="file-upload">
            Choose a Picture
            <Input
              type="file"
              id="file-upload"
              accept="image/*"
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </StyledButton>
          {picture && <ImagePreview src={URL.createObjectURL(picture)} alt="Preview" />}
          <StyledButton onClick={handleNextClick}>Next</StyledButton>
        </Form>
      </MainCard>
    </StyledContainer>
    </PageContainer>
  );
}

export default Picture;



























// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Button, Card, Container, Form } from 'react-bootstrap';
// import axios from 'axios';
// import styled, { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #E6F0FF; /* Light Blue background */
//     color: #2F4F4F; /* Dark Gray for text */
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   padding: 20px;
// `;

// const MainCard = styled(Card)`
//   background-color: #FAFAFA; /* Off-White for the card background */
//   border-radius: 20px;
//   width: 100%;
//   max-width: 600px;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
//   padding: 30px;
// `;

// const Title = styled.h2`
//   text-align: center;
//   color: #4169E1; /* Royal Blue for the title */
//   margin-bottom: 30px;
//   font-size: 24px;
//   font-weight: bold;
// `;

// const UploadButton = styled(Button)`
//   width: 100%;
//   margin: 20px 0;
//   background-color: #FFD700; /* Golden Yellow for the button */
//   border: none;
//   padding: 12px;
//   font-size: 16px;
//   font-weight: bold;
//   border-radius: 8px;

//   &:hover {
//     background-color: #4169E1; /* Royal Blue on hover */
//   }
// `;

// const Input = styled.input`
//   display: none;
// `;

// const Label = styled.label`
//   display: block;
//   color: #2F4F4F; /* Dark Gray for label */
//   cursor: pointer;
//   margin-top: 10px;
// `;

// const ImagePreview = styled.img`
//   display: block;
//   margin: 20px auto;
//   width: 140px;
//   height: 140px;
//   border-radius: 20px;
//   object-fit: cover;
//   border: 2px solid #4169E1; /* Royal Blue border */
// `;

// function Picture() {
//   const [picture, setPicture] = useState('');
//   const navigate = useNavigate();

//   const handleNextClick = () => {
//     navigate("/letter");
//   };

//   const handleUpload = async (e) => {
//     const formData = new FormData();
//     formData.append('picture', e);
//     formData.append('name', 'image/png');
//     await axios.post('https://sell-skill.com/api/endpoints/insertSkillerPicture', formData);
//     setPicture(e);
//   };

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer>
//         <MainCard>
//           <Title>A Nice Profile Picture</Title>
//           <Form>
//             <UploadButton as="label" htmlFor="file-upload">
//               Choose a Picture
//               <Input
//                 type="file"
//                 id="file-upload"
//                 name="picture"
//                 onChange={(e) => handleUpload(e.target.files[0])}
//               />
//             </UploadButton>
//             {picture && <ImagePreview src={URL.createObjectURL(picture)} alt="Preview" />}
//             <UploadButton onClick={handleNextClick}>Next</UploadButton>
//           </Form>
//         </MainCard>
//       </StyledContainer>
//     </>
//   );
// }

// export default Picture;
