import React, {  useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBell, FaComments, FaSearch,  FaStar, FaUpload,   FaChevronDown, FaChevronLeft, FaChevronRight,  FaArrowRight,  FaChalkboardTeacher, FaCheckCircle, FaShoppingCart,   FaPlayCircle,    FaBars, FaUserFriends, FaFileAlt } from 'react-icons/fa';
import { Button, Card, Col, Container,   Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {  setWorldId } from '../../../../../../../reducers/reducers';
  
import { requestNotificationPermission } from '../../../../../../../Firebase/Firebase';
import { Helmet } from 'react-helmet';
 
function MySubscriptions() {
    let filteredWorlds;
    const skillerId = localStorage.getItem("skillerId");
 
    const [worlds, setWorlds] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Define the maximum length before truncation

    const toggleExpand = () => setIsExpanded(!isExpanded);
 
 
         const [lengthOfUnreadedNotifications, setLengthOfUnreadedNotifications] = useState(0);
           
const [isSearchVisible, setIsSearchVisible] = useState(false);
 
const [searchTerm, setSearchTerm] = useState('');



const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};

 
const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

 

 
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



 
 
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
 
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(true);
 
 
    const [sidebarVisible, setSidebarVisible] = useState(true);
 
 
    const [selectedWorldComments, setSelectedWorldComments] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
 
 
 
    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });


    const [categories, setCategories] = useState([
        "Freelancing",
        "E-commerce",
        "Dropshipping",
        "Affiliate Marketing",
        "Content Creation (YouTube, TikTok, Blogging)",
        "Social Media Management",
        "Stock Trading & Investing",
        "Cryptocurrency & NFTs",
        "Online Coaching & Consulting",
        "Digital Marketing Services",
        "Selling Online Courses",
        "Print-on-Demand",
        "Virtual Assistance",
        "Real Estate Investing",
        "Passive Income Strategies",
        "Remote Work Opportunities"
    ]); // Example categories


 

        const filteredCategories = categories.filter((cat) =>
            cat.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [lengthOfUnreadedMessages, setLengthOfUnreadedMessages] = useState(0)
  
      useEffect(() => {
 
        const fetchWorlds = async () => {
           
       try
 {
 
                const response = await axios.get('https://sell-skill.com/api/endpoints/getAllWorlds');
                 
                    setWorlds(response.data);

                
                }
                catch(error){
 
                    fetchWorlds();
                }finally{
                    setLoading(false)
                }
          
                   
        
        };

        fetchWorlds();


        const  getNumberOfUnReadedMessages = async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnReadedMessages', {params: {skillerId}})
            console.log('response.data from getNumberOfUnReadedMessages =================-=-=-=-=-=-> > > > >  ',response.data)
            setLengthOfUnreadedMessages(response.data)
 
        }
       getNumberOfUnReadedMessages()
        const  getNumberOfUnreadedNotifications = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnreadedNotifications', {params: {skillerId}})
            setLengthOfUnreadedNotifications(response.data)
        }
        getNumberOfUnreadedNotifications();

          const fetchProfilePicture = async () => {
              try {
                  const response = await axios.get(
                      'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                      {
                        params: {skillerId},
                        responseType: 'blob' }
                  );
        
                  console.log('Fetched blob:', response.data);
                 
                  if (response.data.size > 0) {
                      // Blob has data, create an object URL
                      const url = URL.createObjectURL(response.data);
                      setProfilePictureSrc(url);
                  } else {
                      // Blob is empty, use the Firebase image URL
                      setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                  }
              } catch (error) {
                  console.error('Error fetching profile picture:', error);
                  // Optionally set a fallback image if there's an error
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          };

 
 

 


 

 

           const getWorldCategories = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterWorlds')
              setCategories(response.data)
 
           }
           getWorldCategories();


                 requestNotificationPermission();

        //    getMatesGetMates();
 
 
 
      fetchProfilePicture();
 
    }, []);


 

 
    const handleLogout = async () => {
        // Clear localStorage
 
        localStorage.clear();
 
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
 
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    
 


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);  
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

 

 
   
 
    
     filteredWorlds = worlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
            
                    return false;
                }
            })());
        
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    
    
    
 

    const handleGetInWorld = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/levels/${worldId}`);
    };

    const handleViewWorldTrailer = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/world-trailer/${worldId}`);
    };

    const handleAddToCart = async (worldId) => {
        await axios.post('https://sell-skill.com/api/endpoints/addToCart', { skillerId, worldId });
    };

    const handleWorldComments = async(worldId) => {
        setShowCommentsModal(true);
        const response =  await axios.get('https://sell-skill.com/api/endpoints/getWorldComments', {params: {worldId}})
        console.log('comments from get world comments ==================--=--------------------=-=-=-=-=-= > > > > > > > > > >   ', response.data.comments)
        setSelectedWorldComments(response.data.comments);
 
    };

    const closeCommentsModal = () => {
        setShowCommentsModal(false);
    };
 
 
 
    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

 
 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    


    const pageContainerRef = useRef(null); // Ref for the PageContainer

 


 
 

 

    return (
        <PageContainer ref={pageContainerRef}  >
                <Helmet>
      {/* Primary Meta Tags */}
      <title>Learn & Earn with Expert Bootcamps & Courses | Sell-Skill</title>
      <meta 
        name="description" 
        content="Explore expert-led bootcamps, courses, and skill-based worlds on Sell-Skill. Learn, grow, and earn by teaching others. Find the best learning experience today!"
      />
      <meta 
        name="keywords" 
        content="online courses, skill learning, bootcamps, earn by teaching, Sell-Skill, learning platform, best online courses, make money with skills" 
      />
      <meta name="author" content="Sell-Skill" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.sell-skill.com/" />

      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Learn & Earn with Expert Bootcamps & Courses | Sell-Skill" />
      <meta 
        property="og:description" 
        content="Join Sell-Skill, the ultimate platform to learn, teach, and earn! Explore high-quality bootcamps and courses tailored to your growth."
      />
      <meta property="og:url" content="https://www.sell-skill.com/" />
      <meta property="og:image" content="https://www.sell-skill.com/logo192.png" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Learn & Earn with Expert Bootcamps & Courses | Sell-Skill" />
      <meta 
        name="twitter:description" 
        content="Boost your skills with expert-led courses on Sell-Skill. Learn, grow, and start earning by teaching today!"
      />
      <meta name="twitter:image" content="https://www.sell-skill.com/logo192.png" />
      <meta name="twitter:site" content="@SellSkill" />

      {/* Schema Markup (JSON-LD) for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "EducationalOrganization",
          "name": "Sell-Skill",
          "url": "https://www.sell-skill.com/",
          "logo": "https://www.sell-skill.com/logo192.png",
 
          "description": "Sell-Skill offers world-class courses, bootcamps, and skill-based learning experiences. Learn, grow, and earn money by teaching others.",
          "offers": {
            "@type": "Offer",
            "name": "Online Courses & Bootcamps",
            "url": "https://www.sell-skill.com",
            "priceCurrency": "USD",
         
          }
        })}
      </script>
    </Helmet>
        <Container   >
    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>


        <NavLinks isOpen={isMenuOpen}>
        <a href = "/my-subscriptions">My Subscribtions</a>
        <a href = "/manage-uploads">My Uploads</a>
        <a href = "/my-cart">My Cart</a>

        <a href = "/my-exams">My Exams</a>
 
        </NavLinks>

        <NavIcons>
 
     {
                isSearchVisible ? (
                    <input
                        type="text"
                        placeholder="Search Worlds"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                ): (
                    <FaSearch onClick={handleSearchIconClick} />
                )
            }
  

                
  

            
          
  <ChatIconWrapper>
            {lengthOfUnreadedNotifications > 0 &&
            <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
            }
            <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
            </ChatIconWrapper>
    
            <ChatIconWrapper>
      {lengthOfUnreadedMessages > 0 && (
        <NotificationBadge>{lengthOfUnreadedMessages.toString()}</NotificationBadge>
      )}
      <FaComments onClick={() => navigate("/my-chats")} title="Messages" size={24} />
    </ChatIconWrapper>
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href = "/my-cart">My Cart</a>
              <a href = "/my-subscriptions">My Subscriptions</a>
              <a href="/financial-management">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href = "/clients-&-employees">Clients & Employees</a>
              <a href="/my-exams">My Exams</a>


 
     
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out <FaArrowRight/>
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      



                        <ContentContainer >
             {sidebarVisible && (
         <Sidebar>

      

 <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <h5 style={{ marginBottom: '20px', color: '#2c3e50' }}>Filters</h5>

   
 


 <div
            style={{
                background: '#f9f9f9',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
 

            {/* Category Filter */}
            <div className="mb-3">
                <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:                     <button
                        onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                    >
                        {filters.category || 'Any...'}
                        <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
                    </button></label>
                <div className="position-relative">

                    {showCategoryDropdown && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                width: '100%',
                                background: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                zIndex: 1000,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {/* Search Bar */}
                            <div
                                style={{
                                    padding: '10px',
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search categories..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>

                            {/* Category List */}
                            <ul
                                style={{
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <li
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                        color: '#2c3e50',
                                    }}
                                    onClick={() => {
                                        setFilters({ ...filters, category: '' });
                                        setShowCategoryDropdown(false);
                                    }}
                                >
                                    Any
                                </li>
                                {filteredCategories.map((cat) => (
                                    <li
                                        key={cat}
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            color: '#2c3e50',
                                        }}
                                        onClick={() => {
                                            setFilters({ ...filters, category: cat });
                                            setShowCategoryDropdown(false);
                                        }}
                                    >
                                        {cat}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
     

    {/* Price Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Price Range:</label>
        <div className="d-flex">
            <input 
                type="number" 
                placeholder="Min" 
                className="form-control me-2" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [Number(e.target.value) || 0, filters.priceRange[1]] 
                })} 
            />
            <input 
                type="number" 
                placeholder="Max" 
                className="form-control" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [filters.priceRange[0], Number(e.target.value) || 1000] 
                })} 
            />
        </div>
    </div>

    {/* Rating Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Rating:</label>
        <select
            value={filters.minRate}
            onChange={(e) => setFilters({ ...filters, minRate: Number(e.target.value) })}
            className="form-select"
        >
            <option value="0">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>
    </div>

    {/* Publish Date Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Publish Date:</label>
        <div className="d-flex">
            <input 
                type="date" 
                className="form-control me-2"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [new Date(e.target.value), filters.publishDateRange[1]] 
                })} 
            />

        </div>

        <div className="d-flex">
        <input 
                type="date" 
                className="form-control"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [filters.publishDateRange[0], new Date(e.target.value)] 
                })} 
            />
            

        </div>


    </div>

    {/* Minimum Students Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Students:</label>
        <input 
            type="number" 
            value={filters.minStudents} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minStudents: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>

    {/* Allow Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Allow Advisors:</label>
        <select 
            value={filters.allowAdvisors === null ? 'any' : filters.allowAdvisors.toString()}
            className="form-select"
            onChange={(e) => setFilters({ ...filters, allowAdvisors: e.target.value === 'any' ? null : e.target.value === 'true' })}
        >
            <option value="any">Any</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>

    {/* Minimum Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Advisors:</label>
        <input 
            type="number" 
            value={filters.minAdvisors} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minAdvisors: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>
</div>


 

 
 
 
 
         </Sidebar>
     )}


     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}






                <MainContent style={{ marginTop: '200px', padding: '50px' }}  >
                <Header>
    <TabContainer>
        <Tab
            active={true}
         
        >
             Learn
        </Tab>

        <Tab   onClick={() => navigate('/earn')}>
                         Earn
                    </Tab>

 

    </TabContainer>

{  !showDropdown &&  <>
    
    <UploadButtonWrapper>
        <UploadButton onClick={() => navigate('/add-world')}>
            <FaUpload style={{ marginRight: '5px' }} /> 
        </UploadButton> 
    </UploadButtonWrapper>
    <PostsButtonWrapper>
        <UploadButton onClick={() => navigate('/posts')}>
            <FaFileAlt style={{ marginRight: '5px' }} /> 
        </UploadButton>
    </PostsButtonWrapper>

    <MatesButtonWrapper>
    <UploadButton onClick={() => navigate('/mates')}>
            <FaUserFriends style={{ marginRight: '5px' }} /> 
        </UploadButton>
    </MatesButtonWrapper>

    </>}
    
     
    
</Header>
 

           
 
    
 
    


 
    <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>

            <Row>
                     
                {filteredWorlds.map((world, index) => {
                    const totalRate = world?.rates?.reduce((sum, r) => sum + r.rate, 0);
                    const averageRate = totalRate / world?.rates?.length || 0;

                    return (
                        <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">

                            <Card style={{ overflow: 'hidden', width: '100%' }}>
                            <Card.Header className="d-flex align-items-center">
{        world?.publisher?.picture &&                    <a href={`/profile/${world?.publisher?._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${world?.publisher?.picture?.picture?.contentType};base64,${bufferToBase64(world?.publisher?.picture?.picture?.data?.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         world?.publisher?.name &&               <a
                            href={`/profile/${world?.publisher?._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {world?.publisher?.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < world?.publisher?.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>


{   world?.worldThumbnail  &&
                                <CardImage
                                    variant="top"
                                    src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`}
                                    alt="World Thumbnail"
                                    style={{
                                        width: '100%',
                                        height: 'auto', /* Maintain aspect ratio */
                                        objectFit: 'cover', /* Prevent stretched images */
                                      }}
                                />}
                                <Card.Body>
                                    <Card.Title>{world?.worldName}</Card.Title>
                                    <Card.Text>{ world?.worldDescription}</Card.Text>
                                    { world?.price !== 0 ? (
  <Card.Text>Price: ${world?.price}</Card.Text>
) : (
  <Card.Text>
    <span className="badge bg-success animate__animated animate__pulse animate__infinite">FREE</span>
  </Card.Text>
)}

                                { world?.advisorAmount &&   <Card.Text>Adviosr pudget: ${world?.advisorAmount}</Card.Text>}
                                { /*           world.students.length > 0 &&                        <Card.Text>
                                        <FaUserGraduate /> {world.students.length.toString()} Students

                                    </Card.Text>*/}
{                         world?.advisors?.length > 0 &&           <Card.Text>
                                        <FaChalkboardTeacher /> {world?.advisors?.length?.toString()} Advisors

                                    </Card.Text>}
                                    {world?.isWorldAllowingAdvisors && (
                                        <Card.Text>
                                            <FaCheckCircle color="green" /> Allowing Advisors
                                        </Card.Text>
                                    )}
                                    <Card.Text>
                                        Date of Publish: {new Date(world?.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>
                                    <div className="mb-3">
                                        {Array.from({ length: 5 }, (_, i) => (
                                            <FaStar
                                                key={i}
                                                color={i < averageRate ? '#f39c12' : '#dcdcdc'}
                                            />
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleGetInWorld(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                            Get In
                                        </Button>
                                        {/* (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Add To Cart
                                            </Button>
                                        ) */}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleViewWorldTrailer(world._id)}
                                        >
                                            <FaPlayCircle style={{ marginRight: '5px' }} />
                                            View Trailer
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => handleWorldComments(world?._id)}
                                        >
                                            <FaComments style={{ marginRight: '5px' }} />
                                            Comments
                                        </Button>
                                    </div>
                                </Card.Body>   
                            </Card>
                  
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>
 


  
   

                    
 

 
 

{
    loading && (

        <div
        className="loading-circle"
        style={{
            position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
            zIndex: 1000, // Optional: Ensure it stays on top of other elements
        }}
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#27ae60"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="90 150"
                strokeDashoffset="0"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
    )
}


                </MainContent>
            </ContentContainer>

            <Modal show={showCommentsModal} onHide={closeCommentsModal}>
  <Modal.Header closeButton>
    <Modal.Title>Comments</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedWorldComments?.length > 0 ? (
      selectedWorldComments.map((comment) => (
        <div
          key={comment._id}
          style={{
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
 
          <a
            href={`/profile/${comment?.commenter?.[0]?._id}`} 
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              marginRight: '10px',
            }}
          >
            <img
                src={`data:${comment?.commenter?.[0]?.picture.contentType};base64,${bufferToBase64(comment?.commenter?.[0]?.picture.picture.data.data)}`}

              alt="Commenter"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            />
          </a>
          <div>
            <a
              href={`/profile/${comment?.commenter?.[0]?._id}`} 
              style={{
                textDecoration: 'none',
                color: '#007bff',
                fontWeight: 'bold',
              }}
            >
              {comment?.commenter?.[0]?.name || 'Unknown'}
            </a>
            <p style={{ margin: 0 }}>
              <a
                href={`/profile/${comment?.commenter?.[0]?._id}`} 
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                ⭐ {comment?.commenter?.[0]?.rate || 'No rating'}
              </a>
            </p>
            <p style={{ marginTop: '5px' }}>
            {comment?.comment?.length > maxLength && !isExpanded
                ? `${comment.comment.substring(0, maxLength)}...`
                : comment.comment}
            {comment?.comment?.length > maxLength && (
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        marginLeft: '5px',
                        textDecoration: 'underline'
                    }}
                    onClick={toggleExpand}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </span>
            )}
        </p>
          </div>
        </div>
      ))
    ) : (
      <p>No comments available for this world!</p>
    )}
  </Modal.Body>
</Modal>
        </Container>
        </PageContainer>
    );
}

export default MySubscriptions;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
 
    
  /* No media queries needed as it will always match the screen size */
`;
 

const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;


 

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;

 

 const MainContent = styled.div`

 
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
  
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;

 

const ContentWrapper = styled.div`
 
    margin-top: -100px;
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
  
`;

 

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 48px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

 

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 120px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 110px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;
 
 
const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 25px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;
const MatesButtonWrapper = styled.div`
  position: fixed;
  bottom: 115px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;
const PostsButtonWrapper = styled.div`
  position: fixed;
  bottom: 160px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 105px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;


const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;



 


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height for scrolling */
 
    box-sizing: border-box;
`;

const ChatIconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;