import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
 
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaBell, FaChevronLeft, FaChevronRight, FaComments } from 'react-icons/fa';

function ClientsChats1() {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
 


  useEffect(() => {
    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1', {params: {skillerId}})
      setMessages(response.data)
      console.log('senders to check for picture && length of the messages ==========>> > > > > > > > > >>>>   >>>>  >> ',response.data)
    }
    getSendersInfo();
  }, [])
  const handleNameClick = (withId) => {
 
    navigate(`/chat/${withId}`)

  }









  


////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const skillerId = localStorage.getItem('skillerId')
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
 
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};

 
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { 
                  params: {skillerId},
                  responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee


 


const bufferToBase64 = (buffer) => {
  if (!Array.isArray(buffer)) return '';

  // Flatten the array if it contains nested arrays
  const flatArray = buffer.flat();

  // Process the flat array in manageable chunks
  const chunkSize = 10000; // Process 10,000 bytes at a time
  let base64String = '';
  for (let i = 0; i < flatArray.length; i += chunkSize) {
      const chunk = flatArray.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
  }

  return btoa(base64String);
};



  return (


    <PageContainer>

<Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>

          <ContentContainer>

          {sidebarVisible && (
            <Sidebar>
  <SidebarNav>
  <SidebarLink href="/my-chats" className='active'>All Messages</SidebarLink>
    <SidebarLink href="/my-advisors-chats">My Advisors Messages</SidebarLink>
    <SidebarLink href="/my-students-chats">My Students Messages</SidebarLink>
    <SidebarLink href="/my-clients-chats" className='active'>My Clients Messages</SidebarLink>
    <SidebarLink href="/my-employees-chats">My Employees Messages</SidebarLink>
  </SidebarNav>
</Sidebar>

     )}



     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}


            <MainContent>
 
            <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>

            <Row>
           
 
            { messages.length > 0 ? (
  messages.map((message) => (
    <ChatItem key={message._id} onClick={() => handleNameClick(message.withId)}>
      <ChatImage
        src={
          message?.senderPicture?.picture?.data?.data
            ? `data:${message.senderPicture.contentType};base64,${bufferToBase64(
                message.senderPicture.picture.data.data
              )}`
            : "https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a"
        }
        alt="Sender Profile"
      />
      <ChatDetails>
        <ChatName><a href=''></a>{message.senderName || "Mate"}</ChatName>
        {message.lengthOfUnreadedMessages > 0 && (
          <NotificationBadge>{message.lengthOfUnreadedMessages.toString()}</NotificationBadge>
        )}
      </ChatDetails>
    </ChatItem>
  ))
) : (     <div  >
 
        {/* Text */}
        <h2 className="text-xl font-semibold mb-2 text-gray-800">
          You don't have any contract chat yet! <span className="text-green-500">Try applying for some job</span> and land your dream job today!
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          apply for job, sell your skills, and start earning on sell skill!
        </p>
        {/* CTA Button */}
        <Button
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600"
          onClick={() => navigate("/earn")}
        >
          search jobs
        </Button>
   
    </div>)   }

 
              </Row>
              </Container>
              </ContentWrapper>


              </MainContent>
              </ContentContainer>
              </Container>
    </PageContainer>
  )
}

export default ClientsChats1

















const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

 

const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;











 





 const MainContent = styled.div`

     margin-top: 80px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;



 




const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;

 


const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;




 


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

 
const NotificationBadge = styled.div`
 
  right: 40px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;


const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


 
const ChatItem = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: #f0f0f0;
    }
`;

const ChatImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
`;

const ChatDetails = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const ChatName = styled.span`
    font-weight: bold;
    color: #333;
`;

const UnreadBadge = styled.span`
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 20px;
    font-weight: bold;
`;




const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const ChatHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    background: #2c3e50;
    color: white;
    font-weight: bold;
`;

const MessagesContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    background: #f4f4f4;
`;

const MessageInputContainer = styled.div`
    display: flex;
    padding: 10px;
    background: white;
    border-top: 1px solid #ddd;
`;

const MessageInput = styled.input`
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
`;

const SendButton = styled.button`
    padding: 10px 20px;
    margin-left: 10px;
    background: #27ae60;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
`;



const Sidebar = styled.div`
  width: 350px;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  z-index: 900;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  background: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #e8e8e8;
  }

  &.active {
    background: #27ae60;
    color: white;
  }
`;
