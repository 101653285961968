import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificatioinId } from '../reducers/reducers';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Container } from 'react-bootstrap';
import { FaBars, FaBell, FaComments } from 'react-icons/fa';

function Notifications1() {
  const [allNotifications, setAllNotifications] = useState([]);
  const skillerId = localStorage.getItem('skillerId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch all notifications from the backend
    const getAllNotifications = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAllNotifications', {params: {skillerId}});
        setAllNotifications(response.data);
        console.log('response data from getAllNotifications =========-=-=-=-============ ---------> =----------------=-------->> ',response.data)
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    getAllNotifications();
  }, []);

  // Dispatch the ID of the clicked notification
  const handleNotificationClick = (notificationId) => {
    dispatch(setNotificatioinId(notificationId))
    navigate(`/notification/${notificationId}`)
    console.log('Notification ID:', notificationId); // Replace this with a dispatch or your desired action
  };



 

////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
 
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
 
 

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { 
                  params: {skillerId},
                  responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
 
  localStorage.clear();
 

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();
 

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee


 
  return (
   
    <PageContainer>

<Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>

          <ContentContainer>
            <MainContent>

       
            <>
  {allNotifications.length > 0 ? (
    allNotifications.map((notification) => (
      <NotificationCard key={notification._id} onClick={() => handleNotificationClick(notification._id)}>
        <NotificationText>{notification.title}</NotificationText>
        <NotificationButton>View</NotificationButton>
      </NotificationCard>
    ))
  ) : (
    <div
    className="loading-circle"
    style={{
        position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
        zIndex: 1000, // Optional: Ensure it stays on top of other elements
    }}
>
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
    >
        <circle
            cx="25"
            cy="25"
            r="20"
            stroke="#27ae60"
            strokeWidth="5"
            strokeLinecap="round"
            strokeDasharray="90 150"
            strokeDashoffset="0"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="1s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
</div>
  )}
</>


 
              </MainContent>
              </ContentContainer>
              </Container>
    </PageContainer>
  );
}

export default Notifications1;

 

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;

  
const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;
 


const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;



const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
`;


 
const MainContent = styled.div`
  margin-top: 100px;
  flex-grow: 1;
  padding: 20px;
  width: 100%;
  max-width: 600px; /* Limit width for better readability */
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 90%; /* Make it more mobile-friendly */
  }
`;


 
const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;




 
const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;
 

 const NotificationCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, background 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    background: #f8f9fa;
  }
`;

const NotificationText = styled.p`
  margin: 0;
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
`;

const NotificationButton = styled(Button)`
  font-size: 0.9rem;
  background: #27ae60;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background 0.2s;

  &:hover {
    background: #219150;
  }
`;
