import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Alert, Card, Form, Container, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeAuth, setProviderOrClientId } from "../reducers/reducers";
import styled from "styled-components";
import {   getToken } from "firebase/messaging";
import { messaging } from "../Firebase/Firebase";
import { v4 as uuidv4 } from "uuid";

function UploadJobForMe () {
    const _id = uuidv4()
    const skillerId = localStorage.getItem('skillerId')
  const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [term, setTerm] = useState('short')
  const [clientResult, setClientResult] = useState(true);
  const navigate = useNavigate();
 const [experience, setExperience] = useState('junior')
 const [budget, setBudget] = useState(1)

   const [category, setCategory] = useState([]);
   const [others, setOthers] = useState("");
  const[categories, setCategories] = useState([])
  const [clicked, setClicked] = useState(false)
const handleUploadJob  = async () => {
    await axios.post('https://sell-skill.com/api/endpoints/uploadJob', {_id, title, description, term, experience, budget, category, skillerId})
 
 
}
 
 
const handleCategoryClick = (cat) => {
  setCategory((prevCategories) => {
    if (prevCategories.includes(cat)) {
      return prevCategories.filter((c) => c !== cat);
    } else {
      return [...prevCategories, cat];
    }
  });
};

const handleAddOther = () => {
  if (others.trim() && !category.includes(others)) {
    setCategory((prevCategories) => [...prevCategories, others]);
    setOthers("");
  }
};


useEffect(() => {
  const getWorldCategories = async() => {
    const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterSkillers', {params: skillerId})
    setCategories(response.data)

 }
 getWorldCategories();
}, [])

  return (
    <>
      <Helmet>
        <title>Sell-Skill | Upload Job</title>
        <meta
          name="description"
          content="Sign in to Sell-Skill to connect with clients or providers, share knowledge, or access services."
        />
        <meta name="keywords" content="Sell-Skill, login, knowledge sharing, client provider platform" />
      </Helmet>
  <PageContainer>
        <StyledContainer>
          <StyledCard>
            <Card.Body>
              <CardTitle>
                <b> title and description</b>
              </CardTitle>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <StyledInput
                    type="title"
                    placeholder="title"
                    onChange={(e) => setTitle(  e.target.value )}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <StyledInput
                    type="description"
                    placeholder="description"
                    onChange={(e) => setDescription(  e.target.value)}
                  />
                </Form.Group>
                <b>term</b>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">short term</label>
                <input
                  type="radio"
                  id="short term"
                  name="term length"
                  checked={term === 'short'}
                  onChange={() => setTerm('short')}
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">medium term</label>
                <input
                  type="radio"
                  id="medium term"
                  name="term length"
                  checked={term === 'medium'}
                  onChange={() => setTerm('medium')}
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">long term</label>
                <input
                  type="radio"
                  id="long term"
                  name="term length"
                  checked={term === 'long'}
                  onChange={() => setTerm('long')}
                />
                </Form.Group>


                <b>Description</b>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">short term</label>
                <input
                  type="radio"
                  id="junior"
                  name="Experience level"
                  checked={experience === 'junior'}
                  onChange={() => setExperience('junior')}
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">medium term</label>
                <input
                  type="radio"
                  id="medium"
                  name="Experience level"
                  checked={experience === 'medium'}
                  onChange={() => setExperience('medium')}
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">long term</label>
                <input
                  type="radio"
                  id="long term"
                  name="Experience level"
                  checked={experience === 'long'}
                  onChange={() => setExperience('senior')}
                />
                </Form.Group>

 <StyledContainer>
      <OuterCard>
        <InnerCard>
          <Title>
            <b>Which category are you interested in?</b>
          </Title>
          <ButtonsContainer>
            {categories.map((cat) => (
              <StyledButton
                key={cat}
                selected={category.includes(cat)}
                onClick={() => handleCategoryClick(cat)}
              >
                {cat}
              </StyledButton>
            ))}
          </ButtonsContainer>
          <Input
            type="text"
            value={others}
            onChange={(e) => setOthers(e.target.value)}
            placeholder="Enter other field ..."
          />
          <AddOtherButton onClick={handleAddOther}>Add Other</AddOtherButton>
 
        </InnerCard>
      </OuterCard>
    </StyledContainer>


                <Form.Group className="mb-3" controlId="formBasicEmail">
                <label htmlFor="paid">Enter Budget</label>
                <input
                  type="input"
              
                  onChange={(e) => setBudget(Number(e.target.value))}
                />
                </Form.Group>
                <StyledButton
                  type="button"
                  onClick={handleUploadJob}
            
                >
                   Upload
                </StyledButton>
              </Form>
   
  
     
            </Card.Body>
          </StyledCard>
        </StyledContainer>
      </PageContainer>  
    </>
  );
}

export default UploadJobForMe;
const PageContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
  background-color: #f8f9fa; /* Optional: Add a subtle background color */
`;


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add vertical centering for inner content */
  width: 100%; /* Ensure it spans the container */
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const CardTitle = styled(Card.Title)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
`;

const StyledInput = styled(Form.Control)`
  border: 2px solid #27ae60;
  border-radius: 5px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #27ae60;
  border: none;
  padding: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #1e8449;
  }

  &:disabled {
    background-color: #bdc3c7;
  }
`;

const FooterText = styled.div`
  text-align: center;
  margin-top: 15px;

  a {
    color: #2980b9;
    text-decoration: underline;

    &:hover {
      color: #2c3e50;
    }
  }
`;

const GoogleLoginWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 20px;
  text-align: center;
`;











 
const OuterCard = styled(Card)`
  background-color: white;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 10px 0; /* Add margin to prevent content sticking to edges */
  }
`;

const InnerCard = styled(Card)`
  background-color: #f8f9fa;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

const Title = styled(Card.Title)`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #27ae60;

  @media (max-width: 576px) {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-bottom: 15px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
    padding-left: 45px;
  }
`;
  
 
const Input = styled(Form.Control)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background-color: white;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for input fields */
    padding: 8px; /* Adjust padding */
  }
`;

const AddOtherButton = styled(Button)`
  width: 100%;
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;

  &:hover {
    background-color: #27ae60;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding */
  }
`;

const NextButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #27ae60;
  border-color: #27ae60;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #2c3e50;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size */
    padding: 10px; /* Adjust padding */
  }
`;
















// import React, { useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { Button, Alert, Card, Form, Container, Spinner } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
// import { GoogleLogin } from '@react-oauth/google';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { changeAuth, setProviderId, setProviderOrClientId } from '../reducers/reducers';

// function SignIn() {
//   const dispatch = useDispatch();
//   const [notAllowed, setNotAllowed] = useState(false);
//   const [signInData, setSignInData] = useState({ email: '', password: '' });
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();
//   let responseProvider;
//   let responseSkiller;
//   let responseProviderId;
//   let skillerToken;
//   let providerToken;
//   const [clientResult, setClientResult] = useState(true);
//   const [providerResult, setProviderResult] = useState(true);

//   const googleSuccess = async (response) => {
//     const { credential } = response;
//     const payload = JSON.parse(atob(credential.split('.')[1]));
//     const { email } = payload;

//     setIsLoading(true);
//     try {
//       responseSkiller = await axios.post('https://sell-skill.com/api/endpoints/skillerSignIn', { email, password: '' });
//       skillerToken = responseSkiller.data;
//       localStorage.setItem('skillerToken', JSON.stringify(skillerToken));
//       responseSkiller && navigate('/dashboard');
//     } catch (error) {
//       setClientResult(false);
//     }
//     setIsLoading(false);
//   };

//   const handleClick = async () => {
//     setIsLoading(true);
//     try {
//       try {
//         responseSkiller = await axios.post('https://sell-skill.com/api/endpoints/skillerSignIn', signInData);
//         skillerToken = responseSkiller.data;
//         localStorage.setItem('skillerToken', JSON.stringify(skillerToken));
//       } catch (error) { 
//         setClientResult(false);
//       }

//       localStorage.setItem('authenticated', 'true');
//       dispatch(changeAuth(true));

//       responseSkiller && navigate('/worlds');
//       responseProvider && navigate('/worlds');
//       if (responseSkiller || responseProvider) {
//         const responseProviderOrClientId = await axios.get('https://sell-skill.com/api/endpoints/providerOrClientId');
//         dispatch(setProviderOrClientId(responseProviderOrClientId.data));
//       }
//     } catch (error) {
//       console.error('Sign-in failed:', error);
//       setNotAllowed(true);
//       localStorage.setItem('authenticated', 'false');
//       dispatch(changeAuth(false));
//     }
//     setIsLoading(false);
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Sell-Skill | Sign Up or Login</title>
//         <meta name="description" content="Sell-Skill is a platform that connects clients with providers. Sign up to get help or share your knowledge." />
//         <meta name="keywords" content="Sell-Skill, client-provider platform, knowledge sharing, login, sign-up" />
//         <meta name="robots" content="index, follow" />
//       </Helmet>
//       <div style={{ minHeight: '100vh', backgroundColor: '#002f6c', padding: '2rem' }}>
//         <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100%' }}>
//           <Card style={{ width: '100%', maxWidth: '500px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
//             <Card.Body>
//               <Card.Title className="text-center mb-4" style={{ fontSize: '24px', color: '#002f6c' }}>
//                 <b>Login to Your Account</b>
//               </Card.Title>
//               <Form>
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Control 
//                     type="email" 
//                     placeholder="Email or username" 
//                     onChange={(e) => setSignInData({ ...signInData, email: e.target.value })} 
//                     style={{ borderColor: '#008080' }} 
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="formBasicPassword">
//                   <Form.Control 
//                     type="password" 
//                     placeholder="Password" 
//                     onChange={(e) => setSignInData({ ...signInData, password: e.target.value })} 
//                     style={{ borderColor: '#008080' }} 
//                   />
//                 </Form.Group>
//                 <Button 
//                   variant="primary" 
//                   type="button" 
//                   onClick={handleClick} 
//                   className="w-100" 
//                   disabled={isLoading}
//                   style={{ backgroundColor: '#008080', borderColor: '#008080' }}
//                 >
//                   {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Log in'}
//                 </Button>
//               </Form>
//               <div className="text-center mt-3">
//                 <a href="/client-sign-up" style={{ color: '#002f6c', textDecoration: 'underline' }}>
//                   Don't have an account? Sign up here
//                 </a>
//               </div>
//               <div className="text-center mt-3">
//                 <GoogleLogin onSuccess={googleSuccess} />
//               </div>
//               {notAllowed && (
//                 <Alert variant="danger" className="mt-3">
//                   Not allowed, please make sure you have an account
//                 </Alert>
//               )}
//               {!clientResult && !providerResult && (
//                 <Alert variant="danger" className="mt-3">
//                   Email or password might be incorrect
//                 </Alert>
//               )}
//             </Card.Body>
//           </Card>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default SignIn;

