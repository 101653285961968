import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
  
import { Button, Card } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import {v4 as uuidv4} from 'uuid';


function Notifications2() {
  const [notification, setNotification] = useState(null);
  const skillerId = localStorage.getItem('skillerId');
  const [loading, setLoading] = useState(true);
  const { notificationId } = useParams(); // Extract notification ID from URL
  const uniqueId = uuidv4();
  const worldId = notification.worldId
  const navigate = useNavigate()
  useEffect(() => {
    if (notificationId) {
      const getNotification = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getNotification', {
            params: { skillerId, notificationId }
          });

          console.log('response.data from get certain notification###########################******************> > > >  > > > >     ',response.data)
          setNotification(response.data);

        } catch (error) {
          console.error('Error fetching notification:', error);
        } finally {
          setLoading(false);
        }
      };
      getNotification();


    }

    const setNotificationToReaded = async() => {
      await axios.patch('https://sell-skill.com/api/endpoints/setNotificationReaded', { skillerId, notificationId });
    }
    setNotificationToReaded()
  }, [notificationId]);


  const handleAcceptAdvisorRequest = async () => {
    await axios.patch('https://sell-skill.com/api/endpoints/acceptAdvisorRequest', {worldId: notification.worldId, requestId: notification.requestId, notificationId})
  }
  return (

    <PageContainer>
 

    <GlobalStyle />
    <StyledContainer>
      <MainCard>

    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      {loading ? (
            <div
            className="loading-circle"
            style={{
                position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
                top: '50%', // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
                zIndex: 1000, // Optional: Ensure it stays on top of other elements
            }}
        >
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#27ae60"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeDasharray="90 150"
                    strokeDashoffset="0"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
      ) : (
        <Card className="max-w-lg w-full p-6 shadow-lg bg-white rounded-2xl">
          <Card.Header>
            <Card.Title className="text-2xl font-semibold text-gray-900">{notification?.title}</Card.Title>
          </Card.Header>
          <Card.Body>
            <p className="text-gray-700 text-lg">{notification?.content}</p>
          </Card.Body>

          {
            notification.typeee === 'mateRequest' && <Button onClick={() => navigate ('/mates-requests')}>View Request</Button>
          }
          
          {
            notification.typeee === 'advisorRequest' && <Button onClick={handleAcceptAdvisorRequest}>accept</Button>
          }



{
            notification.typeee === 'invitationToWorld' && <Button onClick={() => navigate(`/accept-invitation/${worldId}/${uniqueId}`)}>accept</Button>
          }

 
        </Card>
      )}
    </div>
    </MainCard>
    </StyledContainer>
    </PageContainer>
  );
}

export default Notifications2;




// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light gray background */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 20px;
`;

 

const Title = styled.h1`
  color: #2c3e50; /* Dark navbar color */
  text-align: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #27ae60;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #219150; /* Darker green on hover */
  }
`;

const StyledLink = styled.a`
  color: #2c3e50;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;