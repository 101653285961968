import axios from 'axios'
import React, { useEffect, useState } from 'react'

function MyEmployees() {
    const [employees, setEmployees ] = useState('')
    const skillerId = localStorage.getItem('skillerId')
    useEffect(() => {
        const getEmployees = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getEmployees')
            console.log('res.data from getEmployees ================================-=-=-=-=-=-=-=-=-=->> > >  >  ',employees)
            setEmployees(response.data)
        }
        getEmployees()
    }, [])
  return (
    <div>
      
    </div>
  )
}

export default MyEmployees
