// Import Firebase dependencies
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFH7SrOXpxr5o3O25lE-tBtXKDTpUKOzQ",
  authDomain: "sell-skill-79ed7.firebaseapp.com",
  projectId: "sell-skill-79ed7",
  storageBucket: "sell-skill-79ed7.appspot.com",
  messagingSenderId: "454122967288",
  appId: "1:454122967288:web:1150ef291cef6f98766b0d",
  measurementId: "G-ZC5R2NKGQZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Storage
const storage = getStorage(app);



const messaging = getMessaging(app);




// 🔥 Function to request permission & get the FCM token
export const requestNotificationPermission = async () => {
  try {
    // Ask for permission
    const permission = await Notification.requestPermission();
    
    if (permission === "granted") {
 

      // Get the token
      const token = await getToken(messaging, {
        vapidKey: "BGHhsw80IA0WGmDiUgWUffZUAdFWzS3ppxo99VStxw6udJHgG0PGkHplCdNqaPmsvtXni5bugr4JNpa3OcOiVb8",
      });

      if (token) {
 
        return token; // You can send this to your backend
      } else {
 
      }
    } else {
    
    }
  } catch (err) {
     
  }
};





export const requestForToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: "BGHhsw80IA0WGmDiUgWUffZUAdFWzS3ppxo99VStxw6udJHgG0PGkHplCdNqaPmsvtXni5bugr4JNpa3OcOiVb8" });
    if (token) {
     
    } else {
     
    }
  } catch (err) {
  
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
// Export functionalities
export { storage, ref, getDownloadURL, uploadBytes, messaging };
