// import React, { useEffect, useState } from "react";
// import { Button, Card, Container, Form } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import styled from "styled-components";
// import { Helmet } from "react-helmet";

 
// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh; /* Full viewport height */
//   width: 100vw; /* Full viewport width */
//   overflow-x: hidden; /* Prevent horizontal scrolling */
//   box-sizing: border-box; /* Ensure consistent box sizing */
//   padding: 0; /* Remove padding */
//   margin: 0; /* Remove any potential margin */

 
// `;

// function Categories() {
//   const navigate = useNavigate();
//   const skillerId = localStorage.getItem('skillerId')
//   const [category, setCategory] = useState([]);
//   const [others, setOthers] = useState("");
//   const [categories, setCategories] = useState([])
//   const handleNextClick = async () => {
//     try {
//       await axios.post("https://sell-skill.com/api/endpoints/category", {categoriesFromFront: category, skillerId : skillerId});
//       navigate("/certifications");
//     } catch (error) {
 
//     }
//   };
   
//   const handleCategoryClick = (cat) => {
//     setCategory((prevCategories) => {
//       if (prevCategories.includes(cat)) {
//         return prevCategories.filter((c) => c !== cat);
//       } else {
//         return [...prevCategories, cat];
//       }
//     });
//   };

//   const handleAddOther = () => {
//     if (others.trim() && !category.includes(others)) {
//       setCategory((prevCategories) => [...prevCategories, others]);
//       setOthers("");
//     }
//   };

//   useEffect(() => {
//     const getWorldCategories = async() => {
//       const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterSkillers')
//       setCategories(response.data)
 
//    }
//    getWorldCategories();
//   }, [])

//   return (
//     <PageContainer>
//             <Helmet>
//         <title>Choose Your Category | Sell-Skill</title>
//         <meta name="description" content="Select the category that best suits your skills and expertise on Sell-Skill." />
//         <meta name="keywords" content="Sell-Skill, skill categories, online courses, earn money, teach skills" />
//         <meta name="robots" content="index, follow" />
//         <meta property="og:title" content="Choose Your Category | Sell-Skill" />
//         <meta property="og:description" content="Select the category that best suits your skills and expertise on Sell-Skill." />
//         <meta property="og:type" content="website" />
//         <meta property="og:url" content="https://sell-skill.com/categories" />
//         <meta property="og:image" content="https://sell-skill.com/static/logo192.png" />
//         <link rel="canonical" href="https://sell-skill.com/categories" />
//       </Helmet>
//     <StyledContainer>
//       <OuterCard>
//         <InnerCard>
//           <Title>
//             <b>Which category are you interested in?</b>
//           </Title>
//           <ButtonsContainer>
//             {categories.map((cat) => (
//               <StyledButton
//                 key={cat}
//                 selected={category.includes(cat)}
//                 onClick={() => handleCategoryClick(cat)}
//               >
//                 {cat}
//               </StyledButton>
//             ))}
//           </ButtonsContainer>
//           <Input
//             type="text"
//             value={others}
//             onChange={(e) => setOthers(e.target.value)}
//             placeholder="Enter other field ..."
//           />
//           <AddOtherButton onClick={handleAddOther}>Add Other</AddOtherButton>
//           <NextButton onClick={handleNextClick}>Next</NextButton>
//         </InnerCard>
//       </OuterCard>
//     </StyledContainer>
//     </PageContainer>
//   );
// }

// export default Categories;




import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { Helmet } from "react-helmet";
 

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
`;

function Categories() {
  const navigate = useNavigate();
  const skillerId = localStorage.getItem('skillerId');
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState("");
  const [categories, setCategories] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleNextClick = async () => {
    try {
      await axios.post("https://sell-skill.com/api/endpoints/category", { categoriesFromFront: category, skillerId });
      navigate("/certifications");
    } catch (error) {
      console.error("Error submitting categories", error);
    }
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) => {
      if (prevCategories.includes(cat)) {
        return prevCategories.filter((c) => c !== cat);
      } else {
        return [...prevCategories, cat];
      }
    });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others)) {
      setCategory((prevCategories) => [...prevCategories, others]);
      setOthers("");
    }
  };

  useEffect(() => {
    const getWorldCategories = async () => {
      try {
        const response = await axios.get("https://sell-skill.com/api/endpoints/getCategoriesForFilterSkillers");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };
    getWorldCategories();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Choose Your Category | Sell-Skill</title>
        <meta name="description" content="Select the category that best suits your skills and expertise on Sell-Skill." />
        <meta name="keywords" content="Sell-Skill, skill categories, online courses, earn money, teach skills" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Choose Your Category | Sell-Skill" />
        <meta property="og:description" content="Select the category that best suits your skills and expertise on Sell-Skill." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sell-skill.com/categories" />
        <meta property="og:image" content="https://sell-skill.com/static/cover.jpg" />
        <link rel="canonical" href="https://sell-skill.com/categories" />
      </Helmet>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleNextClick();
      }}>
     
      <StyledContainer >
        <OuterCard>
          <InnerCard>
            <Title>
              <b>Which category are you interested in?</b>
            </Title>
            <Form.Control
              type="text"
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ButtonsContainer>
              {categories
                .filter((cat) => cat.toLowerCase().includes(searchTerm.toLowerCase()))
                .slice(0, visibleCategories)
                .map((cat) => (
                  <StyledButton
                    key={cat}
                    selected={category.includes(cat)}
                    onClick={() => handleCategoryClick(cat)}
                  >
                    {cat}
                  </StyledButton>
                ))}
            </ButtonsContainer>
            {categories.length > visibleCategories && (
              <SeeMoreButton onClick={() => setVisibleCategories(categories.length)}>
                See more...
              </SeeMoreButton>
            )}
            <Input
              type="text"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
              placeholder="Enter other field ..."
            />
            <AddOtherButton onClick={handleAddOther}>Add Other</AddOtherButton>
            <NextButton onClick={handleNextClick}>Next</NextButton>
          </InnerCard>
        </OuterCard>
      </StyledContainer>
      </form>
    </PageContainer>
  );
}

export default Categories;



const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Add padding for small devices */
    @media (max-width: 576px) {
    height: auto; /* Allow height to adjust based on content */
    padding: 20px 10px; /* Add padding for smaller screens */
    padding-left: 15px;
  }
`;

const OuterCard = styled(Card)`
  background-color: white;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 10px 0; /* Add margin to prevent content sticking to edges */
  }
`;

const InnerCard = styled(Card)`
  background-color: #f8f9fa;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

const Title = styled(Card.Title)`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #27ae60;

  @media (max-width: 576px) {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-bottom: 15px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
    padding-left: 45px;
  }
`;
  
const StyledButton = styled(Button)`
  width: 150px;
  margin: 5px;
  background-color: ${(props) => (props.selected ? "#27ae60" : "#2c3e50")};
  border-color: ${(props) => (props.selected ? "#27ae60" : "#2c3e50")};
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: #27ae60;
    color: white;
  }

  @media (max-width: 576px) {
    width: 120px; /* Reduce button width for smaller screens */
    margin: 3px;
  }
`;

const SeeMoreButton = styled.button`
background: none;
border: none;
color: #27ae60;
font-size: 0.9rem;
cursor: pointer;
margin-top: 5px;
padding: 0;
text-decoration: underline;

&:hover {
  color: #2c3e50;
}
`;

const Input = styled(Form.Control)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background-color: white;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for input fields */
    padding: 8px; /* Adjust padding */
  }
`;

const AddOtherButton = styled(Button)`
  width: 100%;
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;

  &:hover {
    background-color: #27ae60;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding */
  }
`;

const NextButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #27ae60;
  border-color: #27ae60;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #2c3e50;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size */
    padding: 10px; /* Adjust padding */
  }
`;