import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLessonId } from '../../../reducers/reducers';
import styled from 'styled-components';
import {   FaChevronLeft, FaChevronRight, FaStar,  FaUpload, FaBell, FaComments, FaBars } from 'react-icons/fa';
import { Button, Container  } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';


function Lessons() {






  


////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [sidebarMessages, setSidebarMessages] = useState([]);
const skillerId = localStorage.getItem('skillerId')
const dropdownRef = useRef(null);
    const [loading, setLoading] = useState(false);
 
 
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { 
                  params: {skillerId},
                  responseType: 'blob' }
            );
    
          
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
  
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    
 
    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
 
  localStorage.clear();
 

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

 
  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee


const handleNameClick = (withId) => {
 
  navigate(`/chat/${withId}`)

}



const bufferToBase64 = (buffer) => {
  if (!Array.isArray(buffer)) return '';

  // Flatten the array if it contains nested arrays
  const flatArray = buffer.flat();

  // Process the flat array in manageable chunks
  const chunkSize = 10000; // Process 10,000 bytes at a time
  let base64String = '';
  for (let i = 0; i < flatArray.length; i += chunkSize) {
      const chunk = flatArray.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
  }

  return btoa(base64String);
};



  const [lessons, setLessons] = useState([]);
  const [levelTitleAndNumber, setLevelTitleAndNumber] = useState('')
      const [comment, setComment] = useState('');
  const [activeTab, setActiveTab ] = useState('lessons')
      const [rate, setRate] = useState('');
 
  
              const [profilePictureSrc, setProfilePictureSrc] = useState('');
 
 
              const [showDropdown, setShowDropdown] = useState(false);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(true);
 
const {worldId, levelId} = useParams();
 
useEffect(() => {
  const fetchLessons = async () => {
    try {


      const response2 = await axios.get('https://sell-skill.com/api/endpoints/getLevelTitleAndNumber', {params: worldId, levelId})
      setLevelTitleAndNumber(response2.data)

    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };

  fetchLessons();
}, [worldId, levelId]);
 
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
};
const handleRateWorld = async () => {
  try {
      await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
  } catch (error) {
      console.error('Error rating world:', error);
  }
};

  useEffect(() => {
    const fetchLessons = async () => {
      
 
      try {
        setLoading(true);
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getOnlyPublishedLessons`, {
          params: { worldId, levelId , skillerId},
        });
        setLessons(response.data);
 
      } catch (error) {
        console.log(error.message)
      }
      finally {
        setLoading(false)
}
    };     

    fetchLessons();

    
  }, [worldId, levelId]);
  const addCommentToTheWorld = async () => {
    try {
        await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
            commentId: uuidv4(),
            comment,
            worldId,
        });
    } catch (error) {
        console.error('Error adding comment:', error);
    }
};

 
   //get mates ssssssssssssssssssssssssssssssssssssssssss
 
 
 


  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();

    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1', {params: {skillerId}})
      setSidebarMessages(response.data)
 
    }
    getSendersInfo();
}, []);

   
///get mates eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
  const handleShowLesson = (lessonId) => {
 
 
    navigate(`/resources/${worldId}/${levelId}/${lessonId}`);
  };
  
 

   
   
  return (
    <>
        <Helmet>
      <title>Lessons| Sell-Skill</title>
 
    </Helmet>

    <PageContainer>
      {
        loading ? (   <div
          className="loading-circle"
          style={{
              position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
              top: '50%', // Center vertically
              left: '50%', // Center horizontally
              transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
              zIndex: 1000, // Optional: Ensure it stays on top of other elements
          }}
      >
          <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
          >
              <circle
                  cx="25"
                  cy="25"
                  r="20"
                  stroke="#27ae60"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeDasharray="90 150"
                  strokeDashoffset="0"
              >
                  <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="1s"
                      repeatCount="indefinite"
                  />
              </circle>
          </svg>
      </div>
) : ( <Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
            
                <ContentContainer>
               
{sidebarVisible && (
  <Sidebar>
<SidebarNav>
{ sidebarMessages.length > 0 ? (
  sidebarMessages.map((message) => (
    <ChatItem key={message._id} onClick={() => handleNameClick(message.withId)}>
     
      <ChatImage
        src={
          message?.senderPicture?.picture?.data?.data
            ? `data:${message.senderPicture.contentType};base64,${bufferToBase64(
                message.senderPicture.picture.data.data
              )}`
            : "https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a"
        }
        alt="Sender Profile"
      />
      
      <ChatDetails>
        <ChatName>{message.senderName || "Mate"}</ChatName>
        {message.lengthOfUnreadedMessages > 0 && (
          <NotificationBadge>{message.lengthOfUnreadedMessages.toString()}</NotificationBadge>
        )}
      </ChatDetails>
    </ChatItem>
  ))
) : (
  <>
    <p className="text-gray-500 text-center">No messages received yet! start exploring mates <FaComments/> </p>

    <Button onClick = {() => navigate(`/mates/${worldId}`)}>Explore Mates</Button>
  </>

)}
</SidebarNav>
</Sidebar>

)}
              <ToggleSidebarButton onClick={toggleSidebar}>
               {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
           </ToggleSidebarButton>
              <MainContent>
                
              <Header>
          <TabContainer>
              <Tab
                  active={true}
             
              >
                  Explore Lessons
              </Tab>
              <Tab
                
                  onClick={() => navigate(`/level-mates/${worldId}/${levelId}`)}
              >
                  Explore Mates
              </Tab>
         <Tab
                 
                  onClick={() => navigate(`/level-posts/${worldId}/${levelId}`)}
              >
                  Explore Posts
              </Tab>  
          </TabContainer>
          {  !showDropdown &&  <UploadButtonWrapper>
              <UploadButton onClick={() => navigate('/upload-post-to-world')}>
                  <FaUpload style={{ marginRight: '5px' }} /> Upload post
              </UploadButton>
          </UploadButtonWrapper>}
      </Header>
      <>
      { activeTab === "lessons" &&
                  <ContentWrapper sidebarVisible={sidebarVisible}>
                  <Container fluid>
                  {  levelTitleAndNumber.title && levelTitleAndNumber.number &&  <h2 style={{ 
            fontSize: '1.5rem', 
            fontWeight: 'bold', 
            color: '#2c3e50', 
            textAlign: 'center', 
            marginBottom: '1rem' 
          }}>
            Lessons of Level Number {levelTitleAndNumber.number} , {levelTitleAndNumber.title}
          </h2>}
                  <LessonsGrid>
                    {lessons && lessons.map((lesson, index) => (
                      <LessonCard key={index}>
                        <h2>{lesson.name}</h2>
                        <p>{lesson.description}</p>
                        <Actions>
                          <Button onClick={() => handleShowLesson(lesson._id)}>Get in</Button>
                        </Actions>
                      </LessonCard>
                    ))}
                  </LessonsGrid>
                  </Container>
                  </ContentWrapper>
      }
      
      
 
      
      
      </>
      <ContentWrapper sidebarVisible={sidebarVisible}>
          {/* Other components */}
          <FeedbackSection sidebarVisible={sidebarVisible}>
                      
                              <h2>Rate and Comment on the World</h2>
                              <RatingContainer>
                                  {[1, 2, 3, 4, 5].map((star) => (
                                      <FaStar
                                          key={star}
                                          size={24}
                                          color={star <= rate ? "#f1c40f" : "#bdc3c7"}
                                          onClick={() => setRate(star)}
                                          style={{ cursor: "pointer" }}
                                      />
                                  ))}
                              </RatingContainer>
                              <button onClick={handleRateWorld}>Submit Rating</button>
                              <textarea
                                  placeholder="Enter your comment"
                                  onChange={(e) => setComment(e.target.value)}
                              ></textarea>
                              <button onClick={addCommentToTheWorld}>Add Comment</button>
                          </FeedbackSection>
                          </ContentWrapper>
              </MainContent>
              </ContentContainer>
            </Container>)
      }
     
    </PageContainer>
    </>
   
  );
}

export default Lessons;



const LessonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const LessonCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #7f8c8d;
  }
`;

 



const RatingContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;





 

 
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;


 
const FeedbackSection = styled.div`
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth resizing */

    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '150px' : '0')}; /* Adjust for Sidebar */
    width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')}; /* Width based on Sidebar */
    box-sizing: border-box;

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #2c3e50;
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: vertical;
    }

    button {
        background-color: #27ae60;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e8449;
        }
    }
`;


 


const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;

 

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;





const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;














const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;









 



const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;
 

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;
  
const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;
























const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;
// //To show inside lessons thumbnails and level description



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';

// import { useNavigate } from 'react-router-dom';
// import { setLessonId } from '../../../reducers/reducers';

// function Lessons() { // Pass skillerId as a prop
//   const [lessons, setLessons] = useState([]);
//   const [isLessonCompleted, setIsLessonCompleted ] = useState(false) // send to back
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   //check if the world name is selected
//   const worldName = useSelector((state) => state.allow.worldName)
//   const levelNumber = useSelector((state) => state.allow.levelNumber)
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);

//   useEffect(() => {
//     const fetchLevels = async () => {
//       try {
//         //to get lessons from the selected level
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getLessons`, {
//           params: {worldId, levelId}
//         });
//         setLessons(response.data);
//         console.log('lessons ===========> > > >  > ',response.data)

//       } catch (error) {
//         console.error("Error fetching worlds:", error);
//       }
//     };


//       fetchLevels();
    
//   },[]);
//   const handleShowLesson=  (id) => {
//     console.log('lesson id ================--------------------------->>>> >>>> > >>   ',id)
//     dispatch(setLessonId(id))
//     navigate ('/resources')
//   }




//   return (
//     <div>
//       <h1>My Lessons</h1>
//       <button>choose || chat</button>
//       <div className="worlds-container">
//         {lessons.map((lesson, index) => (
//           <div key={index} className="world-card">
//             <h2>{lesson.name}</h2>
//             <h2>{lesson.title}</h2>
//             <p>{lesson.description}</p>
            
//             <button onClick={() => handleShowLesson(lesson._id)}>Get in</button>

//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Lessons;



 
 
const NotificationBadge = styled.div`
  position: absolute;
  top: 20px;
  right: 40px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

 

 
const ChatItem = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 25px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: #f0f0f0;
    }
`;

const ChatImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
`;

const ChatDetails = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const ChatName = styled.span`
    font-weight: bold;
    color: #333;
`;

 
 
 

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

 