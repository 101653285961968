import axios from 'axios'
import React, { useState } from 'react'

function DeleteSkiller() {
    const [skillerId, setSkillerId] = useState('')
    const handleDeleteSkiller =async () => {
        await axios.delete('https://sell-skill.com/api/endpoints/deleteSkiller', {params: {skillerId}})
    }
  return (
    <div>
        <input placeholder='enter skillerId...' onChange={(e) => setSkillerId(e.target.value)}/>
      <button onClick={handleDeleteSkiller}></button>
    </div>
  )
}

export default DeleteSkiller
