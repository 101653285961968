import React, { useState } from "react";
import { Button, Card, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light Gray background */
    color: #2c3e50; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const MainCard = styled(Card)`
  background-color: #ffffff; /* White for the card background */
  border-radius: 15px;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Card.Title)`
  text-align: center;
  font-size: 28px;
  color: #2c3e50; /* Dark text for the title */
  margin-top: 20px;
`;

const TextArea = styled(Form.Control)`
  height: 300px;
  border-radius: 10px;
  background-color: #f0f0f0; /* Light Gray for text area background */
  color: #2c3e50; /* Dark Gray text */
  padding: 15px;
  border: 1px solid #dcdcdc; /* Light border for the text area */
`;

const NextButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #27ae60; /* Green button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #219150; /* Darker green */
    color: #ffffff;
  }
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;

function Letter() {
  const [letter, setLetter] = useState("");
  const navigate = useNavigate();
  const skillerId = localStorage.getItem('skillerId')

  const handleNextClick = async () => {
    try {
      navigate("/media-links");
      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerLetter", { letter, skillerId });

    } catch (error) {
 
    }
  };

  return (
    <PageContainer>
            <Helmet>
        <title>Write Your Description Letter | Sell-Skill</title>
        <meta name="description" content="Introduce yourself by writing a description letter to showcase your skills and expertise on Sell-Skill." />
        <meta name="keywords" content="description letter, about me, Sell-Skill, user profile, personal branding" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Write Your Description Letter | Sell-Skill" />
        <meta property="og:description" content="Introduce yourself by writing a description letter to showcase your skills and expertise on Sell-Skill." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sell-skill.com/letter" />
        <meta property="og:image" content="https://sell-skill.com/static/logo192.png" />
        <link rel="canonical" href="https://sell-skill.com/letter" />
      </Helmet>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>
            <b>Cover Letter</b>
          </Title>
          <Form style={{ padding: "20px" }}>
            <TextArea
              as="textarea"
              placeholder="Enter a cover letter for your profile"
              onChange={(e) => setLetter(e.target.value)}
              value={letter}
            />
            <NextButton onClick={handleNextClick}>Next</NextButton>
          </Form>
        </MainCard>
      </StyledContainer>
    </PageContainer>
  );
}

export default Letter;

























// import React, { useState } from 'react';
// import { Button, Card, Form, Container } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import styled, { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #E6F0FF; /* Light Blue background */
//     color: #2F4F4F; /* Dark Gray for text */
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// `;

// const MainCard = styled(Card)`
//   background-color: #FAFAFA; /* Off-White for the card background */
//   border-radius: 20px;
//   width: 100%;
//   max-width: 800px;
//   margin-top: 20px;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
// `;

// const Title = styled(Card.Title)`
//   text-align: center;
//   font-size: 30px;
//   color: #4169E1; /* Royal Blue for the title */
//   margin-top: 20px;
// `;

// const TextArea = styled(Form.Control)`
//   height: 400px;
//   border-radius: 20px;
//   background-color: #F0F0F0; /* Light gray background for the text area */
//   color: #2F4F4F; /* Dark gray text */
//   padding: 15px;
// `;

// const NextButton = styled(Button)`
//   width: 100%;
//   margin-top: 20px;
//   background-color: #FFD700; /* Golden Yellow for the button */
//   border: none;
//   padding: 12px;
//   font-size: 16px;
//   font-weight: bold;
//   border-radius: 8px;

//   &:hover {
//     background-color: #4169E1; /* Royal Blue on hover */
//     color: #FAFAFA; /* Off-White text on hover */
//   }
// `;

// function Letter() {
//   const [letter, setLetter] = useState('');
//   const navigate = useNavigate();

//   const handleNextClick = async () => {
//     navigate("/worlds");
//     await axios.post('https://sell-skill.com/api/endpoints/insertSkillerLetter', letter);
//   };

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer>
//         <MainCard>
//           <Title>
//             <b>Cover Letter</b>
//           </Title>
//           <Form style={{ padding: '20px' }}>
//             <TextArea
//               as="textarea"
//               placeholder="Enter a cover letter for your profile"
//               onChange={(e) => setLetter(e.target.value)}
//               value={letter}
//             />
//             <NextButton onClick={handleNextClick}>Next</NextButton>
//           </Form>
//         </MainCard>
//       </StyledContainer>
//     </>
//   );
// }

// export default Letter;
