import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const StockTradingFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FStockFullWidthSlider%2F1202988_c73f_20.webp?alt=media&token=85cb2e08-7c22-46af-89ec-7674b427e80d" alt="Slide 1" className="slider-image" />
 
                </div>
     
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FStockFullWidthSlider%2Ftrading-begriffe.jpg?alt=media&token=39ff4e4c-762e-4795-9c90-eb5ecd02958f" alt="Slide 1" className="slider-image" />
 
                </div>
 
               
            </Slider>
        </div>
    );
};

export default StockTradingFullWidthSlider;
