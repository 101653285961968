import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {   FaSearch, FaStar,  FaRegStar, FaPlus, FaBars, FaArrowRight, FaBell, FaComments,    FaComment,  } from 'react-icons/fa';
import { Button, Card, Col, Container,  Row } from 'react-bootstrap';
 
 
import { v4 as uuidv4 } from 'uuid';
 
import { Helmet } from 'react-helmet';
import { setWithId } from '../../../reducers/reducers';
import { requestNotificationPermission } from '../../../Firebase/Firebase';

function InviteTab() {

 
    const skillerId = localStorage.getItem('skillerId');
 
  
    const [mates, setMates] = useState([]);
  
   const {worldId} = useParams()
 
 
const [isSearchVisible, setIsSearchVisible] = useState(false);
 
const [searchTerm, setSearchTerm] = useState('');

let matesLength = 0;


const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};


 

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

 
 
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  const [filteredCurrentMates, setFilteredCurrentMates] = useState([]); // Filtered mates
     const [searchQueryM, setSearchQueryM] = useState('');
const handleSearchChangeM = (event) => {
  const query = event.target.value;
  setSearchQueryM(query);
};


useEffect(() => {
  if (!searchQueryM.trim()) {
    // If search query is empty, show all mates
    setFilteredCurrentMates(mates);
  } else {
    const lowerCaseQuery = searchQueryM.toLowerCase();
    const filtered = mates.filter((mate) => {
      // Check if the name or email contains the search query
      return (
        mate.name?.toLowerCase().includes(lowerCaseQuery) ||
        mate.email?.toLowerCase().includes(lowerCaseQuery)
      );
    });
    setFilteredCurrentMates(filtered);
  }
}, [searchQueryM, mates]);


        
const getMatesGetAllSkillers = async() => {

 
    try
               
     {  
        setLoading(true)
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAllSkillers', {params: {skipNum: matesLength}} )
 
        // setMates(prevMates => [...prevMates, ...response.data]);
        setMates(prevMates => {
            const newMates = response.data.filter(newMate => 
              !prevMates.some(prevMate => prevMate._id === newMate._id)
            );
            return [...prevMates, ...newMates];
          });
          
        matesLength = matesLength + response.data.length
      
    }
    catch(error){
     
       
    }finally{
        setLoading(false)
    }
    
              }
     
     
     
 
    const [lengthOfUnreadedNotifications, setLengthOfUnreadedNotifications] = useState(0);
    const [lengthOfUnreadedMessages, setLengthOfUnreadedMessages] = useState(0);


    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
 
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(true);
 
 
    const [sidebarVisible, setSidebarVisible] = useState(true);
 

    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });


    const [categories, setCategories] = useState([
        "Freelancing",
        "E-commerce",
        "Dropshipping",
        "Affiliate Marketing",
        "Content Creation (YouTube, TikTok, Blogging)",
        "Social Media Management",
        "Stock Trading & Investing",
        "Cryptocurrency & NFTs",
        "Online Coaching & Consulting",
        "Digital Marketing Services",
        "Selling Online Courses",
        "Print-on-Demand",
        "Virtual Assistance",
        "Real Estate Investing",
        "Passive Income Strategies",
        "Remote Work Opportunities"
    ]); // Example categories


 
 
 
 


      /// <---- fetch worlds --->  ssssssssssssssssssssssssssssssssssss

        // Infinite scroll handler

 
        
  


      useEffect(() => {
 
 

 
          const fetchProfilePicture = async () => {
              try {
                  const response = await axios.get(
                      'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                      { 
                        params: {skillerId},
                        responseType: 'blob' }
                  );
        
  
                  if (response.data.size > 0) {
                      // Blob has data, create an object URL
                      const url = URL.createObjectURL(response.data);
                      setProfilePictureSrc(url);
                  } else {
                      // Blob is empty, use the Firebase image URL
                      setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                  }
              } catch (error) {
    
                  // Optionally set a fallback image if there's an error
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          };


        //   const getMatesGetMates = async() => {

        //     const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
 
           
        //        setMates(response.data)
        //        console.log('response.data from ******************************************** getMates getMates getMates getMates getMates******************************************* =================================-=-=-=-=-=- > > > > > > > >  ',response.data)
        //    }
        
          const getMatesGetAllSkillers = async() => {
try
           
 {  
    setLoading(true)
    const response = await axios.get('https://sell-skill.com/api/endpoints/getAllSkillers' )
       

 
              setMates(response.data)
              matesLength = matesLength + response.data.length
  
}
catch(error){
  
}finally{
    setLoading(false)
}

          }
 
 
 
 

 

 

           const getWorldCategories = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterWorlds')
              setCategories(response.data)
 
           }
           getWorldCategories();


                 requestNotificationPermission();

        //    getMatesGetMates();
 
            getMatesGetAllSkillers();

      fetchProfilePicture();
 
         const  getNumberOfUnReadedMessages = async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnReadedMessages', {params: {skillerId}})
 
            setLengthOfUnreadedMessages(response.data)
 
        }
       getNumberOfUnReadedMessages()
        const  getNumberOfUnreadedNotifications = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnreadedNotifications', {params: {skillerId}})
            setLengthOfUnreadedNotifications(response.data)
        }
        getNumberOfUnreadedNotifications();

 
    }, []);



    const handleAddMate = async (invitedSkillerId) => {
        
        await axios.post('https://sell-skill.com/api/endpoints/inviteSkillerToBeAdvisorInWorld', {skillerId, invitedSkillerId,  worldId, notificationId: uuidv4()})
 
        // Add your logic here
    };
           
    const handleMessage = (mateId) => {
         setWithId(mateId)
 
         navigate(`/chat/${mateId}`)
        // Add your logic here
    };
    

 
 
    const handleLogout = async () => {
        // Clear localStorage
 
        localStorage.clear();
        
    
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
 
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

     

   

    
    const navigate = useNavigate();

 

  
 
 
    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

 
    
 

 
 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isMobile = window.innerWidth <= 768; // Adjust this value as needed
    const maxScrollRef = useRef(isMobile ? 1500 : 200); // Store maxScroll in useRef
     useEffect(() => {
         const handleScroll = () => {
           // Calculate opacity based on scroll position
          
           const currentScroll = window.scrollY; // Use window.scrollY to get the scroll position
 
     
 

         };
     
         // Add scroll event listener
         window.addEventListener('scroll', handleScroll);
     
   
         // Cleanup on component unmount
         return () => {
           window.removeEventListener('scroll', handleScroll);
         };
       }, []);
     
     const [opacity, setOpacity] = useState(1);
 
  
 
 
 
     const pageContainerRef = useRef(null); // Ref for the PageContainer
 
   useEffect(() => {
     const handleScroll = () => {
       if (pageContainerRef.current) {
         // Calculate scroll position relative to PageContainer
         const maxScroll = 300; // Adjust based on when you want it to vanish completely
         const currentScroll = pageContainerRef.current.scrollTop;
         const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
 
 
        if (currentScroll >= maxScrollRef.current) {
 
         getMatesGetAllSkillers(); // Call function when bottom is reached
         maxScrollRef.current += isMobile ? 1862 : 315; // Update maxScroll using useRef
       }
         setOpacity(newOpacity);
       }
     };
 
     const containerElement = pageContainerRef.current;
 
     if (containerElement) {
       // Add scroll listener to PageContainer
       containerElement.addEventListener('scroll', handleScroll);
     }
 
     // Cleanup on component unmount
     return () => {
       if (containerElement) {
         containerElement.removeEventListener('scroll', handleScroll);
       }
     };
   }, []);
 
 

  return (
    <PageContainer ref={pageContainerRef}>


<Helmet>
      {/* Primary Meta Tags */}
      <title>Find Mates | Sell-Skill - Connect & Learn with Like-Minded People</title>
      <meta name="description" content="Discover like-minded learners and mentors on Sell-Skill. Connect, collaborate, and grow your skills together in a thriving community." />
      <meta name="keywords" content="find mates, Sell-Skill, learn together, skill sharing, online learning community, mentorship, collaboration" />
      <meta name="author" content="Sell-Skill" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Find Mates | Sell-Skill - Connect & Learn with Like-Minded People" />
      <meta property="og:description" content="Discover like-minded learners and mentors on Sell-Skill. Connect, collaborate, and grow your skills together in a thriving community." />
 
      <meta property="og:url" content="https://sell-skill.com/mates" />
      <meta property="og:site_name" content="Sell-Skill" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Find Mates | Sell-Skill - Connect & Learn with Like-Minded People" />
      <meta name="twitter:description" content="Discover like-minded learners and mentors on Sell-Skill. Connect, collaborate, and grow your skills together in a thriving community." />
 
      <meta name="twitter:site" content="@sell_skill" />
      <meta name="twitter:creator" content="@sell_skill" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://sell-skill.com/find-mates" />

      {/* Structured Data (Schema.org JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Find Mates | Sell-Skill",
          "description": "Discover like-minded learners and mentors on Sell-Skill. Connect, collaborate, and grow your skills together in a thriving community.",
          "url": "https://sell-skill.com/mates",
   
          "publisher": {
            "@type": "Organization",
            "name": "Sell-Skill",
            "logo": "https://sell-skill.com/static/logo192.png"
          }
        })}
      </script>
    </Helmet>


         <Header>
              <Navbar>
                <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
        
        
                <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>
        
                <NavIcons>
         
             {
                        isSearchVisible ? (
                            <input
                                type="text"
                                placeholder="Search Worlds"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        ): (
                            <FaSearch onClick={handleSearchIconClick} />
                        )
                    }
          
        
                        
          
        
                    
                  
                    <>
                    {lengthOfUnreadedNotifications > 0 &&
                    <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
                    }
                    <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
                    </>
            
            <>
            {lengthOfUnreadedMessages > 0 &&
                    <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
                    }
            <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
            </>
        
                </NavIcons>
        
        
                <div ref={dropdownRef}>
                  {profilePictureSrc && (
                    <ProfileWrapper onClick={handleProfileClick}>
                      <ProfilePicture src={profilePictureSrc} alt="Profile" />
                    </ProfileWrapper>
                  )}
                  {showDropdown && (
                    <DropdownMenu>
                      <a href={`/my-profile/${skillerId}`}>My Profile</a>
                      <a href="/manage-uploads">Uploads Management</a>
                      <a href="/manage-financial">Financial Management</a>
                      <a href="/advisors-&-students">Advisors & Students</a>
                      <a href="/my-exams">My Exams</a>
                      <a href = "/my-employees">My Cart</a>
                      <a href = "/my-employees">My Uploaded Worlds</a>
                      <a href = "/my-employees">My Subscriptions</a>
                      <a href = "/my-employees">My Employees</a>
                      <a href = "/my-employees">My Clients</a>
                      <a href="/financial-management">Withdraw Money</a>
                      <a href="/skiller-signIn" onClick={handleLogout}>
                        Log Out <FaArrowRight/>
                      </a>
                    </DropdownMenu>
                  )}
                </div>
        
                <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <FaBars />
                </Hamburger>
              </Navbar>
        
        
            </Header>
              
<Container>


<ContentContainer>


{sidebarVisible && (
            <Sidebar>
  <SidebarNav>
    <SidebarLink   className='active'>Explore Mates</SidebarLink>
    <SidebarLink href='/mates-requests'>Mates Requests</SidebarLink>
    <SidebarLink href='/current-mates'>Current Mates</SidebarLink>
 
  </SidebarNav>
</Sidebar>

     )}


    <MainContent>

                


    <div>
      {  (
    <>
 

        {/* Content based on the selected sub-tab */}
        { (
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
                
                <Row>

 

                    
                    { filteredCurrentMates.length > 0 && filteredCurrentMates.map((mate) => {
                        const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                        return (
        <Col
            key={mate._id}
            xs={12}
            sm={mates.length === 1 ? 8 : 6}
            md={mates.length === 1 ? 6 : 4}
            lg={mates.length === 1 ? 4 : 3}
            className="mb-4"
            style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
        >
            <CardContainer>
            <Card
                style={{
                    minHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {mate.picture ? (
                      <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center mt-3">
                        <Card.Img
                            variant="top"
                            src={`data:${mate?.picture?.contentType};base64,${bufferToBase64(mate?.picture?.picture?.data?.data)}`  }
                            alt={`${mate.name || 'Mate'}'s profile`}
                            className="rounded-circle mx-auto"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '2px solid #ccc',
                            }}
                        />
                    </div>
                    </a>
                ) : (
                    <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                  <div className="text-center mt-3">
                      <Card.Img
                          variant="top"
                          src={'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a'}
                          alt={`${mate.name || 'Mate'}'s profile`}
                          className="rounded-circle mx-auto"
                          style={{
                              width: '100px',
                              height: '100px',
                              objectFit: 'contain',
                              border: '2px solid #ccc',
                          }}
                      />
                  </div>
                  </a>
              )}
                <Card.Body style={{ flex: 1 }}>
                <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${mate.name} ${mate.surname}`}
                    </Card.Title>
                    </a>
 
                    <div className="d-flex justify-content-center mb-3">
                        {Array.from({ length: 5 }, (_, i) => (
                            i < averageRate ? (
                                <FaStar key={i} color="gold" />
                            ) : (
                                <FaRegStar key={i} color="gold" />
                            )
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {mate.sharedWorlds?.map((world) => (
                             <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                {world.thumbnail && 
                                    <img
                                        key={world._id}
                                        src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`}
                                        alt={world.name || 'World'}
                                        title={world.name || 'World'}
                                        className="rounded-circle"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'contain',
                                            margin: '5px',
                                        }}
                                    />
                                }
                            </a>
                        ))}
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success" onClick={() => handleAddMate(mate._id)} disabled={mate.mates.find(mt => mt.mateId === skillerId)}>
                     Invite <FaPlus/>
                    </Button>
                              <button className="message-btn" onClick={() => handleMessage(mate._id)}  >Message <FaComment/></button>
                </Card.Footer>
            </Card>
            </CardContainer>
        </Col>
        
                        );
                    })}
                </Row>
            </Container>
        </ContentWrapper>



        )}

 

{
    loading && (

        <div
        className="loading-circle"
        style={{
            position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
            zIndex: 1000, // Optional: Ensure it stays on top of other elements
        }}
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#27ae60"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="90 150"
                strokeDashoffset="0"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
    )
}

 
    </>
)}


    </div>
    </MainContent>
</ContentContainer>
   
    </Container>
    </PageContainer>
  )
}

export default InviteTab



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
 
    
  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




















  


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 90px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;









 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

 



const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;
 

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 60px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

 

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;
 
 


 


 

 



const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height for scrolling */
 
    box-sizing: border-box;
`;



const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;


 


 

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  background: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #e8e8e8;
  }

  &.active {
    background: #27ae60;
    color: white;
  }
`;
