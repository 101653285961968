import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const DropshippingFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FDroshippingFullWidthSlider%2F1282672_8850_6.webp?alt=media&token=a12da6d9-12ca-4477-a3b7-f745f793c3c3" alt="Slide 1" className="slider-image" />
                   
                </div>
 
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FDroshippingFullWidthSlider%2F3845214_e9fd_3.webp?alt=media&token=a0682b9d-1203-4f3b-a23f-147a98832126" alt="Slide 3" className="slider-image" />
                   
                </div>

 
            </Slider>
        </div>
    );
};

export default DropshippingFullWidthSlider;
