import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Alert, Card, Form, Container, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeAuth, setProviderOrClientId } from "../reducers/reducers";
import styled from "styled-components";
import {   getToken } from "firebase/messaging";
import { messaging } from "../Firebase/Firebase";

function SignIn() {
  const dispatch = useDispatch();
  const [signInData, setSignInData] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [clientResult, setClientResult] = useState(true);
  const navigate = useNavigate();
const getTokenn = async () =>{
  await getToken(messaging, { vapidKey: "BGHhsw80IA0WGmDiUgWUffZUAdFWzS3ppxo99VStxw6udJHgG0PGkHplCdNqaPmsvtXni5bugr4JNpa3OcOiVb8" });
 
}
getTokenn();
 
  const googleSuccess = async (response) => {
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split(".")[1]));
    const { email } = payload;

    setIsLoading(true);
    try {
      const responseSkiller = await axios.post("https://sell-skill.com/api/endpoints/skillerSignIn", {
        email,
        password: "",
      });
      const skillerToken = responseSkiller.data;
      localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
      navigate("/worlds");
    } catch (error) {
       
      setClientResult(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async () => {         
    setIsLoading(true);
    try {
      const responseSkiller = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignIn",
        signInData
      );
 
      const skillerToken = responseSkiller.data.token;
 
      const skillerId = responseSkiller.data.skillerId;
 
      localStorage.setItem("skillerToken",  JSON.stringify(skillerToken));
      localStorage.setItem("skillerId", skillerId)

      const responseProviderOrClientId = await axios.get(
        "https://sell-skill.com/api/endpoints/providerOrClientId"
      );
      dispatch(setProviderOrClientId(responseProviderOrClientId.data));

      localStorage.setItem("authenticated", "true");
      dispatch(changeAuth(true));
      navigate("/worlds");
    } catch (error) {
 
      setNotAllowed(true);
      setClientResult(false);
      localStorage.setItem("authenticated", "false");
      dispatch(changeAuth(false));
    } finally {

      setIsLoading(false);
    }
  };

  return (
    <>
<Helmet>
  <title>Sell-Skill | Sign In - Access Exclusive Money-Making Courses</title>
  <meta 
    name="description" 
    content="Sign in to Sell-Skill and access expert-led courses. Unlock premium content, enhance your skills, and start earning today." 
  />
  <meta 
    name="keywords" 
    content="Sell Skill, online learning, money making courses, earn online, skill development, digital skills, passive income, financial growth, entrepreneurship, online business, sign in" 
  />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="Sell-Skill Team" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

 
  <meta property="og:title" content="Sell-Skill | Sign In - Unlock Exclusive Money-Making Courses" />
  <meta property="og:description" content="Log in to Sell-Skill to learn high-income skills and start earning. Join expert-led courses designed to help you succeed in the digital economy." />
  <meta property="og:image" content="https://sell-skill.com/logo192.png" />
  <meta property="og:url" content="https://sell-skill.com/skiller-signin" />
  <meta property="og:type" content="website" />
 
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Sell-Skill | Sign In - Learn & Earn with Online Courses" />
  <meta name="twitter:description" content="Sign in to Sell Skill and gain access to exclusive money making courses and learning high on demand skills. Start learning today!" />
  <meta name="twitter:image" content="https://sell-skill.com/logo192.png" />

 
  <link rel="canonical" href="https://sell-skill.com/skiller-signin" />
</Helmet>

      <PageContainer>
        <StyledContainer>
          <StyledCard>
            <Card.Body>
              <CardTitle>
                <b>Login to Your Account</b>
              </CardTitle>
              <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSignIn();
              }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <StyledInput
                    type="email"
                    placeholder="Email or username"
                    onChange={(e) => setSignInData({ ...signInData, email: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <StyledInput
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setSignInData({ ...signInData, password: e.target.value })}
                  />
                </Form.Group>
                <StyledButton
                  type="button"
                  onClick={handleSignIn}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    "Log in"
                  )}
                </StyledButton>
              </Form>
              <FooterText>
                <a href="/skiller-signUp">Don't have an account? Sign up here</a>
              </FooterText>
              <GoogleLoginWrapper>
                <GoogleLogin onSuccess={googleSuccess} />
              </GoogleLoginWrapper>
              {notAllowed && (
                <StyledAlert variant="danger">
                  Not allowed. Please ensure you have an account.
                </StyledAlert>
              )}
              {!clientResult && (
                <StyledAlert variant="danger">
                  Email or password is incorrect.
                </StyledAlert>
              )}
            </Card.Body>
          </StyledCard>
        </StyledContainer>
      </PageContainer>
    </>
  );
}

export default SignIn;
const PageContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
  background-color: #f8f9fa; /* Optional: Add a subtle background color */
`;


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add vertical centering for inner content */
  width: 100%; /* Ensure it spans the container */
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const CardTitle = styled(Card.Title)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
`;

const StyledInput = styled(Form.Control)`
  border: 2px solid #27ae60;
  border-radius: 5px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #27ae60;
  border: none;
  padding: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #1e8449;
  }

  &:disabled {
    background-color: #bdc3c7;
  }
`;

const FooterText = styled.div`
  text-align: center;
  margin-top: 15px;

  a {
    color: #2980b9;
    text-decoration: underline;

    &:hover {
      color: #2c3e50;
    }
  }
`;

const GoogleLoginWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 20px;
  text-align: center;
`;