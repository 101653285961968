import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

const PageContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

const ResourceCard = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;

  img, video {
    width: 100%;
    border-radius: 8px;
  }

  a {
    color: #0275d8;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.4;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  background-color: #27ae60;
  border: none;

  &:hover {
    background-color: #218c53;
  }
`;

function GetWorldTrailer() {
  const [resources, setResources] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const { worldId } = useParams();
  // useEffect(() => {
  //   if (window.fbq) {
  //     window.fbq('track', 'ViewContent', {
  //       content_name: 'Trailer page',
  //       value: 0,
  //       currency: 'USD'
  //     });
  //   }
  // }, []);
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getWorldTrailer`, {
          params: { worldId }
        });
        setResources(response.data);
        if(response.data){
        setLoading(false)
        }
        console.log('response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer response.data from trailer ========================------=-=-=-=-=-=-=-> > > > > > > > > ',response.data)
      } catch (error) {
        fetchResources();
        console.error("Error fetching resources:", error);
      }
    };
    fetchResources();
  }, [worldId]);

  const renderResource = (resource, index) => {
    switch (resource.type) {
      case 'video':
        return (
          <ResourceCard key={index}>
            <video controls src={resource.url} />
          </ResourceCard>
        );
      case 'image':
        return (
          <ResourceCard key={index}>
            <img src={resource.url} alt="Resource" />
          </ResourceCard>
        );
      case 'pdf':
        return (
          <ResourceCard key={index}>
            <a href={resource.url} target="_blank" rel="noopener noreferrer">
              View attachment
            </a>
          </ResourceCard>
        );
      case 'text':
        return (
          <ResourceCard key={index}>
            <p>{resource.content}</p>
          </ResourceCard>
        );
      case 'quiz':
        return (
          <ResourceCard key={index}>
            <p>{resource.content.question}</p>
            <ul>
              {resource.content.choices.map((choice, i) => (
                <li key={i}>{choice}</li>
              ))}
            </ul>
          </ResourceCard>
        );
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <h1>World Trailer</h1>
      {
      loading ? (
            <div
                className="loading-circle"
                style={{
                    position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
                    zIndex: 1000, // Optional: Ensure it stays on top of other elements
                }}
            >
                <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="#27ae60"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="90 150"
                        strokeDashoffset="0"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </circle>
                </svg>
            </div>) : (      <ResourceContainer>
        {resources.map((resource, index) => renderResource(resource, index))}
      </ResourceContainer>)
      }

      <StyledButton onClick={() => navigate(`/levels/${worldId}`)}>Get In <FaArrowRight/></StyledButton>
    </PageContainer>
  );
}

export default GetWorldTrailer;



























































// //To show resources inside a lesson




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Button } from 'react-bootstrap';

// function GetWorldTrailer() {
//   const [resources, setResources] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();


//   const {worldId} = useParams()

//   useEffect(() => {

//       const fetchResources = async () => {
//         try {
//           const response = await axios.get(`https://sell-skill.com/api/endpoints/getWorldTrailer`, {
//             params: { worldId }
//           });
//           setResources(response.data);

//           console.log('response.data from fetchResources          =-=-=-=================--=- > > > > > >    ',response.data)
//         } catch (error) {
//           console.error("Error fetching resources:", error);
//         }
      
  

//     }
//     fetchResources();
//   }, []);
  
//   const renderResource = (resource, index) => {
//     switch (resource.type) {
//       case 'video':
//         return (
//           <div key={index} className="resource-card">
   
//             <video controls src={resource.url} width="100%" />
//           </div>
//         );
//       case 'image':
//         return (
//           <div key={index} className="resource-card">

//             <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
//           </div>
//         );
//       case 'pdf':
//         return (
//           <div key={index} className="resource-card">

//             <a href={resource.url} target="_blank" rel="noopener noreferrer">
//               View attachement
//             </a>
//           </div>
//         );
//       case 'text':
//         return (
//           <div key={index} className="resource-card">
       
//             <p>{resource.content}</p>
//           </div>
//         );
//       case 'quiz':
//         return (
//           <div key={index} className="resource-card">
  
//             <div>
//               <p>{resource.content.question}</p>
//               <ul>
//                 {resource.content.choices.map((choice, i) => (
//                   <li key={i}>{choice}</li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <h1>World Trailer</h1>
//       <div className="resources-container">
//         {resources.map((resource, index) => renderResource(resource, index))}
//       </div>
//       <Button onClick={() => navigate(`/levels/${worldId}`)}>Get In</Button>
//     </div>
//   );
// }

// export default GetWorldTrailer;
