import React, { useEffect, useRef, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { getToken } from "firebase/messaging";
import { messaging } from "../../Firebase/Firebase";
import { Helmet } from "react-helmet";


function SkillerSignUp() {
 
  const skillerIdRef = useRef(  uuidv4());

 

  console.log('skillerId -1 ==============================================-=-=-=-=-> > > > > >   ', skillerIdRef.current)
  const getTokenn = async () => {

    try {
 
      const token = await getToken(messaging, {
        vapidKey: "BGHhsw80IA0WGmDiUgWUffZUAdFWzS3ppxo99VStxw6udJHgG0PGkHplCdNqaPmsvtXni5bugr4JNpa3OcOiVb8",
      });
 
      return token;
    } catch (error) {
   
      return "";
    }
  };
  

  const [providerSignUpData, setProviderSignUpData] = useState({
    skillerId : skillerIdRef.current ,
    notificationId: uuidv4(),
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    FcmToken: '',  
  });
  console.log('providerSignUpData  -2 ====================================-=-=-=-=-=-=-=-=-=-> > > > > >  ',providerSignUpData)
  const [alreadyProviderExist, setAlreadyProviderExist] = useState(false);
  const navigate = useNavigate();

  const googleSuccess = async (response) => {
  
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split(".")[1]));
    const { email, name, family_name } = payload;

    try {
     const token =  await getTokenn();

      const responseProvider = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignUp",
        {
            skillerId :  skillerIdRef.current,
    notificationId: uuidv4(),
          name,
          surname: family_name,
          email,
          password: "",
          confirmPassword: "",
          FcmToken: token,
        }
      );

      const skillerToken = responseProvider.data;

      if (!skillerToken) {
        setAlreadyProviderExist(true);
      } else {
        localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
   
        localStorage.setItem("skillerId",  skillerIdRef.current)
       
        window.fbq('track', 'SignUp', {
           content_name: 'Sign Up',
         value: 0,
          currency: 'USD'
          });
      
        navigate("/categories");

      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };

  const handleProviderSignUp = async () => {

    try {
    
      const token = await getTokenn();
      const responseProvider = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignUp",
       { ...providerSignUpData, FcmToken: token}
      );
      const skillerToken = responseProvider.data;

      if (!skillerToken) {
        setAlreadyProviderExist(true);
      } else {

        localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
        console.log('skillerId -3 ==========================-=-=-=-=-=-=-=-> > > > > >  ', skillerIdRef.current )
        localStorage.setItem("skillerId",  skillerIdRef.current)
          window.fbq('init', '1669785423607093');
          window.fbq('track', 'SignUp', {
           content_name: 'Sign Up',
           value: 0,
            currency: 'USD'
         });
          
        navigate("/categories");

      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };



  return (
    <PageContainer>
      <Helmet>
  <title>Sell-Skill | Sign Up - Learn, Teach, and Earn</title>
  <meta 
    name="description" 
    content="Join Sell-Skill and access courses in various fields, upload your own courses, and connect with paid job opportunities. Sign up now to learn, teach, and earn." 
  />
  <meta 
    name="keywords" 
    content="Sell-Skill, sign up, online learning, course creation, freelance jobs, paid jobs, online courses, skill development, company hiring, job marketplace, find clients, teach online" 
  />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="Sell-Skill Team" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
 
  <meta property="og:title" content="Sell-Skill | Sign Up - Learn, Teach, and Find Paid Jobs" />
  <meta property="og:description" content="Create an account on Sell-Skill to access diverse courses, teach online, and connect with companies offering paid jobs. Start your journey today!" />
  <meta property="og:image" content="https://sell-skill.com/logo192.png" />
  <meta property="og:url" content="https://sell-skill.com/skiller-signup" />
  <meta property="og:type" content="website" />
 
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Sell-Skill | Sign Up - Learn, Teach, and Get Hired" />
  <meta name="twitter:description" content="Sign up to Sell-Skill to learn new skills, upload courses, and explore paid job opportunities. Join a thriving marketplace today!" />
  <meta name="twitter:image" content="https://sell-skill.com/logo192.png" />

 
  <link rel="canonical" href="https://sell-skill.com/skiller-signup" />
</Helmet>

      <GlobalStyle />
      <StyledContainer>
        <MainCard>
 
          <Title>Sign Up as a skiller</Title>
          <StyledForm 
           onSubmit={(e) => {
            e.preventDefault();
            handleProviderSignUp();
          }}>
            <StyledFormControl
              type="text"
              placeholder="Name"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, name: e.target.value })
              }
            />
            <StyledFormControl
              type="text"
              placeholder="Surname"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, surname: e.target.value })
              }
            />
            <StyledFormControl
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, email: e.target.value })
              }
            />
            <StyledFormControl
              type="password"
              placeholder="Password"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, password: e.target.value })
              }
            />
            <StyledFormControl
              type="password"
              placeholder="Confirm Password"
              onChange={(e) =>
                setProviderSignUpData({
                  ...providerSignUpData,
                  confirmPassword: e.target.value,
                })
              }
            />
            <SubmitButton onClick={handleProviderSignUp}>Next</SubmitButton>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <StyledLink href="/skiller-signIn">Already have an account? Sign in</StyledLink>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <GoogleLogin onSuccess={googleSuccess} />
            </div>
            {alreadyProviderExist && (
              <ErrorMessage>Email already exists</ErrorMessage>
            )}
          </StyledForm>
        </MainCard>
      </StyledContainer>
    </PageContainer>
  );
}

export default SkillerSignUp;

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light gray background */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 20px;
`;

 

const Title = styled.h1`
  color: #2c3e50; /* Dark navbar color */
  text-align: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #27ae60;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #219150; /* Darker green on hover */
  }
`;

const StyledLink = styled.a`
  color: #2c3e50;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;