import axios from 'axios';
import React, { useState } from 'react'
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid'

function AcceptInvite() {
  const skillerId = localStorage.getItem('skillerId')
    const {worldId, uniqueId }= useParams();
  const [title, setTitle] = useState('')
  const [description, setDescription ] = useState('')
  const [price, setPrice] = useState('')
    const handleSendAdvReq = async() => {
        await axios.post('https://sell-skill.com/api/endpoints/acceptInvite', {worldId, skillerId, title, description, price, notificationId: uuidv4()})
    }

  return (
 <PageContainer>
        <StyledContainer>
          <StyledCard>
            <Card.Body>
              <CardTitle>
                <b> enter your details </b>
              </CardTitle>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <StyledInput
                    type="title"
                    placeholder="Brief title..."
                    onChange={(e) => setTitle(  e.target.value )}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <StyledInput
                    type="description"
                    placeholder=" Brief description of what you can provide..."
                    onChange={(e) => setDescription(  e.target.value)}
                  />
                </Form.Group>
 
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <StyledInput
                    type="price"
                    placeholder="Price you want per student..."
                    onChange={(e) => setPrice(  e.target.value)}
                  />
                </Form.Group>
 
 
                <StyledButton
                  type="button"
                  onClick={handleSendAdvReq}
            
                >
                   Submit
                </StyledButton>
              </Form>
   
  
     
            </Card.Body>
          </StyledCard>
        </StyledContainer>
      </PageContainer>  
  )
}

export default AcceptInvite



const PageContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
  background-color: #f8f9fa; /* Optional: Add a subtle background color */
`;


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add vertical centering for inner content */
  width: 100%; /* Ensure it spans the container */
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const CardTitle = styled(Card.Title)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
`;

const StyledInput = styled(Form.Control)`
  border: 2px solid #27ae60;
  border-radius: 5px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #27ae60;
  border: none;
  padding: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #1e8449;
  }

  &:disabled {
    background-color: #bdc3c7;
  }
`;

const FooterText = styled.div`
  text-align: center;
  margin-top: 15px;

  a {
    color: #2980b9;
    text-decoration: underline;

    &:hover {
      color: #2c3e50;
    }
  }
`;

const GoogleLoginWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 20px;
  text-align: center;
`;











 
const OuterCard = styled(Card)`
  background-color: white;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 10px 0; /* Add margin to prevent content sticking to edges */
  }
`;

const InnerCard = styled(Card)`
  background-color: #f8f9fa;
  color: #2c3e50;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 576px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

const Title = styled(Card.Title)`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #27ae60;

  @media (max-width: 576px) {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-bottom: 15px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
    padding-left: 45px;
  }
`;
  
 
const Input = styled(Form.Control)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background-color: white;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for input fields */
    padding: 8px; /* Adjust padding */
  }
`;

const AddOtherButton = styled(Button)`
  width: 100%;
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;

  &:hover {
    background-color: #27ae60;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding */
  }
`;

const NextButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #27ae60;
  border-color: #27ae60;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #2c3e50;
    color: white;
  }

  @media (max-width: 576px) {
    font-size: 14px; /* Adjust font size */
    padding: 10px; /* Adjust padding */
  }
`;

