import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DefiningPage.css';
import Footer from '../Footer/Footer';
import DefiningSlider from './DefiningSlider';
import FullWidthSlider from './FullWidthSlider'
import styled from 'styled-components';
import DevFullWidthSlider from './DevFullWidthSlider';
import ProgrammingFullWidthSlider from './ProgrammingFullWidthSlider';
import DigitalMarketingFullWidthSlider from './DigitalMarketingFullWidthSlider';
import StockTradingFullWidthSlider from './StockTradingFullWidthSlider';
import UIUXFullWidthSlider from './UIUXFullWidthSlider';
import DropshippingFullWidthSlider from './DropshippingFullWidthSlider';
import { FaArrowRight, FaStar } from 'react-icons/fa';
function DefiningPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  const services = [
    {
      title: 'Master Machine Learning & Data Science – Build Real-World Skills',
      image: 'DefiningPageImages/worlds.jpeg',
      description: 'Unlock the power of AI and data with hands-on machine learning and data science bootcamps. Learn practical skills, solve real-world problems, and gain expertise in Python, algorithms, and predictive modeling. Start now and take your first step toward a high-demand career!',
      alt: 'Master Machine Learning & Data Science',
    },
    {
      title: 'Master Programming Languages – Code with Confidence',
      image: 'DefiningPageImages/advisor.jpeg',
      description: " Learn the most in-demand programming languages through hands-on bootcamps. Whether you're a beginner or looking to level up, gain practical skills in Python, JavaScript, C++, and more. Start coding, build projects, and unlock new career opportunities today!",
      alt: 'Master Programming Languages',
    },
    {
      title: 'Digital Marketing Bootcamps – Grow Brands & Drive Sales',
      image: 'DefiningPageImages/earnlearn.jpeg',
      description: 'Master digital marketing strategies with hands-on training in SEO, social media, paid ads, and content marketing. Learn how to attract customers, boost engagement, and scale businesses online. Start now and take your marketing skills to the next level!',
      alt: 'Earn while you learn',
    },
    {
      title: 'Stock Trading Bootcamps – Learn to Trade with Confidence',
      image: 'DefiningPageImages/earnlearn.jpeg',
      description: 'Master the art of stock trading with practical strategies and real-market insights. Learn technical analysis, risk management, and trading psychology to make informed decisions. Start now and take control of your financial future!',
      alt: 'Earn while you learn',
    },
    {
      title: 'UI/UX Design Bootcamps – Create Engaging Digital Experiences',
      image: 'DefiningPageImages/earnlearn.jpeg',
      description: 'Learn the principles of user interface and user experience design through hands-on projects. Master wireframing, prototyping, and design tools to craft intuitive and visually appealing digital products. Start now and build a career in UI/UX design!',
      alt: 'Earn while you learn',
    },
    {
      title: ' Dropshipping Bootcamps – Launch and Scale Your Online Store',
      image: 'DefiningPageImages/earnlearn.jpeg',
      description: 'Learn how to build a profitable dropshipping business from scratch. Master product sourcing, store setup, marketing strategies, and automation to generate passive income. Start now and turn your eCommerce ideas into reality!',
      alt: 'Earn while you learn',
    },
    

  ];



  const courses = [
    { id: 1, image: 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a', alt: 'Course 1' },
    { id: 2, image: 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a', alt: 'Course 2' },
    { id: 3, image: 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a', alt: 'Course 3' },
    { id: 4, image: 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a', alt: 'Course 4' },
    { id: 5, image: 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a', alt: 'Course 5' },
  ];

  return (
    <PageContainer>
      {/* SEO Enhancements */}
      <Helmet>
        <title>Sell-Skill: YOUR E-LEARNING PLANET</title>
        <meta name="description" content="Sell-Skill offers a platform to buy and sell digital learning resources, access expert legal and medical consultations, and explore digital marketing solutions globally and in the USA." />
        <meta name="keywords" content="digital learning, legal consulting, medical consultation, digital marketing, online courses, programming courses, remote work, USA digital services, professional consulting" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Sell-Skill: Your Go-To Platform for Digital Learning and Professional Consulting" />
        <meta property="og:description" content="Join Sell-Skill to access top digital learning courses, legal and medical consultations, and expert marketing solutions, targeting global users and USA professionals." />
        <meta property="og:image" content="images/hero-image.jpg" /> {/* Replace with an actual hero image URL */}
        <meta property="og:url" content="https://www.sell-skill.com" /> {/* Replace with your actual site URL */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sell-Skill: Digital Learning, Legal Consulting, Medical Advice & More" />
        <meta name="twitter:description" content="Access digital learning resources, legal and medical consultations, and digital marketing solutions with Sell-Skill." />
        <meta name="twitter:image" content="images/hero-image.jpg" />
        <link rel="canonical" href="https://www.sell-skill.com" />
      </Helmet>

      {/* Pop-up Modal */}
      <Modal show={showModal} onHide={handleClose}>
  <Modal.Body className="modal-content text-center">
    <h3 className="modal-title">Join Sell Skill Today</h3>
    <p>Sign up now and take the first step towards achieving your professional goals.</p>
    <Button
 
      onClick={() => {
        handleClose();
        navigate('/skiller-signUp');
      }}
    >
      Sign Up Now
    </Button>
  </Modal.Body>
</Modal>


      {/* Main Content */}
      <Container fluid style={{ padding: 0 }}>
      <div className="fullwidth-slider-container">
          <div className="fullwidth-slider">
            <FullWidthSlider />
          </div>
          {/* Sign Up/In Button */}
          <Button
            className="signup-button"
            onClick={() => navigate('/skiller-signUp')}
          >
            Sign Up/In
          </Button>
        </div>

        {/* Brand Logos Section */}
        <div className="brand-logos">
  <Container>
    <Row className="text-center">
      <Col><div className="brand-box">GET KNOWLEDGE</div></Col>
      <Col><div className="brand-box">GET MATES</div></Col>
      <Col><div className="brand-box">SHARE KNOWLEDGE</div></Col>
      <Col><div className="brand-box">GET PAID</div></Col>

    </Row>
  </Container>
</div>

 
        {/* Services Section */}
        <section className="services-section">
          <h2 className="text-center mb-4" style={{ color: '#002f6c' }}>Explore Our Top Services</h2>
          {services.map((service, index) => (
            <Row key={index} className="align-items-center mb-4 service-row">
              {index % 2 === 0 ? (
                <>
                  <Col xs={12} md={6}>
               { index === 0 &&  <DevFullWidthSlider />}
               { index === 1 &&  <ProgrammingFullWidthSlider />}
               { index === 2 &&  <DigitalMarketingFullWidthSlider />}
               { index === 3 &&  <StockTradingFullWidthSlider />}
               { index === 4 &&  <UIUXFullWidthSlider />}
               { index === 5 &&  <DropshippingFullWidthSlider />}
 
                    {/* <Card.Img variant="top" src={service.image} className="img-fluid" alt={service.alt} style={{ borderRadius: '10px' }} /> */}
                  </Col>
                  <Col xs={12} md={6}>
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Join Now
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
                
              ) : (
                <>
                  <Col xs={12} md={6} className="order-md-2">
                  { index === 0 &&  <DevFullWidthSlider />}
               { index === 1 &&  <ProgrammingFullWidthSlider />}
               { index === 2 &&  <DigitalMarketingFullWidthSlider />}
               { index === 3 &&  <StockTradingFullWidthSlider />}
               { index === 4 &&  <UIUXFullWidthSlider />}
               { index === 5 &&  <DropshippingFullWidthSlider />}
 
                  </Col>
                  <Col xs={12} md={6} className="order-md-1">
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Join Now
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </section>

        {/* Features Section */}
        <div className="features-section">
          <Container>
            <h2 className="section-title text-center">Top Rated Instructors</h2>
            <Row>
              <Col md={4}>
                <div className="feature-card">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/InstructorsProfiles%2Foriginal.avif?alt=media&token=29142d7d-e758-453b-b42b-7edcb89fc818" alt="Industry Experts" className="rounded-image" />
                  <h3 className="feature-title">Jun Wu</h3>
                  <h4 className="feature-title">Marketing Expert</h4>
                  <div>
                                              {Array.from({ length: 5 }, (_, i) => (
                                                  <FaStar
                                                      key={i}
                                                      size={12}
                                                      color={i < 4? '#f39c12' : '#dcdcdc'}
                                                  />
                                              ))}
                                          </div>
                  <p className="feature-description">
                  Jun Wu, a top marketing expert, offers hands-on bootcamps on Facebook Ads, Google Ads, and more on Sell-Skill. Learn proven strategies to boost sales and scale fast—join his bootcamps and start winning today!
                    </p>
                </div>
                
                <Button onClick = {() => navigate('/profile/73acca12-4871-4fe0-b34b-5e04c496bd2c')}>View Profile <FaArrowRight/></Button>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/InstructorsProfiles%2F238384_73462f032da4463db4e43e082f59bc9f~mv2.webp?alt=media&token=1ec8bfa6-853c-4e78-9ddb-354bbd933bfe" alt="Flexible Learning" className="rounded-image" />

                  <h3 className="feature-title">Rohit Musale</h3>

                  <h4 className="feature-title">Trading Expert</h4>
                  <div>
                                              {Array.from({ length: 5 }, (_, i) => (
                                                  <FaStar
                                                      key={i}
                                                      size={12}
                                                      color={i < 4? '#f39c12' : '#dcdcdc'}
                                                  />
                                              ))}
                                          </div>
                  <p className="feature-description">
                  Rohit Musale, a trading expert, teaches powerful strategies to analyze markets, manage risks, and maximize profits. Join his bootcamps on Sell-Skill to master trading stocks, forex, and more with real-world techniques! 
                  </p>
                </div>
                <Button onClick = {() => navigate('/profile/57be4dbe-2a32-4eb8-88ac-735b611e9265')}>View Profile <FaArrowRight/></Button>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/InstructorsProfiles%2Fimages%20(25).jpg?alt=media&token=f133cd4e-30ce-4de2-86ec-445549369b5e" alt="Flexible Learning" className="rounded-image" />
                  <h3 className="feature-title">Ryan Dhungel</h3>
                  <h4 className="feature-title">Web Dev Expert</h4>
                  <div>
                                              {Array.from({ length: 5 }, (_, i) => (
                                                  <FaStar
                                                      key={i}
                                                      size={12}
                                                      color={i < 3? '#f39c12' : '#dcdcdc'}
                                                  />
                                              ))}
                                          </div>
                  <p className="feature-description">
                  Ryan Dhungel, a web development expert, teaches React, Next.js, and full-stack development with real-world projects. Join his Sell-Skill bootcamps to level up your coding skills and build high-performance web apps!
                  </p>
                </div>
                
              <Button onClick = {() => navigate('profile/95964b55-2b08-45a3-be0d-9fff06ba7575')}>View Profile <FaArrowRight/></Button>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Features Section */}
        <div className="features-section">
          <Container>
            <h2 className="section-title text-center">As a learner you will</h2>
            <Row>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/completeLevel.jpeg" alt="Industry Experts" className="img-fluid" />
                  <h3 className="feature-title">Complete the level</h3>
                  <p className="feature-description">
                    inside that certain levels there are lessons you will go through them with your advisor and mates, after completion you can sit for an exam for that level 
                    </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/takeExam.jpeg" alt="Flexible Learning" className="img-fluid" />
                  <h3 className="feature-title">Sit for an exam</h3>
                  <p className="feature-description">
                    you will sit for a live exam, with the ability to repeat it
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/certificate.jpeg" alt="Career Growth" className="img-fluid" />
                  <h3 className="feature-title">Get a certification</h3>
                  <p className="feature-description">
                  Get a certification of completion after passing that certain level exam
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Additional Services Section */}
        <section className="additional-services text-center mt-5" style={{ padding: '40px 0', backgroundColor: '#F5F5F5' }}>
          <h2 style={{ color: '#002f6c' }}>It can't be better</h2>
          <p className="mb-4" style={{ color: '#333', fontSize: '16px', maxWidth: '800px', margin: '0 auto' }}>
           With sell skill mind blowing features it is easy to acheive your dreams in no time
          </p>
          <Row>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/advisorSystem.jpeg" className="img-fluid" alt="Professional Marketing Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Advisor system</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   With the advisor system you can acheive that certain level on that certain world and start work as an advisor on that world
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/mates.jpeg" className="img-fluid" alt="Business Strategy Consulting" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Find live time mates</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   No one can deniy the role of the people surronding you, got those like minded people on sell skill and start fighting together as a team
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/uploadWorld.jpeg" className="img-fluid" alt="Financial Planning Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Upload your own world</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   Got a skill and knowledge, sell that on sell skill
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>


         <div className="featured-professionals-section">
          <Container>
            <h2 className="section-title text-center">Your mission</h2>
            <Row>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/publisherMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as a publisher</Card.Title>
                    <Card.Text>
                      As a publisher you need take care of your world, update its content, add more and give it a real valuble information. and be carefull your students will rate your world and leave comments
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/advisorMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as an advisor</Card.Title>
                    <Card.Text>
                    As an advisor your mission is to answer all your student questions and give him the most valuable information that will help him over his all journey. you will also be rated so do your best.
                    </Card.Text>


                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/studentMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as a student</Card.Title>
                    <Card.Text>
                      As student your mission is to work harder than it was ever been, find mates and improve your self, as we give you the best enviroment of learning and people who are on your field and better than you, so after that it is just depends on you, in addition to you can become an advisor and earn while you learn. you will be rated as a mate and also as an advisor, so take carefull
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> 

 

        {/* Success Stories Section */}
         <div className="success-stories-section">
          <Container>
            <h2 className="section-title text-center">Everyting is rated so you can choose in a piece of mind</h2>
            <Row>
              <Col md={6}>
                <img src="DefiningPageImages/worldRated.jpeg" alt="Success Story 1" className="img-fluid" />
                <h4 className="success-title">Worlds are rated</h4>
                <p className="success-description">
                 You can choose the right world that fits with your needs
                </p>
              </Col>
              <Col md={6}>
                <img src="DefiningPageImages/advisorRated.jpeg" alt="Success Story 2" className="img-fluid" />
                <h4 className="success-title">Advisors are rated</h4>
                <p className="success-description">
                 You can choose the highest rate between advisors
                </p>
              </Col>
            </Row>
          </Container>
        </div> 

      
        {/* Footer Section */}
        {Footer && <Footer />}
      </Container>
    </PageContainer>
  );
}
   
export default DefiningPage;




const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;


const ActionButtons = styled.div`
margin-top: 20px;
display: flex;
gap: 10px;

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.add-mate-btn {
  background: #27ae60;
  color: white;
}

.message-btn {
  background: #2c3e50;
  color: white;
}
`;
