import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {v4 as uuidv4} from 'uuid'
function PayExamer() {

    const navigate = useNavigate()
    const {examerId, worldId}= useParams();
    const examId = uuidv4();
    const skillerId = localStorage.getItem('skillerId')
 
    const levelId = useSelector((state) => state.allow.levelId)
    const [advisorName, setAdvisorName] = useState('')
    const [advisorPrice, setAdvisorPrice] = useState('')
    const [worldName, setWorldName] =useState('')
    useEffect(() => {
        //get advisorPrice, advisorName, worldName
        const getAdvisorPriceNameWorldName = async () =>{        
            const response = await axios.get('https://sell-skill.com/api/endpoints/getExamerPriceNameWorldName', {params: {worldId, examerId}})
            setWorldName(response.data.worldName)
            setAdvisorName(response.data.examerName)
            setAdvisorPrice(response.data.examerPrice)
            }

      
            getAdvisorPriceNameWorldName();
    }, [])


    useEffect(() => {
        // Load PayPal Script
        const addPayPalScript = () => {
          if (window.paypal) {
            renderPayPalButton();
          } else {
            const script = document.createElement('script');
            
            // PayPal SDK script to include both PayPal and card payments
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;
            script.type = 'text/javascript';
            script.async = true;
            script.onload = renderPayPalButton;
            document.body.appendChild(script);
          }
        };
      
        const renderPayPalButton = () => {
          if (!advisorPrice) return; // Prevent rendering if worldPrice is not available
          window.paypal.Buttons({
            // No funding source restriction, so both PayPal and card will be available
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: advisorPrice?.toString(), // Use the worldPrice value dynamically
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
              return actions.order.capture().then(async (details) => {
                alert(`Transaction completed by ${details.payer.name.given_name}`);
try {
        await axios.post('https://sell-skill.com/api/endpoints/startExam', {
            levelId,
             worldId,
            examId,
            skillerId
        });
      navigate(`/chat/${examerId}`)
    } catch (error) {
      console.error('Error choosing advisor:', error);
    }
                window.location.reload();
              });
            },
            onError: (err) => {
              console.error('PayPal Checkout Error: ', err);
    
            },
          }).render('#paypal-button-container');
        };
      
        if (advisorPrice) {
          addPayPalScript();
        }
      }, [advisorPrice]); // Add worldPrice as a dependency
      
  return (
    <div className="payment-section" style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', maxWidth: '500px', margin: 'auto' }}>
    <Card className="payment-card" style={{ border: 'none', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <Card.Body>
        <h2 className="payment-title" style={{ color: '#2c3e50', fontWeight: 'bold', fontSize: '24px', textAlign: 'center', marginBottom: '16px' }}>Get skills from {advisorName}</h2>
        <p className="payment-description" style={{ color: '#7f8c8d', fontSize: '16px', textAlign: 'center', marginBottom: '24px' }}>
          Choose {advisorName} as your advisor for {worldName} for  <strong style={{ color: '#27ae60' }}>{advisorPrice?.toString()} $ </strong>.
        </p>
      
        <div className="payment-buttons" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <div id="paypal-button-container" className="paypal-button" style={{ flex: '1', maxWidth: '200px' }}></div>
          {/* Uncomment below if Stripe is enabled */}
          {/* <button id="stripe-button" className="stripe-button" style={{ backgroundColor: '#27ae60', color: '#fff', border: 'none', borderRadius: '4px', padding: '10px 16px', cursor: 'pointer', fontSize: '16px', transition: 'background-color 0.3s' }} onMouseOver={(e) => e.target.style.backgroundColor = '#1e8449'} onMouseOut={(e) => e.target.style.backgroundColor = '#27ae60'}>Pay with Stripe</button> */}
        </div>
      </Card.Body>
    </Card>       
  </div>
  
  )
}

export default PayExamer
