import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { FaBars, FaBell, FaChevronLeft, FaChevronRight, FaComments, FaFileAlt, FaRegStar, FaStar, FaUpload, FaUserFriends } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { setWithId } from '../../../reducers/reducers';

function ChooseURAdvisor() {
   const {worldId} =useParams();
  const skillerId = localStorage.getItem('skillerId');
  const levelId = useSelector((state) => state.allow.levelId)
  const navigate = useNavigate();
  const worldName = useSelector((state) => state.allow.worldName);
  const [advisors, setAdvisors] = useState([]);
const [sidebarMessages, setSidebarMessages] = useState([])


const handleNameClick = (withId) => {
 
  navigate(`/chat/${withId}`)

}



  const handleChooseAdvisor = async (advisorId) => {
    navigate(`/pay-advisor/${advisorId}/${worldId}`)

  };

  const handleShowAdvisorProfile = (advisorId) => {
    navigate(`/profile/${advisorId}`); // Assuming dynamic routing
  };

  const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};



  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAdvisors', {
          params: { worldId },
        });  
        console.log('advisors from getAdvisors ############################***********************  =============-=-=-=-=-=-=-=> > > >  > ',response.data)
        setAdvisors(response.data || []);
      } catch (error) {
        console.error('Error fetching advisors:', error);
      }
    };

    fetchAdvisors();
  }, [worldId]);
  const handleMessage = (mateId) => {
    setWithId(mateId)
    navigate('/chat')
    console.log(`Message mate with ID: ${mateId}`);
    // Add your logic here
};












////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
 
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};


 
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                {
                  params: {skillerId},
                  responseType: 'blob' }
            );
    
         
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    

    fetchProfilePicture();

    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1', {params: {skillerId}})
      setSidebarMessages(response.data)
 
    }
    getSendersInfo();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee



  return (
    <PageContainer>
      <Container>
    
    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>
      </Header>
  
<ContentContainer>
  
      {sidebarVisible && (
  <Sidebar>
<SidebarNav>
{ sidebarMessages.length > 0 ? (
  sidebarMessages.map((message) => (
    <ChatItem key={message._id} onClick={() => handleNameClick(message.withId)}>
     
      <ChatImage
        src={
          message?.senderPicture?.picture?.data?.data
            ? `data:${message.senderPicture.contentType};base64,${bufferToBase64(
                message.senderPicture.picture.data.data
              )}`
            : "https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a"
        }
        alt="Sender Profile"
      />
      
      <ChatDetails>
        <ChatName>{message.senderName || "Mate"}</ChatName>
        {message.lengthOfUnreadedMessages > 0 && (
          <NotificationBadge>{message.lengthOfUnreadedMessages.toString()}</NotificationBadge>
        )}
      </ChatDetails>
    </ChatItem>
  ))
) : (
  <p className="text-gray-500 text-center">No messages received.</p>
)}
</SidebarNav>
</Sidebar>

)}

     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}

<MainContent>

         <Header>
      <TabContainer>
          <Tab
              onClick={ () => navigate(`/levels/${worldId}`)}
    
          >
                Levels
          </Tab>
  
  
          <Tab
         
      active ={true}
     >
           advisors
     </Tab>
  
 
      </TabContainer>
  
       <UploadButtonWrapper>
          <UploadButton onClick={() => navigate('/upload-post-to-world')}>
              <FaUpload style={{ marginRight: '5px' }} /> Upload post
          </UploadButton>
      </UploadButtonWrapper>
  
   <PostsButtonWrapper>
          <UploadButton
              onClick={ () => navigate(`/posts/${worldId}`)}>
              <FaFileAlt style={{ marginRight: '5px' }} /> 
          </UploadButton>
      </PostsButtonWrapper>
  
      <MatesButtonWrapper>
      <UploadButton
              onClick={ () => navigate(`/mates/${worldId}`)}>
              <FaUserFriends style={{ marginRight: '5px' }} /> 
          </UploadButton>
      </MatesButtonWrapper>
  
  
 
  </Header>


            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
                
                <Row>
                    
                    {advisors.map((advisor) => {
                        const averageRate = Math.round(advisor.rate) || 0; // Assuming rate is a number
                        return (
        <Col
            key={advisor._id}
            xs={12}
            sm={advisor.length === 1 ? 8 : 6}
            md={advisor.length === 1 ? 6 : 4}
            lg={advisor.length === 1 ? 4 : 3}
            className="mb-4"
            style={advisors.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
        >
            <CardContainer>
            <Card
                style={{
                    minHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {advisor.picture && (
                      <a href={`/profile/${advisor._id}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center mt-3">
                        <Card.Img
                            variant="top"
                            src={`data:${advisor?.picture?.picture?.contentType};base64,${bufferToBase64(advisor?.picture?.picture?.data?.data)}`  }
                            alt={`${advisor.name || 'Mate'}'s profile`}
                            className="rounded-circle mx-auto"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '2px solid #ccc',
                            }}
                        />
                    </div>
                    </a>
                )}
                <Card.Body style={{ flex: 1 }}>
                <a href={`/profile/${advisor._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${advisor.name} ${advisor.surname}`}
                    </Card.Title>
                    </a>
            {  advisor.totalCash &&      <Card.Text className="text-center">
                        Total outcome: ${advisor.totalCash || 0}
                    </Card.Text>}
                    <div className="d-flex justify-content-center mb-3">
                        {Array.from({ length: 5 }, (_, i) => (
                            i < averageRate ? (
                                <FaStar key={i} color="gold" />
                            ) : (
                                <FaRegStar key={i} color="gold" />
                            )
                        ))}
                    </div>
                    {  advisor.title &&      <Card.Text className="text-center">
                        {advisor.title }
                    </Card.Text>}

                    {  advisor.description &&      <Card.Text className="text-center">
                      {advisor.description }
                    </Card.Text>}

                    
                    
                    {  advisor.price &&      <Card.Text className="text-center">
                        Price: ${advisor.price.toString()  }
                    </Card.Text>}
                    <div className="d-flex justify-content-center mb-3">
                        {advisor.sharedWorlds?.map((world) => (
                             <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                {world.thumbnail && 
                                    <img
                                        key={world._id}
                                        src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                        alt={world.name || 'World'}
                                        title={world.name || 'World'}
                                        className="rounded-circle"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'contain',
                                            margin: '5px',
                                        }}
                                    />
                                }
                            </a>
                        ))}
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success"  onClick={() => handleChooseAdvisor(advisor._id)}>
                        Choose
                    </Button>
                    <Button variant="primary" onClick={() => handleMessage(advisor._id)}>
                        Message
                    </Button>
                </Card.Footer>
            </Card>
            </CardContainer>
        </Col>
        
                        );
                    })}
                </Row>
            </Container>
        </ContentWrapper>

        </MainContent>
        </ContentContainer>


{/*                 

    <div>
      {advisors.length > 0 ? (
        <>
          <h1>Available Advisors</h1>
          <div className="advisors-container">
            {advisors.map((advisor, index) => (
              <div key={index} className="advisor-card">
                <h2>{advisor.advisorName}</h2>
                <p>Level: {advisor.advisorLevel}</p>
                <p>{advisor.description}</p>
                <button onClick={() => handleChooseAdvisor(advisor.advisorId)}>Choose</button>
                <img
                  src={advisor.profile || '/default-profile.png'} // Use default image if none
                  alt={`Profile of ${advisor.advisorName}`}
                  onClick={() => handleShowAdvisorProfile(advisor.advisorId)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="no-advisors">
          <h2>No advisors available</h2>
          <p>Please check back later or contact the course publisher.</p>
        </div>
      )}
    </div> */}
    </Container>
    </PageContainer>
  );
}

export default ChooseURAdvisor;




 

     

const CardContainer = styled.div`


`;


 

 




















































































const RatingContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;

const LevelsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;


 
const LevelCard = styled.div`
    background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease; /* Adds hover effect */

    &:hover {
        transform: translateY(-5px); /* Slight lift on hover */
    }
`;











 
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;


 

const FeedbackSection = styled.div`
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth resizing */

    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '150px' : '0')}; /* Adjust for Sidebar */
    width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')}; /* Width based on Sidebar */
    box-sizing: border-box;

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #2c3e50;
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: vertical;
    }

    button {
        background-color: #27ae60;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e8449;
        }
    }
`;



















































const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;





const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;














const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


  


const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
 width: 300px;
  position: fixed;
  top: 120px; /* Below navbar */
  left: 0;
  bottom: 0;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: 250px;
    top: 120px;
  }

  @media (max-width: 480px) {
    width: 230px;
  }














    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;
 

 







 


const ToggleContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOn }) => (isOn ? '#4caf50' : '#ccc')};
  transition: 0.4s;
  border-radius: 25px;

  &:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: ${({ isOn }) => (isOn ? '26px' : '4px')};
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;











 


 
 


 
 
const NotificationBadge = styled.div`
  position: absolute;
  top: 20px;
  right: 40px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

 

 
const ChatItem = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 25px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: #f0f0f0;
    }
`;

const ChatImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
`;

const ChatDetails = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const ChatName = styled.span`
    font-weight: bold;
    color: #333;
`;

 
 
 

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

 

const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 25px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;
const MatesButtonWrapper = styled.div`
  position: fixed;
  bottom: 115px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;

const PostsButtonWrapper = styled.div`
  position: fixed;
  bottom: 160px; /* Place it at the bottom of the screen */
  right: 60px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;
 