import React from 'react'

function MyCart() {
  return (
    <div>
      my cart
    </div>
  )
}

export default MyCart
