import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
 
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { FaBars, FaBell, FaChevronLeft, FaChevronRight, FaComments } from "react-icons/fa";


 

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  background: #0088cc;
  color: white;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
`;

const BackArrow = styled.div`
  font-size: 24px;
  cursor: pointer;
  margin-right: 15px;
`;

const ChatProfileImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
`;

 
 


const MessagesContainer = styled.div`
  flex-grow: 1;
  height: 400px; /* Fixed height */
  overflow-y: auto; /* Enables scrolling for messages */
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #e5ddd5;
`;


const MessageBubble = styled.div`
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
  color: white;
  background: ${({ isSender }) => (isSender ? "#0088cc" : "#ffffff")};
  color: ${({ isSender }) => (isSender ? "white" : "black")};
  align-self: ${({ isSender }) => (isSender ? "flex-end" : "flex-start")};
  text-align: ${({ isSender }) => (isSender ? "right" : "left")};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
`;



const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
  background: #f0f0f0;
  font-size: 14px;
`;

const AttachButton = styled.label`
  cursor: pointer;
  margin-right: 10px;
  font-size: 18px;
  color: #0088cc;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const SendButton = styled.button`
  background: #0088cc;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
`;



const Chats2 = () => {
  const skillerId = localStorage.getItem('skillerId');
  const navigate = useNavigate();
  const [messageContent, setMessageContent] = useState("");
  const [file, setFile] = useState(null); // To handle file uploads
  const [messages, setMessages] = useState([]);
  const [sidebarMessages, setSidebarMessages] = useState([]);
  const [withName, setWithName] = useState('')
  const [withPicture, setWithPicture] = useState('')
  const {withId} =    useParams();

  const handleNameClick = (withId) => {
 
    navigate(`/chat/${withId}`)

  }



////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
 
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
 
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};
 
  

const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
 
  localStorage.clear();
 
  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};






const bufferToBase64 = (buffer) => {
  if (!Array.isArray(buffer)) return '';

  // Flatten the array if it contains nested arrays
  const flatArray = buffer.flat();

  // Process the flat array in manageable chunks
  const chunkSize = 10000; // Process 10,000 bytes at a time
  let base64String = '';
  for (let i = 0; i < flatArray.length; i += chunkSize) {
      const chunk = flatArray.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
  }

  return btoa(base64String);
};

  useEffect(() => {
    const getMessages = async () => {
      const response = await axios.get(
        "https://sell-skill.com/api/endpoints/chats2",
        { params: { skillerId, withId } }
      );
 
      setMessages(response.data);
    };

    getMessages();

    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1', {params: {skillerId}})
      setSidebarMessages(response.data)
 
    }
    getSendersInfo();

    const getWithNameAndPicture = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getWithNameAndPicture', {params: {withId}})
 
      setWithName(response.data.name)
      setWithPicture(response.data.picture)

    }
    getWithNameAndPicture()

    const setAllMessagesToReaded = async () => {
      await axios.patch('https://sell-skill.com/api/endpoints/setAllMessagesToReaded', {withId, skillerId})
    }

    setAllMessagesToReaded();
    const interval = setInterval(getMessages, 5000);
    return () => clearInterval(interval);
  }, [withId]);

  const handleSendMessage = async () => {
    const messageId = uuidv4();
 
    try {
      let fileUrl = null;

      if (file) {
        const storage = getStorage();
        const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
      }

      const response = await axios.post(
        "https://sell-skill.com/api/endpoints/sendMessage",
        {
          _id: messageId,
          withId,
          skillerId,
          content: messageContent,
          document: fileUrl,
        }
      );

      if (response.data.success) {
        setMessages([
          ...messages,
          {
            _id: messageId,
            content: messageContent,
            response: true,
            document: fileUrl,
          },
        ]);
        setMessageContent("");
        setFile(null);
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
 
      alert("An error occurred. Please try again.");
    }
  };

  const renderFilePreview = (fileUrl) => {
    const fileExtension = fileUrl.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
    } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
      return <audio src={fileUrl} controls />;
    } else if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={fileUrl}
          title="PDF Preview"
          style={{ width: "100%", height: "500px" }}
        />
      );
    } else {
      return (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          Download File
        </a>
      );
    }
  };



  return (


    <PageContainer>

    <Container >
   
          <Header>
            <Navbar>
              <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
      
      
      
      
      
              <NavLinks isOpen={isMenuOpen}>
                <a href={`/my-profile/${skillerId}`}>My Profile</a>
                <a href="/manage-uploads">Uploads</a>
                <a href="/financial-management">Withdraw Cash</a>
                <a href="/my-chats">My chats</a>
                <a href="/my-exams">My Exams</a>
      
              </NavLinks>
      
              <NavIcons>
       
                <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
                <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
              </NavIcons>
      
      
              <div ref={dropdownRef}>
                {profilePictureSrc && (
                  <ProfileWrapper onClick={handleProfileClick}>
                    <ProfilePicture src={profilePictureSrc} alt="Profile" />
                  </ProfileWrapper>
                )}
                {showDropdown && (
                  <DropdownMenu>
                    <a href={`/my-profile/${skillerId}`}>My Profile</a>
                    <a href="/manage-uploads">Uploads Management</a>
                    <a href="/manage-financial">Financial Management</a>
                    <a href="/advisors-&-students">Advisors & Students</a>
                    <a href="/skiller-signIn" onClick={handleLogout}>
                      Log Out
                    </a>
                  </DropdownMenu>
                )}
              </div>
      
              <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <FaBars />
              </Hamburger>
            </Navbar>
      
      
          </Header>



          <ContentContainer>

{sidebarVisible && (
  <Sidebar>
<SidebarNav>
{ sidebarMessages.length > 0 ? (
  sidebarMessages.map((message) => (
    <ChatItem key={message._id} onClick={() => handleNameClick(message.withId)}>
     
      <ChatImage
        src={
          message?.senderPicture?.picture?.data?.data
            ? `data:${message.senderPicture.contentType};base64,${bufferToBase64(
                message.senderPicture.picture.data.data
              )}`
            : "https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a"
        }
        alt="Sender Profile"
      />
      
      <ChatDetails>
        <ChatName>{message.senderName || "Mate"}</ChatName>
        {message.lengthOfUnreadedMessages > 0 && (
          <NotificationBadge>{message.lengthOfUnreadedMessages.toString()}</NotificationBadge>
        )}
      </ChatDetails>
    </ChatItem>
  ))
) : (
  <p className="text-gray-500 text-center">No messages received.</p>
)}
</SidebarNav>
</Sidebar>

)}



{
!isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
{sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
</ToggleSidebarButton>
}


  <MainContent>

  <ContentWrapper sidebarVisible={sidebarVisible}>
<Container fluid>

  <Row>

   <ChatHeader>
  <BackArrow onClick={() => navigate("/my-chats")}>&larr;</BackArrow>
  <a href={`/profile/${withId}`}>
  <ChatProfileImage
    src={
      withPicture?.picture?.data?.data
        ? `data:${withPicture.contentType};base64,${bufferToBase64(
            withPicture.picture.data.data
          )}`
        : "https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a"
    }
    alt="User"
  />
  </a>
  <a href={`/profile/${withId}`}>
  {withName}
  </a>
</ChatHeader>

<MessagesContainer>
  {messages.length > 0 ? (
    messages.map((message) => (
      <MessageBubble key={message._id} isSender={message.response}>
        <div>{message.content}</div>
        {message.document && renderFilePreview(message.document)}
      </MessageBubble>
    ))
  ) : (
    <p style={{ textAlign: "center", color: "gray" }}>No messages received.</p>
  )}
</MessagesContainer>



<ChatInputContainer>
  <AttachButton htmlFor="fileInput">📎</AttachButton>
  <HiddenFileInput
    type="file"
    id="fileInput"
    onChange={(e) => setFile(e.target.files[0])}
  />
  <ChatInput
    type="text"
    placeholder="Type a message..."
    value={messageContent}
    onChange={(e) => setMessageContent(e.target.value)}
  />
  <SendButton onClick={handleSendMessage}>➤</SendButton>
</ChatInputContainer>



</Row>
</Container>
</ContentWrapper>
</MainContent>
</ContentContainer>
    </Container>


    </PageContainer>
  );
};

export default Chats2;



























// const Chats2 = () => {
  //   const [messageContent, setMessageContent] = useState("");
  //   const [file, setFile] = useState(null); // To handle file uploads
  //   const [messages, setMessages] = useState([]);
  //   const withId = useSelector((state) => state.allow.withId);
  
  //   // Fetch messages on component mount
  //   useEffect(() => {
  //     const getMessages = async () => {
  //       const response = await axios.get(
  //         "https://sell-skill.com/api/endpoints/chats2",
  //         { params: { withId } }
  //       );
  //       setMessages(response.data);
  //     };
  
  //     getMessages();
  
  //     // Optionally, use polling or WebSocket for real-time updates
  //     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
  //     return () => clearInterval(interval);
  //   }, [withId]);
  
  //   const handleSendMessage = async () => {
  //     const messageId = uuidv4();
  
  //     try {
  //       let fileUrl = null;
  
  //       // Upload file to Firebase if a file is selected
  //       if (file) {
  //         const storage = getStorage();
  //         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
  //         await uploadBytes(fileRef, file);
  //         fileUrl = await getDownloadURL(fileRef);
  //       }
  
  //       // Send the message to the backend
  //       const response = await axios.post(
  //         "https://sell-skill.com/api/endpoints/sendMessage",
  //         {
  //           _id: messageId,
  //           withId,
  //           content: messageContent,
  //           document: fileUrl, // Send the file URL if uploaded
  //         }
  //       );
  
  //       if (response.data.success) {
  //         // Optimistically update the UI
  //         setMessages([
  //           ...messages,
  //           {
  //             _id: messageId,
  //             content: messageContent,
  //             response: true,
  //             document: fileUrl,
  //           },
  //         ]);
  //         setMessageContent("");
  //         setFile(null);
  //       } else {
  //         alert("Failed to send message.");
  //       }
  //     } catch (error) {
  //       console.error("Error sending message:", error);
  //       alert("An error occurred. Please try again.");
  //     }
  //   };
  
  //   const renderFilePreview = (fileUrl) => {
  //     const fileExtension = fileUrl.split(".").pop().toLowerCase();
  
  //     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
  //       return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
  //     } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
  //       return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
  //     } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
  //       return <audio src={fileUrl} controls />;
  //     } else if (["pdf"].includes(fileExtension)) {
  //       return (
  //         <iframe
  //           src={fileUrl}
  //           title="PDF Preview"
  //           style={{ width: "100%", height: "500px" }}
  //         />
  //       );
  //     } else {
  //       return (
  //         <a href={fileUrl} target="_blank" rel="noopener noreferrer">
  //           Download File
  //         </a>
  //       );
  //     }
  //   };


// import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { useSelector } from "react-redux";

// const Chats2 = () => {
//   const [messageContent, setMessageContent] = useState("");
//   const [file, setFile] = useState(null); // To handle file uploads
//   const [messages, setMessages] = useState([]);
//   const withId = useSelector((state) => state.allow.withId);

//   // Fetch messages on component mount
//   useEffect(() => {
//     const getMessages = async () => {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/chats2",
//         { params: { withId } }
//       );
//       setMessages(response.data);
//     };

//     getMessages();

//     // Optionally, use polling or WebSocket for real-time updates
//     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
//     return () => clearInterval(interval);
//   }, [withId]);

//   const handleSendMessage = async () => {
//     const messageId = uuidv4();

//     try {
//       let fileUrl = null;

//       // Upload file to Firebase if a file is selected
//       if (file) {
//         const storage = getStorage();
//         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
//         await uploadBytes(fileRef, file);
//         fileUrl = await getDownloadURL(fileRef);
//       }

//       // Send the message to the backend
//       const response = await axios.post(
//         "https://sell-skill.com/api/endpoints/sendMessage",
//         {
//           _id: messageId,
//           withId,
//           content: messageContent,
//           document: fileUrl, // Send the file URL if uploaded
//         }
//       );

//       if (response.data.success) {
//         // Optimistically update the UI
//         setMessages([
//           ...messages,
//           {
//             _id: messageId,
//             content: messageContent,
//             response: true,
//             document: fileUrl,
//           },
//         ]);
//         setMessageContent("");
//         setFile(null);
//       } else {
//         alert("Failed to send message.");
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//       alert("An error occurred. Please try again.");
//     }
//   };

//   const renderFilePreview = (fileUrl) => {
//     const fileExtension = fileUrl.split(".").pop().toLowerCase();

//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
//     } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
//       return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
//     } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
//       return <audio src={fileUrl} controls />;
//     } else if (["pdf"].includes(fileExtension)) {
//       return (
//         <iframe
//           src={fileUrl}
//           title="PDF Preview"
//           style={{ width: "100%", height: "500px" }}
//         />
//       );
//     } else {
//       return (
//         <a href={fileUrl} target="_blank" rel="noopener noreferrer">
//           Download File
//         </a>
//       );
//     }
//   };

//   return (
//     <div>
 
//       {messages.length > 0 ? (
//         messages.map((message) => (
//           <div key={message._id} style={{ margin: "10px 0" }}>
//             {message.response ? (
//               <div style={{ color: "blue" }}>{message.content}</div>
//             ) : (
//               <div style={{ color: "black" }}>{message.content}</div>
//             )}
//             {message.document && renderFilePreview(message.document)}
//           </div>
//         ))
//       ) : (
//         <p>No messages received.</p>
//       )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//         style={{ width: "100%", marginBottom: "10px" }}
//       />
//       <input
//         type="file"
//         onChange={(e) => setFile(e.target.files[0])}
//         style={{ marginBottom: "10px" }}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//     </div>
//   );
// };

// export default Chats2;




















// import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { useSelector } from "react-redux";

// const Chats2 = () => {
//   const [messageContent, setMessageContent] = useState("");
//   const [file, setFile] = useState(null); // To handle file uploads
//   const [messages, setMessages] = useState([]);
//   const withId = useSelector((state) => state.allow.withId);

//   // Fetch messages on component mount
//   useEffect(() => {
//     const getMessages = async () => {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/chats2",
//         { params: { withId } }
//       );
//       setMessages(response.data);
//     };

//     getMessages();

//     // Optionally, use polling or WebSocket for real-time updates
//     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
//     return () => clearInterval(interval);
//   }, [withId]);

//   const handleSendMessage = async () => {
//     const messageId = uuidv4();

//     try {
//       let fileUrl = null;

//       // Upload file to Firebase if a file is selected
//       if (file) {
//         const storage = getStorage();
//         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
//         await uploadBytes(fileRef, file);
//         fileUrl = await getDownloadURL(fileRef);
//       }

//       // Send the message to the backend
//       const response = await axios.post(
//         "https://sell-skill.com/api/endpoints/sendMessage",
//         {
//           _id: messageId,
//           withId,
//           content: messageContent,
//           document: fileUrl, // Send the file URL if uploaded
//         }
//       );

//       if (response.data.success) {
//         // Optimistically update the UI
//         setMessages([
//           ...messages,
//           {
//             _id: messageId,
//             content: messageContent,
//             response: true,
//             document: fileUrl,
//           },
//         ]);
//         setMessageContent("");
//         setFile(null);
//       } else {
//         alert("Failed to send message.");
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//       alert("An error occurred. Please try again.");
//     }
//   };

//   return (
//     <div>
//       <h2>Send Message</h2>
//       {messages.length > 0 ? (
//         messages.map((message) => (
//           <div key={message._id} style={{ margin: "10px 0" }}>
//             {message.response ? (
//               <div style={{ color: "blue" }}>{message.content}</div>
//             ) : (
//               <div style={{ color: "black" }}>{message.content}</div>
//             )}
//             {message.document && (
//               <a href={message.document} target="_blank" rel="noopener noreferrer">
//                 View Attachment
//               </a>
//             )}
//           </div>
//         ))
//       ) : (
//         <p>No messages received.</p>
//       )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//         style={{ width: "100%", marginBottom: "10px" }}
//       />
//       <input
//         type="file"
//         onChange={(e) => setFile(e.target.files[0])}
//         style={{ marginBottom: "10px" }}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//     </div>
//   );
// };

// export default Chats2;


























// import React, { useEffect, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Button } from 'react-bootstrap';

// const Chats2 = () => {
//   const [receiverId, setReceiverId] = useState('');
//   const [messageContent, setMessageContent] = useState('');
//   const [messages, setMessages] = useState([])
//   const withId = useSelector((state) => state.allow.withId)
//   const handleNameClick = () => {

//   }

//   useEffect(() => {
//     const getMessages = async () => 
//     { 
//       console.log('withId from chats2 after useSelector ======================------------------------------>>>>> ', withId)
//       const response = await axios.get('https://sell-skill.com/api/endpoints/chats2', {params: {withId}})
//       setMessages(response.data)//map through messages, response true in a certain color and false in another
//       console.log('response.data from getMessages in chats2 ====================---------------------->>>>>> ',response.data)
//     }
// getMessages();
//   }, [])
//   const handleSendMessage = async () => {
//     const messageId = uuidv4(); // Generate unique ID for the message



//     try {
//       const response = await axios.post('https://sell-skill.com/api/endpoints/sendMessage', {
//         _id: messageId,
//         withId,
//         content: messageContent,
//       });

//       if (response.data.success) {
//         alert('Message sent successfully!');
//         setMessageContent('');
//       } else {
//         alert('Failed to send message');
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('An error occurred. Please try again.');
//     }


//   };


//   const handleGoInCall = () => {

//   }
//   return (
//     <div>
//       <h2>Send Message</h2>
//       {messages.length > 0 ? (
//                 messages.map((message) => (
//                   <div key={message._id}>
//                     {message.content && (
//                       <>
//                       {     
//                       message.response ?                  
//                      ( <div style={{color: 'black'}}>
//                         {message.content}
//                       </div>) :
//                      (
//                       <>
//                       {message.content}
//                       </>
//                      )
                      
//                     }
//                       </>

//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <p>No messages received.</p>
//               )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//       <button onClick={handleGoInCall}>go in a call</button>
//     </div>
//   );
// };

// export default Chats2;

















const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

 

const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;











 





 const MainContent = styled.div`

     margin-top: 80px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;



 




const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;

 


const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;




 


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

 
const NotificationBadge = styled.div`
  position: absolute;
  top: 20px;
  right: 40px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;


const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


 
const ChatItem = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 25px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: #f0f0f0;
    }
`;

const ChatImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
`;

const ChatDetails = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const ChatName = styled.span`
    font-weight: bold;
    color: #333;
`;

const UnreadBadge = styled.span`
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 20px;
    font-weight: bold;
`;


 
 
const MessageInputContainer = styled.div`
    display: flex;
    padding: 10px;
    background: white;
    border-top: 1px solid #ddd;
`;

const MessageInput = styled.input`
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
`;
 

const Sidebar = styled.div`
  width: 350px;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  z-index: 900;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  background: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #e8e8e8;
  }

  &.active {
    background: #27ae60;
    color: white;
  }
`;
