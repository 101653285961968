import React, { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light Gray background */
    font-family: Arial, Helvetica, sans-serif;
    color: #2c3e50; /* Dark Gray for text */
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #ffffff; /* White card background */
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50; /* Dark text for the title */
  margin-bottom: 20px;
`;

const UploadCard = styled(Card)`
  background-color: #2c3e50; /* Dark card background */
  color: #ffffff; /* White text */
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #27ae60; /* Green hover */
    color: #ffffff;
  }
`;

const UploadButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #27ae60; /* Green button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #219150; /* Darker green */
    color: #ffffff;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #2c3e50; /* Dark label text */
  cursor: pointer;
`;

const ImagePreview = styled.img`
  display: block;
  margin: 20px auto;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #27ae60; /* Green border */
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;

function PreviousExperience() {
  const [pdfExperience, setPdfExperience] = useState(null);
  const [imageExperience, setImageExperience] = useState(null);
  const navigate = useNavigate();

  const handlePDF = async (file) => {
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("id", uuidv4());

    await axios.post("https://sell-skill.com/api/endpoints/insertSkillerPdfExperience", formData);
  };

  const handleImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("id", uuidv4());

    await axios.post("https://sell-skill.com/api/endpoints/insertSkillerImageExperience", formData);
  };

  const handleNextClick = () => {
    navigate("/picture");
  };

  return (
    <PageContainer>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>Previous Experience</Title>
          <UploadCard onClick={() => document.getElementById("image-upload").click()}>
            <b>Upload Image</b>
            <Input
              type="file"
              id="image-upload"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                setImageExperience(e.target.files[0]);
                handleImage(e.target.files[0]);
              }}
            />
            <Label htmlFor="image-upload">Choose Image</Label>
          </UploadCard>
          <UploadCard onClick={() => document.getElementById("pdf-upload").click()}>
            <b>Upload PDF</b>
            <Input
              type="file"
              id="pdf-upload"
              accept="application/pdf"
              onChange={(e) => {
                setPdfExperience(e.target.files[0]);
                handlePDF(e.target.files[0]);
              }}
            />
            <Label htmlFor="pdf-upload">Choose PDF</Label>
          </UploadCard>
          {imageExperience && (
            <ImagePreview src={URL.createObjectURL(imageExperience)} alt="Preview" />
          )}
          <UploadButton onClick={handleNextClick}>Next</UploadButton>
        </MainCard>
      </StyledContainer>
    </PageContainer>
  );
}

export default PreviousExperience;