import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard, FaTimes, FaBars } from 'react-icons/fa';
import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setWithId, setWorldId } from '../../../../../../../reducers/reducers';
import { v4 as uuidv4 } from 'uuid';
import { requestNotificationPermission } from '../../../../../../../Firebase/Firebase';
import { Helmet } from 'react-helmet';

function ExplorePosts() {


    let filteredWorlds;
    let filteredMyUploads;
    let filteredMyDrafts;
    let filteredMySubscriptions;
    const [worlds, setWorlds] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Define the maximum length before truncation

    const toggleExpand = () => setIsExpanded(!isExpanded);
    const skillerId = localStorage.getItem('skillerId')
    const [activeSubTab, setActiveSubTab] = useState('exploreWorlds');
    const [matesRequests, setMatesRequests] = useState([]);
const [currentMates, setCurrentMates] = useState([]);
const [myUploads, setMyUploads] = useState([])
const [subscribedInWorlds, setSubscribedInWorlds] = useState([])          
const [isSearchVisible, setIsSearchVisible] = useState(false);
const [isSearchVisibleM, setIsSearchVisibleM] = useState(false);
const [searchTerm, setSearchTerm] = useState('');

 
    const [lengthOfUnreadedNotifications, setLengthOfUnreadedNotifications] = useState(0);
    const [lengthOfUnreadedMessages, setLengthOfUnreadedMessages] = useState(0);

const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};


const handleSearchIconClickM = () => {
    setIsSearchVisibleM(!isSearchVisibleM);
};

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

 
 
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



    const [activeTab, setActiveTab] = useState('worlds');
    const [posts, setPosts] = useState([])
    const [Aposts, setAPosts] = useState([])
    const [ASposts, setASPosts] = useState([])
    const [ASVposts, setASVPosts] = useState([])
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryM, setSearchQueryM] = useState('');
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [expandedSection, setExpandedSection] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [myEarning, setMyEarning] = useState('');
    const [myRate, setMyRate] = useState('');
    const [myCart, setMyCart] = useState([]);
    const [selectedWorldComments, setSelectedWorldComments] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [mates, setMates] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [resources, setResources] = useState([])
    const [myDrafts, setMyDrafts] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

    const [relativePeople, setRelativePeople] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });


    const [categories, setCategories] = useState([
        "Freelancing",
        "E-commerce",
        "Dropshipping",
        "Affiliate Marketing",
        "Content Creation (YouTube, TikTok, Blogging)",
        "Social Media Management",
        "Stock Trading & Investing",
        "Cryptocurrency & NFTs",
        "Online Coaching & Consulting",
        "Digital Marketing Services",
        "Selling Online Courses",
        "Print-on-Demand",
        "Virtual Assistance",
        "Real Estate Investing",
        "Passive Income Strategies",
        "Remote Work Opportunities"
    ]); // Example categories


            const [activeCategory, setActiveCategory] = useState(null);
      
        const handleCategoryClick = (category) => {
          setActiveCategory(category);
 setFilters({ ...filters, category: category });
        };


        const filteredCategories = categories.filter((cat) =>
            cat.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
    const handleMateTab = () => {
        setActiveTab('mates')
        setActiveSubTab('exploreMates')
        
    }

    const handlePostsTab = () => {
        setActiveTab('posts')
        setActiveSubTab('explorePosts')
        
    }

    const handleShowModal = (post) => {
        setSelectedPost(post);
        setResources([
          ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
          ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
          ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
          ...(post.texts || []).map((item) => ({ type: "text", content: item })),
          ...(post.quizs || []).map((item) => ({
            type: "quiz",
            content: item.content,
            choices: item.choices,
            correctAnswer: item.correctAnswer,
          })),
        ]);
    
        console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
        setShowModal(true);
      };
      const handleCloseModal = () => setShowModal(false);

 
     
 

        
  


      useEffect(() => {
 
 



    

          const fetchProfilePicture = async () => {
              try {
                  const response = await axios.get(
                      'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                      { 
                        params: {skillerId},
                        responseType: 'blob' }
                  );
        
                  console.log('Fetched blob:', response.data);
                 
                  if (response.data.size > 0) {
                      // Blob has data, create an object URL
                      const url = URL.createObjectURL(response.data);
                      setProfilePictureSrc(url);
                  } else {
                      // Blob is empty, use the Firebase image URL
                      setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                  }
              } catch (error) {
                  console.error('Error fetching profile picture:', error);
                  // Optionally set a fallback image if there's an error
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          };


        //   const getMatesGetMates = async() => {

        //     const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
 
           
        //        setMates(response.data)
        //        console.log('response.data from ******************************************** getMates getMates getMates getMates getMates******************************************* =================================-=-=-=-=-=- > > > > > > > >  ',response.data)
        //    }
        
    
  
       

 
 

          const AgetPosts = async () => {
              try {
                const response = await axios.get(
                  "https://sell-skill.com/api/endpoints/AgetPosts" , {params: {skillerId}}
             
                );
                console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
                setAPosts(response.data);
      
                console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
              } catch (error) {
                console.error("Error fetching posts:", error);
              }
            };


 

 
          


                 requestNotificationPermission();

        //    getMatesGetMates();
 
            AgetPosts();
 

      fetchProfilePicture();
 
    }, []);



  
 
 
    const clearAllCookies = () => {
        document.cookie.split(";").forEach((cookie) => {
            const cookieParts = cookie.split("=");
            const cookieName = cookieParts[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
        });
    };
    const handleUnSavePost = async(postId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/unSavePost', {postId})
    }
    const handleLogout = async () => {
        // Clear localStorage
        console.log("Before clearing localStorage:", localStorage);
        localStorage.clear();
        console.log("After clearing localStorage:", localStorage);
    
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
        console.log("Cookies after clearing:", document.cookie);
    
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

 

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);  
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };


         const handleWorldClickFromMyCart = (worldId) => {
         dispatch(setWorldId(worldId))
         navigate('/levels')
     }
     const handleNameClick = (withId) => {
         dispatch(setWithId(withId));
         navigate('/chat');
     };

    //       useEffect(() => {
    //   const getSendersInfo = async () => {
    //          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
    //          setMessages(response.data);
    //          console.log('senders ==========>>', response.data);
    //      };
    //      getSendersInfo();
    //  }, []);
    // useEffect(() => {
    //     const myEarning = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
    //         setMyEarning(response.data);
    //     };
    //     myEarning();
    // }, []);


    // useEffect(() => {
    //     const myRate = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
    //         setMyRate(response.data);
    //     };
    //     myRate();
    // }, []);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
            //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                    //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
    
     filteredWorlds = worlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    
    
    

      

    const handleWorldComments = async(worldId) => {
        setShowCommentsModal(true);
        const response =  await axios.get('https://sell-skill.com/api/endpoints/getWorldComments', {params: {worldId}})
        console.log('comments from get world comments ==================--=--------------------=-=-=-=-=-= > > > > > > > > > >   ', response.data.comments)
        setSelectedWorldComments(response.data.comments);
 
    };

    const closeCommentsModal = () => {
        setShowCommentsModal(false);
    };
    const handleToggleWorldsSection = () => {
        setActiveTab('worlds')
        setActiveSubTab('exploreWorlds')
    }

    const handlePurchaseWorldFromCart = (worldId) => {
        dispatch(setWorldId(worldId))
        navigate('/levels')
    }

    const handleDeleteWorldFromCart = async(worldId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
    }

    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

    useEffect(() => {
        const handleScroll = () => {
          // Calculate opacity based on scroll position
          const maxScroll = 300; // Adjust based on when you want it to vanish completely
          const currentScroll = window.scrollY; // Use window.scrollY to get the scroll position
          const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
    
          console.log('currentScroll:', currentScroll);
          console.log('newOpacity:', newOpacity);
    
          setOpacity(newOpacity);
        };
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const [opacity, setOpacity] = useState(1);

 
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    


    const pageContainerRef = useRef(null); // Ref for the PageContainer

  useEffect(() => {
    const handleScroll = () => {
      if (pageContainerRef.current) {
        // Calculate scroll position relative to PageContainer
        const maxScroll = 300; // Adjust based on when you want it to vanish completely
        const currentScroll = pageContainerRef.current.scrollTop;
        const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);

        console.log('currentScroll:', currentScroll);
        console.log('newOpacity:', newOpacity);

        setOpacity(newOpacity);
      }
    };

    const containerElement = pageContainerRef.current;

    if (containerElement) {
      // Add scroll listener to PageContainer
      containerElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup on component unmount
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);



  const [filteredCurrentMates, setFilteredCurrentMates] = useState([]); // Filtered mates
 
const handleSearchChangeM = (event) => {
  const query = event.target.value;
  setSearchQueryM(query);
};

// Filter function
useEffect(() => {
  if (!searchQueryM.trim()) {
    // If search query is empty, show all mates
    setFilteredCurrentMates(mates);
  } else {
    const lowerCaseQuery = searchQueryM.toLowerCase();
    const filtered = mates.filter((mate) => {
      // Check if the name or email contains the search query
      return (
        mate.name?.toLowerCase().includes(lowerCaseQuery) ||
        mate.email?.toLowerCase().includes(lowerCaseQuery)
      );
    });
    setFilteredCurrentMates(filtered);
  }
}, [searchQueryM, mates]);



  return (

    <PageContainer>

<Helmet>
      {/* Primary Meta Tags */}
      <title>Learn & Earn with Expert Bootcamps & Courses | Sell-Skill</title>
      <meta 
        name="description" 
        content="Explore expert-led bootcamps, courses, and skill-based worlds on Sell-Skill. Learn, grow, and earn by teaching others. Find the best learning experience today!"
      />
      <meta 
        name="keywords" 
        content="online courses, skill learning, bootcamps, earn by teaching, Sell-Skill, learning platform, best online courses, make money with skills" 
      />
      <meta name="author" content="Sell-Skill" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.sell-skill.com/" />

      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Learn & Earn with Expert Bootcamps & Courses | Sell-Skill" />
      <meta 
        property="og:description" 
        content="Join Sell-Skill, the ultimate platform to learn, teach, and earn! Explore high-quality bootcamps and courses tailored to your growth."
      />
      <meta property="og:url" content="https://www.sell-skill.com/" />
      <meta property="og:image" content="https://www.sell-skill.com/logo192.png" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Learn & Earn with Expert Bootcamps & Courses | Sell-Skill" />
      <meta 
        name="twitter:description" 
        content="Boost your skills with expert-led courses on Sell-Skill. Learn, grow, and start earning by teaching today!"
      />
      <meta name="twitter:image" content="https://www.sell-skill.com/logo192.png" />
      <meta name="twitter:site" content="@SellSkill" />

      {/* Schema Markup (JSON-LD) for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "EducationalOrganization",
          "name": "Sell-Skill",
          "url": "https://www.sell-skill.com/",
          "logo": "https://www.sell-skill.com/logo192.png",
 
          "description": "Sell-Skill offers world-class courses, bootcamps, and skill-based learning experiences. Learn, grow, and earn money by teaching others.",
          "offers": {
            "@type": "Offer",
            "name": "Online Courses & Bootcamps",
            "url": "https://www.sell-skill.com",
            "priceCurrency": "USD",
         
          }
        })}
      </script>
    </Helmet>




   <Header>
              <Navbar>
                <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
        
        
                <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>
        
                <NavIcons>
         
             {
                        isSearchVisible ? (
                            <input
                                type="text"
                                placeholder="Search Worlds"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        ): (
                            <FaSearch onClick={handleSearchIconClick} />
                        )
                    }
          
        
                        
          
        
                    
                  
                    <>
                    {lengthOfUnreadedNotifications > 0 &&
                    <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
                    }
                    <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
                    </>
            
            <>
            {lengthOfUnreadedMessages > 0 &&
                    <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
                    }
            <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
            </>
        
                </NavIcons>
        
        
                <div ref={dropdownRef}>
                  {profilePictureSrc && (
                    <ProfileWrapper onClick={handleProfileClick}>
                      <ProfilePicture src={profilePictureSrc} alt="Profile" />
                    </ProfileWrapper>
                  )}
                  {showDropdown && (
                    <DropdownMenu>
                      <a href={`/my-profile/${skillerId}`}>My Profile</a>
                      <a href="/manage-uploads">Uploads Management</a>
                      <a href="/manage-financial">Financial Management</a>
                      <a href="/advisors-&-students">Advisors & Students</a>
                      <a href="/my-exams">My Exams</a>
                      <a href = "/my-employees">My Cart</a>
                      <a href = "/my-employees">My Uploaded Worlds</a>
                      <a href = "/my-employees">My Subscriptions</a>
                      <a href = "/my-employees">My Employees</a>
                      <a href = "/my-employees">My Clients</a>
                      <a href="/financial-management">Withdraw Money</a>
                      <a href="/skiller-signIn" onClick={handleLogout}>
                        Log Out <FaArrowRight/>
                      </a>
                    </DropdownMenu>
                  )}
                </div>
        
                <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <FaBars />
                </Hamburger>
              </Navbar>
        
        
            </Header>
              
<Container>


<ContentContainer>


{sidebarVisible && (
            <Sidebar>
  <SidebarNav>
    <SidebarLink   className='active'>All Posts</SidebarLink>
    <SidebarLink href='/saved-posts'>Saved Posts</SidebarLink>
 
 
  </SidebarNav>
</Sidebar>

     )}


    <MainContent>

                


 
                 <ContentWrapper sidebarVisible={sidebarVisible}>
         <Container fluid>
     
        
  
           <Row>
     
             {Aposts.length > 0 ? ( Aposts.map((post) => (
               <Col md={4} key={post._id} className="mb-4">
                 <CardContainer>
                 <Card>
                 <Card.Header className="d-flex align-items-center">
     {        post.poster.picture &&                    <a href={`/profile/${post.poster._id}`} style={{ textDecoration: 'none' }}>
         <img
             src={ `data:${post.poster.picture.picture.contentType};base64,${bufferToBase64(post.poster.picture.picture.data.data)}`}
             alt="Publisher Picture"
             style={{
                 width: '40px',
                 height: '40px',
                 borderRadius: '50%',
                 marginRight: '10px',
             }}
         />
     </a>}
     
                         <div>
     {         post.poster.name &&               <a
                                 href={`/profile/${post.poster._id}`}
                                 style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                             >
                                 {post.poster.name}
                             </a>}
                             <div>
                                 {Array.from({ length: 5 }, (_, i) => (
                                     <FaStar
                                         key={i}
                                         size={12}
                                         color={i < post.poster.rate ? '#f39c12' : '#dcdcdc'}
                                     />
                                 ))}
                             </div>
                         </div>
                     </Card.Header>
                   {post.thumbnail && (
                     <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
                   )}
                   <Card.Body>
                     <Card.Title>{post.title}</Card.Title>
                     <Card.Text>{post.description}</Card.Text>
                     <Button variant="primary" >
                       Like
                     </Button>
                     <Button
                       variant="danger"
                       className="ms-2"
                   
                     >
                       Comment
                     </Button>
                     <Button
                       variant="info"
                       className="ms-2"
                       onClick={() => navigate(`/post-resources/${post.worldId}/${post._id}`)}
                     >
                       View attachements
                     </Button>
                   </Card.Body>
                 </Card>
                 </CardContainer>
               </Col>
             ))) : (
                <div  >
      
                {/* Text */}
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  No Posts For you yet! <span className="text-green-500">subscribe to some world</span> in order to see related posts!
                </h2>
                <p className="text-sm text-gray-600 mb-6">
                  Discover skills, connect with people, and grow your knowledge together.
                </p>
                {/* CTA Button */}
                <Button
                  className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600"
                  onClick={() => navigate("/worlds")}
                >
                  Explore Worlds
                </Button>
           
            </div>
             )}
           </Row>
     
           {/* Modal */}
           <Modal
       show={showModal}
       onHide={handleCloseModal}
       centered
       style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
     >
       <Modal.Header closeButton>
         <Modal.Title style={{ fontSize: "1.5rem" }}>Resources for {selectedPost?.title}</Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <ListGroup variant="flush">
           {resources?.map((res, idx) => (
             <ListGroup.Item
               key={idx}
               style={{
                 fontSize: "1.2rem", // Larger text
                 padding: "1.5rem", // Extra padding for better appearance
               }}
             >
               {res.type}:{" "}
               {res.type === "photo" && (
                 <img
                   src={res.content}
                   alt="Photo"
                   style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
                 />
               )}
               {res.type === "video" && (
                 <video
                   controls
                   style={{
                     maxWidth: "100%",
                     display: "block",
                     margin: "10px auto",
                   }}
                 >
                   <source src={res.content} type="video/mp4" />
                 </video>
               )}
               {res.type === "pdf" && (
                 <a
                   href={res.content}
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
                 >
                   View PDF
                 </a>
               )}
               {res.type === "text" && (
                 <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
               )}
               {res.type === "quiz" && (
                 <>
                   <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
                   <ul style={{ paddingLeft: "20px" }}>
                     {res.choices?.map((choice, i) => (
                       <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                         {choice}
                       </li>
                     ))}
                   </ul>
                   <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                     Correct Answer: {res.correctAnswer}
                   </p>
                 </>
               )}
             </ListGroup.Item>
           ))}
         </ListGroup>
       </Modal.Body>
       <Modal.Footer>
         <Button
           variant="secondary"
           onClick={handleCloseModal}
           style={{
             padding: "0.75rem 1.5rem",
             fontSize: "1.2rem", // Larger button
           }}
         >
           Close
         </Button>
       </Modal.Footer>
     </Modal>
     
         </Container>
         </ContentWrapper>
     
  
         </MainContent>
         </ContentContainer>
         </Container>

 
    </PageContainer>
  )
}

export default ExplorePosts


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
 
    
  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;





















const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 400px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;



const SearchBarM = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 10000;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;
    z-index: 10000;
        input {
            font-size: 12px;
        }
    }
`;



const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;






const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;


 


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height for scrolling */
 
    box-sizing: border-box;
`;



const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  background: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #e8e8e8;
  }

  &.active {
    background: #27ae60;
    color: white;
  }
`;




const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;
