import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard, FaTimes, FaBars, FaFileAlt } from 'react-icons/fa';
import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
 
import { v4 as uuidv4 } from 'uuid';
 
import { setWorldId ,setWithId} from '../../../../../reducers/reducers';
import { requestNotificationPermission } from '../../../../../Firebase/Firebase';

function MyUploadJobs() {
    let filteredWorlds;
 
    const [worlds, setWorlds] = useState([]);
    const skillerId = localStorage.getItem('skillerId')
 
 
 
      
const [isSearchVisible, setIsSearchVisible] = useState(false);
const [isSearchVisibleM, setIsSearchVisibleM] = useState(false);
const [searchTerm, setSearchTerm] = useState('');



const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};


const handleSearchIconClickM = () => {
    setIsSearchVisibleM(!isSearchVisibleM);
};

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

 
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



    const [activeTab, setActiveTab] = useState('earn');
 
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryM, setSearchQueryM] = useState('');
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [expandedSection, setExpandedSection] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [myEarning, setMyEarning] = useState('');
    const [myRate, setMyRate] = useState('');
    const [myCart, setMyCart] = useState([]);
    const [selectedWorldComments, setSelectedWorldComments] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [mates, setMates] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
 
    const [myDrafts, setMyDrafts] = useState([])
 
 
    const [relativePeople, setRelativePeople] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });


    const [categories, setCategories] = useState([
        "Freelancing",
        "E-commerce",
        "Dropshipping",
        "Affiliate Marketing",
        "Content Creation (YouTube, TikTok, Blogging)",
        "Social Media Management",
        "Stock Trading & Investing",
        "Cryptocurrency & NFTs",
        "Online Coaching & Consulting",
        "Digital Marketing Services",
        "Selling Online Courses",
        "Print-on-Demand",
        "Virtual Assistance",
        "Real Estate Investing",
        "Passive Income Strategies",
        "Remote Work Opportunities"
    ]); // Example categories


            const [activeCategory, setActiveCategory] = useState(null);
      
        const handleCategoryClick = (category) => {
          setActiveCategory(category);
 setFilters({ ...filters, category: category });
        };


 

    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
 
 
 

      /// <---- fetch worlds --->  ssssssssssssssssssssssssssssssssssss

        // Infinite scroll handler

 
     
 

        
  


      useEffect(() => {
 
 


        
          const fetchProfilePicture = async () => {
              try {
                  const response = await axios.get(
                      'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                      { 
                        params: {skillerId},
                        responseType: 'blob' }
                  );
        
   
                  if (response.data.size > 0) {
                      // Blob has data, create an object URL
                      const url = URL.createObjectURL(response.data);
                      setProfilePictureSrc(url);
                  } else {
                      // Blob is empty, use the Firebase image URL
                      setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                  }
              } catch (error) {
       
                  // Optionally set a fallback image if there's an error
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          };


        //   const getMatesGetMates = async() => {

        //     const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
 
           
        //        setMates(response.data)
        //        console.log('response.data from ******************************************** getMates getMates getMates getMates getMates******************************************* =================================-=-=-=-=-=- > > > > > > > >  ',response.data)
        //    }
        
 
 
 
 

 

      

           const getWorldCategories = async() => {
              const response = await axios.get('https://sell-skill.com/api/endpoints/getCategoriesForFilterWorlds')
              setCategories(response.data)
 
           }
           getWorldCategories();


                 requestNotificationPermission();

        //    getMatesGetMates();
 

      fetchProfilePicture();
       
    }, []);



 
    

 
    const handleLogout = async () => {
        // Clear localStorage
 
        localStorage.clear(); 
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
        console.log("Cookies after clearing:", document.cookie);
    
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

    //   useEffect(() => {
    //     const getPosts = async () => {
    //       try {
    //         const response = await axios.get(
    //           "https://sell-skill.com/api/endpoints/ASgetPosts"
           
    //         );
    //         console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
    //         setASPosts(response.data);
    
    //         console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
    //       } catch (error) {
    //         console.error("Error fetching posts:", error);
    //       }
    //     };
    //     getPosts();
    //   },[]);  


 
    // useEffect(() => {
    //     const getCart = async () => {
    //         try {
    //             const response = await axios.get('https://sell-skill.com/api/endpoints/getCart');
    //             setMyCart(response.data);
    //             const total = response.data.reduce((sum, world) => sum + (world.price || 0), 0);
    //             setTotalPrice(total); // Update the state
    //         } catch (error) {
    //             console.error('Error fetching cart:', error);
    //         }
    //     };
    //     getCart();
    // }, []);
    


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);  
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };


    const handleWorldClickFromMyCart = (worldId) => {
         dispatch(setWorldId(worldId))
         navigate('/levels')
     }

     const handleNameClick = (withId) => {
         dispatch(setWithId(withId));
         navigate('/chat');
     };

    //   useEffect(() => {
    //   const getSendersInfo = async () => {
    //          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
    //          setMessages(response.data);
    //          console.log('senders ==========>>', response.data);
    //      };
    //      getSendersInfo();
    //  }, []);
    // useEffect(() => {
    //     const myEarning = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
    //         setMyEarning(response.data);
    //     };
    //     myEarning();
    // }, []);


    // useEffect(() => {
    //     const myRate = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
    //         setMyRate(response.data);
    //     };
    //     myRate();
    // }, []);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
            //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                    //repeat it with each subTab repeat it with each subTab repeat it with each subTab    //repeat it with each subTab repeat it with each subTab repeat it with each subTab
                        //repeat it with each subTab repeat it with each subTab repeat it with each subTab
    
     filteredWorlds = worlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world?.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world?.description?.toLowerCase().includes(searchQuery.toLowerCase());
    
            const matchesCategory = !category || 
            (world.category &&
            (() => {
                try {
                    return JSON.parse(world.category).some(ct => ct.toLowerCase() === category.toLowerCase());
                } catch (error) {
                    console.error("Invalid category format in database:", world.category);
                    return false;
                }
            })());
        
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    
    
    

        
 


     

 

    const handleViewAppliers = (jobId) => {
        navigate(`/appliers/${jobId}`)
    };

 



    const handleAddToCart = async (worldId) => {
        await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
    };

 
 
    const handlePurchaseWorldFromCart = (worldId) => {
        dispatch(setWorldId(worldId))
        navigate('/levels')
    }

    const handleDeleteWorldFromCart = async(worldId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
    }

    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

    useEffect(() => {
        const handleScroll = () => {
          // Calculate opacity based on scroll position
          const maxScroll = 300; // Adjust based on when you want it to vanish completely
          const currentScroll = window.scrollY; // Use window.scrollY to get the scroll position
          const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
    
          console.log('currentScroll:', currentScroll);
          console.log('newOpacity:', newOpacity);
    
          setOpacity(newOpacity);
        };
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const [opacity, setOpacity] = useState(1);

 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    


    const pageContainerRef = useRef(null); // Ref for the PageContainer

  useEffect(() => {
    const handleScroll = () => {
      if (pageContainerRef.current) {
        // Calculate scroll position relative to PageContainer
        const maxScroll = 300; // Adjust based on when you want it to vanish completely
        const currentScroll = pageContainerRef.current.scrollTop;
        const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);

 
        setOpacity(newOpacity);
      }
    };

    const containerElement = pageContainerRef.current;

    if (containerElement) {
      // Add scroll listener to PageContainer
      containerElement.addEventListener('scroll', handleScroll);
    }

    // Cleanup on component unmount
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


 
 
// Filter function
 


    return (
        <PageContainer ref={pageContainerRef}  >
        <Container   >
    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>


        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
        <SearchContainer>
                <FaSearch onClick={handleSearchIconClick} />
                <SearchBar isVisible={isSearchVisible}>
                    <input
                        type="text"
                        placeholder="Search Worlds"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </SearchBar>
            </SearchContainer>
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>




    </Header>



                        <ContentContainer >
             {sidebarVisible && (
         <Sidebar>

      

 <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <h5 style={{ marginBottom: '20px', color: '#2c3e50' }}>Filters</h5>

   
    {/* <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:</label>
        <div className="position-relative">
            <button 
                onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                style={{
                    background: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    borderRadius: '8px',
                }}
            >
                {filters.category || 'Any'}
                <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
            </button>
            {showCategoryDropdown && (
                <ul 
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        background: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        listStyle: 'none',
                        padding: 0,
                        margin: 0,
                        zIndex: 1000,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <li 
                        style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
                        onClick={() => {
                            setFilters({ ...filters, category: '' });
                            setShowCategoryDropdown(false);
                        }}
                    >
                        Any
                    </li>
                    {categories.map((cat) => (
                        <li 
                            key={cat} 
                            style={{ padding: '10px', cursor: 'pointer', color: '#2c3e50' }}
                            onClick={() => {
                                setFilters({ ...filters, category: cat });
                                setShowCategoryDropdown(false);
                            }}
                        >
                            {cat}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    </div>  */}


{/* 
 <div className="mb-3">
      <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:</label>
      <Container fluid>
        <Row className="g-1">
          {categories.map((category, index) => (
            <Col key={index} xs={6}>
              <Button
                variant={activeCategory === category ? 'success' : 'outline-secondary'}
                onClick={() => handleCategoryClick(category)}
                style={{
                  fontSize: '8px', // Smaller font size
                  padding: '5px', // Reduced padding
                  width: '100%', // Full width for consistency
                  borderRadius: '12px', // Rounded edges
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  overflow: 'hidden', // Hide overflow
                  textOverflow: 'ellipsis', // Add ellipsis if text is too long
                }}
                title={category} // Tooltip for full category name
              >
                {category}
              </Button>
            </Col>   
          ))}
        </Row>
      </Container>
    </div>
 */}



 <div
            style={{
                background: '#f9f9f9',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
 

            {/* Category Filter */}
            <div className="mb-3">
                <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Category:                     <button
                        onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                    >
                        {filters.category || 'Any...'}
                        <FaChevronDown style={{ marginLeft: '10px', color: '#2c3e50' }} />
                    </button></label>
                <div className="position-relative">

                    {showCategoryDropdown && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                width: '100%',
                                background: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                zIndex: 1000,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {/* Search Bar */}
                            <div
                                style={{
                                    padding: '10px',
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search categories..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>

                            {/* Category List */}
                            <ul
                                style={{
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <li
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                        color: '#2c3e50',
                                    }}
                                    onClick={() => {
                                        setFilters({ ...filters, category: '' });
                                        setShowCategoryDropdown(false);
                                    }}
                                >
                                    Any
                                </li>
 
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
     

    {/* Price Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Price Range:</label>
        <div className="d-flex">
            <input 
                type="number" 
                placeholder="Min" 
                className="form-control me-2" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [Number(e.target.value) || 0, filters.priceRange[1]] 
                })} 
            />
            <input 
                type="number" 
                placeholder="Max" 
                className="form-control" 
                onChange={(e) => setFilters({ 
                    ...filters, 
                    priceRange: [filters.priceRange[0], Number(e.target.value) || 1000] 
                })} 
            />
        </div>
    </div>

    {/* Rating Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Rating:</label>
        <select
            value={filters.minRate}
            onChange={(e) => setFilters({ ...filters, minRate: Number(e.target.value) })}
            className="form-select"
        >
            <option value="0">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>
    </div>

    {/* Publish Date Range Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Publish Date:</label>
        <div className="d-flex">
            <input 
                type="date" 
                className="form-control me-2"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [new Date(e.target.value), filters.publishDateRange[1]] 
                })} 
            />

        </div>

        <div className="d-flex">
        <input 
                type="date" 
                className="form-control"
                onChange={(e) => setFilters({ 
                    ...filters, 
                    publishDateRange: [filters.publishDateRange[0], new Date(e.target.value)] 
                })} 
            />
            

        </div>


    </div>

    {/* Minimum Students Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Students:</label>
        <input 
            type="number" 
            value={filters.minStudents} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minStudents: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>

    {/* Allow Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Allow Advisors:</label>
        <select 
            value={filters.allowAdvisors === null ? 'any' : filters.allowAdvisors.toString()}
            className="form-select"
            onChange={(e) => setFilters({ ...filters, allowAdvisors: e.target.value === 'any' ? null : e.target.value === 'true' })}
        >
            <option value="any">Any</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>

    {/* Minimum Advisors Filter */}
    <div className="mb-3">
        <label style={{ color: '#2c3e50', fontWeight: 'bold' }}>Minimum Advisors:</label>
        <input 
            type="number" 
            value={filters.minAdvisors} 
            className="form-control"
            onChange={(e) => setFilters({ ...filters, minAdvisors: Number(e.target.value) })}
            placeholder="Any"
        />
    </div>
</div>


 <SectionHeader onClick={() => toggleSection('myCart')}>
                {expandedSection === 'myCart' ? <FaChevronUp /> : <FaChevronDown />}
                My Cart
            </SectionHeader>
            {expandedSection === 'myCart' &&
    myCart.map((world) => (
        <div key={world._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {world.worldName && (
                <Button
                    onClick={() => handleWorldClickFromMyCart(world._id)}
                    variant="light"
                    style={{ flexGrow: 1, marginRight: '10px' }}
                >
                    {world.worldName}
                </Button>
            )}
            {world.price && (
                <span style={{ marginRight: '10px' }}>${world.price.toFixed(2)}</span>
            )}
<FaCreditCard
    onClick={() => handlePurchaseWorldFromCart(world._id)}
    style={{ color: 'blue', cursor: 'pointer', marginRight: '10px' }}
    title="Go to Payment"
/>
            <FaTrash
                onClick={() => handleDeleteWorldFromCart(world._id)}
                style={{ color: 'red', cursor: 'pointer' }}
                title="Delete"
            />
        </div>
    ))}

            {expandedSection === 'myCart' && (
                <div style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'right' }}>
                    Total Price: ${totalPrice.toFixed(2)}
                </div>
            )}

             <SectionHeader onClick={() => toggleSection('chats')}>
                 {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
                 My Chats
             </SectionHeader>
             {expandedSection === 'chats' &&
                 messages.map((message) => (
                     <div key={message._id}>
                         {message.senderName && (
                             <Button
                                 onClick={() => handleNameClick(message.withId)}
                                 variant="light"
                                 style={{ width: '100%' }}
                             >
                                 {message.senderName}
                             </Button>
                         )}
                     </div>
                 ))}
             <SectionHeader onClick={() => toggleSection('myEarning')}>
             {expandedSection === 'myEarning' ? <FaChevronUp /> : <FaChevronDown />}
                 MY Earning
                
             </SectionHeader>
             {expandedSection === 'myEarning' &&
               (
                     <div >
                         {myEarning} $
                     </div>
                 )}

 <SectionHeader onClick={() => toggleSection('myRate')}>
     {expandedSection === 'myRate' ? <FaChevronUp /> : <FaChevronDown />}
     My Rate
 </SectionHeader>
 {expandedSection === 'myRate' && (
     <div>
         {Array.from({ length: 5 }, (_, index) => {
             if (myRate >= index + 1) {
                 return <FaStar key={index} color="gold" />;
             } else if (myRate > index && myRate < index + 1) {
                 return <FaStarHalfAlt key={index} color="gold" />;
             } else {
                 return <FaRegStar key={index} color="gold" />;
             }
         })}
     </div>
 )}
         </Sidebar>
     )}


     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}






                <MainContent style={{ marginTop: '200px', padding: '50px' }}  >
                <Header>
    <TabContainer>
        <Tab
   
            onClick={() => navigate('/worlds')}
        >
             Learn
        </Tab>

        <Tab active={activeTab === 'earn'} >
                         Earn
                    </Tab>

 

    </TabContainer>
{  !showDropdown  &&  <UploadButtonWrapper>
        <UploadButton onClick={() => navigate('/upload-job')}>
            <FaUpload style={{ marginRight: '5px' }} /> Upload Job
        </UploadButton>
    </UploadButtonWrapper>}
</Header>
 

             ( 

    <>
    
    <SubTabContainer opacity={opacity}>
            <SubTab onClick={() => navigate('/earn')}  >
                Explore Jobs 
            </SubTab>
            <SubTab  active={true}>
                My Uploads
            </SubTab>
            <SubTab  onClick={() => navigate('/saved-jobs')}>
                Saved Jobs
            </SubTab>
       
        </SubTabContainer>


    <ContentWrapper sidebarVisible={sidebarVisible}>
        <Container fluid>

            <Row>
                     
                {filteredWorlds.map((world, index) => {
  
                    return (
                        <Col key={index} xs={12} sm={12} md={12} lg={12} className="mb-4">

                            <Card style={{ overflow: 'hidden', width: '100%' }}>
                            <Card.Header className="d-flex align-items-center">
{        world?.publisher?.picture &&                    <a href={`/profile/${world?.publisher?._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${world?.publisher?.picture?.picture?.contentType};base64,${bufferToBase64(world?.publisher?.picture?.picture?.data?.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         world?.publisher?.name &&               <a
                            href={`/profile/${world?.publisher?._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {world?.publisher?.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < world?.publisher?.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>


 
                                <Card.Body>
                                    <Card.Title>{world?.title}</Card.Title>
                                    <Card.Text>{ world?.description}</Card.Text>
                                   
  <Card.Text>Price: ${world?.budget}</Card.Text>
 
 
                                { /*           world.students.length > 0 &&                        <Card.Text>
                                        <FaUserGraduate /> {world.students.length.toString()} Students

                                    </Card.Text>*/}
{                         world?.Freelancers?.length > 0 &&           <Card.Text>
                                        <FaChalkboardTeacher /> {world?.Freelancers?.length?.toString()} appliers

                                    </Card.Text>}
 
                                    <Card.Text>
                                        Date of Publish: {new Date(world?.dateOfPublish).toLocaleDateString()}
                                    </Card.Text>
        
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            onClick={() => handleViewAppliers(world._id)}
                                        >
                                            <FaArrowRight style={{ marginRight: '5px' }} />
                                           <FaFileAlt /> view appliers
                                        </Button>
                                        {world?.allowedCard && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleAddToCart(world._id)}
                                            >
                                                <FaShoppingCart style={{ marginRight: '5px' }} />
                                                Save job
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
 
 
                                    </div>
                                </Card.Body>   
                            </Card>
                  
                        </Col>
                    );
                })}
            </Row>
        </Container>
    </ContentWrapper>
 


  
    </>
    

                    
                ) 

 
 

{
    loading && (

        <div
        className="loading-circle"
        style={{
            position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
            zIndex: 1000, // Optional: Ensure it stays on top of other elements
        }}
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#27ae60"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="90 150"
                strokeDashoffset="0"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
    )
}


                </MainContent>
            </ContentContainer>

 
        </Container>
        </PageContainer>
    );
}

export default MyUploadJobs;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
 
    
  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;





















const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 400px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;



const SearchBarM = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 10000;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;
    z-index: 10000;
        input {
            font-size: 12px;
        }
    }
`;



const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;






const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;



const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height for scrolling */
 
    box-sizing: border-box;
`;