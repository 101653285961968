import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 2rem auto;
`;

const Title = styled.h2`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218c54;
  }
`;

const LinkMedia = () => {
  const [links, setLinks] = useState({
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
  });

  const skillerId = localStorage.getItem('skillerId')
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLinks((prevLinks) => ({
      ...prevLinks,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      navigate('/definig-video')
      const response = await axios.post('https://sell-skill.com/api/endpoints/SocialLinks', {links, skillerId});
      alert('Links submitted successfully!');
      console.log(response.data);

    } catch (error) {
      console.error('Error submitting links:', error);
 
    }
  };

  return (
    <Container>
      <Title>Enter Your Social Media Links</Title>
      <form onSubmit={handleSubmit}>
        {Object.keys(links).map((platform) => (
          <InputGroup key={platform}>
            <Label htmlFor={platform}>{platform.charAt(0).toUpperCase() + platform.slice(1)} *Optional</Label>
            <Input
              type="url"
              id={platform}
              name={platform}
              placeholder={`Enter your ${platform} link`}
              value={links[platform]}
              onChange={handleChange}
  
            />
          </InputGroup>
        ))}
        <Button type="submit">Submit</Button>
      </form>
    </Container>
  );
};

export default LinkMedia;
