import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {  useNavigate } from "react-router-dom";
import { setPostId } from "../../../reducers/reducers";
import styled from 'styled-components';
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { FaArrowRight, FaBars, FaBell, FaComments } from "react-icons/fa";

function UploadPostToAllLevels() {

  ////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [alreadyRateWorld, setAlreadyRateWorld] = useState(false)
const [sidebarVisible, setSidebarVisible] = useState(false)
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false)
const [alreadyRateSkiller, setAlreadyRateSkiller] = useState(false)
 const skillerId = localStorage.getItem('skillerId')
const [myCurrentAdvisorId, setMyCurrentAdvisorId] = useState('')
const [lengthOfUnreadedMessages, setLengthOfUnreadedMessages] = useState([])
const [lengthOfUnreadedNotifications, setLengthOfUnreadedNotifications] = useState([])
const [worldName, setWorldName] = useState('')
 const [worldPricee, setWorldPricee] = useState('')
const dropdownRef = useRef(null);
const [permissionToBeAnAdvisor, setPermissionToBeAnAdvisor] = useState(false)
const bufferToBase64 = (buffer) => {
  if (!Array.isArray(buffer)) return '';

  // Flatten the array if it contains nested arrays
  const flatArray = buffer.flat();

  // Process the flat array in manageable chunks
  const chunkSize = 10000; // Process 10,000 bytes at a time
  let base64String = '';
  for (let i = 0; i < flatArray.length; i += chunkSize) {
      const chunk = flatArray.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
  }

  return btoa(base64String);
};
useEffect(() => {
 
    const getMyAdvisorId = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getAMyCurrentdvisorId')
      setMyCurrentAdvisorId(response.data)
    }

    const getPermissionToBeAnAdvisor = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getToBeAdvisorPermission')
      setPermissionToBeAnAdvisor(response.data)
    }

    const getWorldName = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldName', {params: worldId})
      setWorldName(response.data.worldName)
      setWorldPricee(response.data.worldPrice)
      console.log('response.data from getWorldName =======================================-=-=-----------------------------------=-==-=-=-=--=---> > > >>  >>  > > > > > > >  ',response.data)
    }

    const checkIsAlreadyRateWorld = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkIsAlreadyRateWorld')
      setAlreadyRateWorld(response.data)
    }
    checkIsAlreadyRateWorld();
    const checkIsAlreadyRateSkiller = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkIsAlreadyRateSkiller')
      setAlreadyRateSkiller(response.data)
    }


    const  getNumberOfUnReadedMessages = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnReadedMessages', {params: {skillerId}})

      setLengthOfUnreadedMessages(response.data)

  }
 getNumberOfUnReadedMessages()
  const  getNumberOfUnreadedNotifications = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getNumberOfUnreadedNotifications', {params: {skillerId}})
      setLengthOfUnreadedNotifications(response.data)
  }
  getNumberOfUnreadedNotifications();
    const checkIfHaveAdvisor = async () => {
      
    }
    checkIsAlreadyRateSkiller();
    getWorldName();
    getPermissionToBeAnAdvisor();
    getMyAdvisorId();
 
  },[])
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                {
                  params: {skillerId},
                  responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee



  const worldId = useSelector((state) => state.allow.worldId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resources, setResources] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [posts, setPosts] = useState([]);
  const [postDescription, setPostDescription] = useState("");
  const [postThumbnail, setPostThumbnail] = useState(null);
    
  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/WgetPosts",
          { params: { worldId , skillerId} }
        );
        setPosts(response.data);

        console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    getPosts();
  }, [worldId]);

  const handleEditPost = async (postId) => {
    dispatch(setPostId(postId));
    navigate("/edit-post-to-world");
  };

  const handleDeletePost = async (postId) => {
    dispatch(setPostId(postId));
    await axios.delete("https://sell-skill.com/api/endpoints/WdeletePost", 
  { params : {worldId, postId} },
    );
  };

  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `posts/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleAddResource = async (type) => {
    let content = null;

    if (type === "photo" || type === "video" || type === "pdf") {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept =
        type === "photo" ? "image/*" : type === "video" ? "video/*" : ".pdf";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const url = await handleFileUpload(file);
          if (url) {
            setResources([...resources, { type, content: url }]);
          }
        }
      };
      fileInput.click();
    } else {
      content = prompt(`Enter ${type} content:`);
      if (content) {
        if (type === "quiz") {
          const choices = prompt("Enter quiz choices, separated by commas").split(
            ","
          );
          const correctAnswer = prompt("Enter the correct answer for the quiz");
          setResources([
            ...resources,
            { type, content, choices, correctAnswer },
          ]);
        } else {
          setResources([...resources, { type, content }]);
        }
      }
    }
  };

  const handleSubmit = async () => {
    const _id = uuidv4();
    let thumbnailUrl = null;

    if (postThumbnail) {
      thumbnailUrl = await handleFileUpload(postThumbnail);
    }

    try {
      await axios.post(
        "https://sell-skill.com/api/endpoints/WaddPost",
        {
          _id,
          worldId,
          postTitle,
          postDescription,
          postThumbnail: thumbnailUrl,
          resources,
          skillerId
        },
        { headers: { "Content-Type": "application/json" } }
      );
      alert("Post uploaded successfully!");

      setPosts((prevPosts) => [
        ...prevPosts,
        {
          _id,
          title: postTitle,
          description: postDescription,
          thumbnail: thumbnailUrl,
          resources,
        },
      ]);
    } catch (error) {
      console.error("Error uploading post:", error);
      alert("Failed to upload post.");
    }
  };













  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/WgetPosts",
          { params: { worldId } }
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    getPosts();
  }, [worldId]);


  const handleShowModal = (post) => {
    setSelectedPost(post);
    setResources([
      ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
      ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
      ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
      ...(post.texts || []).map((item) => ({ type: "text", content: item })),
      ...(post.quizs || []).map((item) => ({
        type: "quiz",
        content: item.content,
        choices: item.choices,
        correctAnswer: item.correctAnswer,
      })),
    ]);

    console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  return (
    <>
    
      <Header>
        <Navbar>
          <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
  
  
          <NavLinks isOpen={isMenuOpen}>
            <a href={`/my-profile/${skillerId}`}>My Profile</a>
            <a href="/manage-uploads">Uploads</a>
            <a href="/financial-management">Withdraw Cash</a>
            <a href="/my-chats">My chats</a>
            <a href="/my-exams">My Exams</a>
  
          </NavLinks>
  
          <NavIcons>
 
    
  
                  
    
  
              
            
    <ChatIconWrapper>
              {lengthOfUnreadedNotifications > 0 &&
              <NotificationBadge>{lengthOfUnreadedNotifications}</NotificationBadge>
              }
              <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
              </ChatIconWrapper>
      
              <ChatIconWrapper>
        {lengthOfUnreadedMessages > 0 && (
          <NotificationBadge>{lengthOfUnreadedMessages.toString()}</NotificationBadge>
        )}
        <FaComments onClick={() => navigate("/my-chats")} title="Messages" size={24} />
      </ChatIconWrapper>
          </NavIcons>
  
  
          <div ref={dropdownRef}>
            {profilePictureSrc && (
              <ProfileWrapper onClick={handleProfileClick}>
                <ProfilePicture src={profilePictureSrc} alt="Profile" />
              </ProfileWrapper>
            )}
            {showDropdown && (
              <DropdownMenu>
                <a href={`/my-profile/${skillerId}`}>My Profile</a>
                <a href="/manage-uploads">Uploads Management</a>
                <a href = "/my-cart">My Cart</a>
                <a href = "/my-subscriptions">My Subscriptions</a>
                <a href="/financial-management">Financial Management</a>
                <a href="/advisors-&-students">Advisors & Students</a>
                <a href = "/clients-&-employees">Clients & Employees</a>
                <a href="/my-exams">My Exams</a>
  
  
   
       
                <a href="/skiller-signIn" onClick={handleLogout}>
                  Log Out <FaArrowRight/>
                </a>
              </DropdownMenu>
            )}
          </div>
  
          <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FaBars />
          </Hamburger>
        </Navbar>
  
  
      </Header>
        
    <PageContainer>


      <Container>
 

    <div className="d-flex gap-3">
    {/* Sidebar */}
    <Sidebar>
      <SidebarNav>

 
      <SidebarLink   >      <button
        className="btn btn-light"
        onClick={() => handleAddResource("photo")}
      >
        Add Photo
      </button></SidebarLink>

      <SidebarLink    >      <button
        className="btn btn-light"
        onClick={() => handleAddResource("video")}
      >
        Add Video
      </button></SidebarLink>

      <SidebarLink   >      <button
        className="btn btn-light"
        onClick={() => handleAddResource("pdf")}
      >
        Add PDF
      </button></SidebarLink>

      <SidebarLink    >      <button
        className="btn btn-light"
        onClick={() => handleAddResource("text")}
      >
        Add Text
      </button></SidebarLink>

      <SidebarLink    >      <button
        className="btn btn-light"
        onClick={() => handleAddResource("quiz")}
      >
        Add Quiz
      </button></SidebarLink>

      </SidebarNav>
    </Sidebar>

<MainContainer>
    <Container>
      <h3 className="mt-4">Create Post</h3>
      <Form>
        <Form.Group className="mb-3" controlId="formPostTitle">
          <Form.Label>Post Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter post title"
            value={postTitle}
            onChange={(e) => setPostTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPostDescription">
          <Form.Label>Post Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter post description"
            value={postDescription}
            onChange={(e) => setPostDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPostThumbnail">
          <Form.Label>Post Thumbnail</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setPostThumbnail(e.target.files[0])}
          />
        </Form.Group>
        <Button variant="success" onClick={handleSubmit}>
          Submit Post
        </Button>
      </Form>

      <h3 className="mt-5">My Uploaded Posts</h3>
      {/* <Row>
        {posts.map((post) => (
          <Col md={4} key={post._id} className="mb-4">
            <Card>
              {post.thumbnail && (
                <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
              )}
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                <Button variant="primary" onClick={() => handleEditPost(post._id)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => handleDeletePost(post._id)}
                >
                  Delete
                </Button>
                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleShowModal(post)}
                >
                  View Resources
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

 */}

















 <ContentWrapper>
 <Container fluid>



              <Row>
                {posts.map((post) => (
                  <Col md={4} key={post._id} className="mb-4">
                    <CardContainer>
                    <Card>
                      {post.thumbnail && (
                        <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
                      )}
                                    <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                <Button variant="primary" onClick={() => handleEditPost(post._id)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => handleDeletePost(post._id)}
                >
                  Delete
                </Button>
                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleShowModal(post)}
                >
                  View Resources
                </Button>
              </Card.Body>
                    </Card>
                    </CardContainer>
                  </Col>
                ))}
              </Row>
</Container>
</ContentWrapper>






      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Resources for {selectedPost?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


                <ListGroup variant="flush">
                  {resources?.map((res, idx) => (
                    <ListGroup.Item key={idx}>
                      {res.type}:{" "}
                      {res.type === "photo" && <img src={res.content} alt="Photo" style={{ maxWidth: "100px" }} />}
                      {res.type === "video" && (
                        <video controls style={{ maxWidth: "200px" }}>
                          <source src={res.content} type="video/mp4" />
                        </video>
                      )}
                      {res.type === "pdf" && (
                        <a href={res.content} target="_blank" rel="noopener noreferrer">
                          View PDF
                        </a>
                      )}
                      {res.type === "text" && <p>{res.content}</p>}
                      {res.type === "quiz" && (
                        <>
                          <p>{res.content}</p>
                          <ul>
                            {res.choices?.map((choice, i) => (
                              <li key={i}>{choice}</li>
                            ))}
                          </ul>
                          <p>Correct Answer: {res.correctAnswer}</p>
                        </>
                      )}

                    </ListGroup.Item>
                  ))}
                </ListGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </MainContainer>
  </div>

  </Container>
  </PageContainer>
  </>
  );
}

export default UploadPostToAllLevels;



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;


 
const ThumbnailImage = styled.img`
  margin-top: 10px;
  width: 100%;
  max-height: 150px;
  object-fit: cover;
`;



const CardContainer = styled.div`


`;


const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;

const MainContainer = styled(Container)`
  margin-left: 220px; /* Adjust to match Sidebar width */
  margin-top: 60px;
  padding: 20px; /* Optional: Add padding for better spacing */
`;

























// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { useNavigate } from "react-router-dom";
// import { setPostId } from "../../../reducers/reducers";

// function UploadPostToAllLevels() {
// const worldId = useSelector((state) => state.allow.worldId)
// const navigate = useNavigate()
// const dispatch = useDispatch()
// useEffect(() => {
//   const getPosts = async () => {
//     try {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/WgetPosts",
//         { params: { worldId } }
//       );
//       setPosts(response.data);
//     } catch (error) {
//       console.error("Error fetching posts:", error);
//     }
//   };
//   getPosts();
// }, [worldId]);
// const handleEditPost = async(postId) => {
//   console.log('postId from handleEditPost =========================================-===---=-=-====--=> > > > >>> >>> ',postId)
//   dispatch(setPostId(postId))
//   navigate('/edit-post-to-world')

// }

// const handleDeletePost = async(postId) => {
//   console.log('postId from handleDeletePost =========================================-===---=-=-====--=> > > > >>> >>> ',postId)
//   dispatch(setPostId(postId))
//   await axios.delete('https://sell-skill.com/api/endpoints/WdeletePost', {worldId, postId})
// }
//   const [resources, setResources] = useState([]);
//   const [postTitle, setPostTitle] = useState("");
//   const [posts, setPosts] = useState([])
//   const [postDescription, setPostDescription] = useState("");
//   const [postThumbnail, setPostThumbnail] = useState(null);


//   const handleFileUpload = async (file) => {
//     try {
//       const storage = getStorage();
//       const fileRef = ref(storage, `posts/${uuidv4()}_${file.name}`);
//       await uploadBytes(fileRef, file);
//       return await getDownloadURL(fileRef);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       return null;
//     }
//   };

//   const handleAddResource = async (type) => {
//     let content = null;

//     if (type === "photo" || type === "video" || type === "pdf") {
//       const fileInput = document.createElement("input");
//       fileInput.type = "file";
//       fileInput.accept = type === "photo" ? "image/*" : type === "video" ? "video/*" : ".pdf";
//       fileInput.onchange = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//           const url = await handleFileUpload(file);
//           if (url) {
//             setResources([...resources, { type, content: url }]);
//           }
//         }
//       };
//       fileInput.click();
//     } else {
//       content = prompt(`Enter ${type} content:`);
//       if (content) {
//         if (type === "quiz") {
//           const choices = prompt("Enter quiz choices, separated by commas").split(",");
//           const correctAnswer = prompt("Enter the correct answer for the quiz");
//           setResources([...resources, { type, content, choices, correctAnswer }]);
//         } else {
//           setResources([...resources, { type, content }]);
//         }
//       }
//     }
//   };

//   const handleSubmit = async () => {
//     const _id = uuidv4();
//     let thumbnailUrl = null;

//     if (postThumbnail) {
//       thumbnailUrl = await handleFileUpload(postThumbnail);
//     }

//     try {
//       await axios.post(
//         "https://sell-skill.com/api/endpoints/WaddPost",
//         {
//           _id,
//           worldId,
//           postTitle,
//           postDescription,
//           postThumbnail: thumbnailUrl,
//           resources,
//         },
//         { headers: { "Content-Type": "application/json" } }
//       );
//       alert("Post uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading post:", error);
//       alert("Failed to upload post.");
//     }
//   };



//   return (
//     <div style={{ display: "flex", gap: "20px" }}>
//       {/* Sidebar */}
//       <div style={{ flex: 1, border: "1px solid #ccc", padding: "10px" }}>
//         <h3>Sidebar</h3>
//         <button onClick={() => handleAddResource("photo")}>Add Photo</button>
//         <button onClick={() => handleAddResource("video")}>Add Video</button>
//         <button onClick={() => handleAddResource("pdf")}>Add PDF</button>
//         <button onClick={() => handleAddResource("text")}>Add Text</button>
//         <button onClick={() => handleAddResource("quiz")}>Add Quiz</button>
//       </div>

//       {/* Main Content */}
//       <div style={{ flex: 3, border: "1px solid #ccc", padding: "10px" }}>
//         <h3>Create Post</h3>
//         <input
//           type="text"
//           placeholder="Post Title"
//           value={postTitle}
//           onChange={(e) => setPostTitle(e.target.value)}
//         />
//         <textarea
//           placeholder="Post Description"
//           value={postDescription}
//           onChange={(e) => setPostDescription(e.target.value)}
//         />
//         <input
//           type="file"
//           onChange={(e) => setPostThumbnail(e.target.files[0])}
//         />
//         <h4>Resources:</h4>
//         <ul>
//           {resources.map((res, idx) => (
//             <li key={idx}>
//               {res.type}: {res.type === "quiz" ? res.content : <a href={res.content} target="_blank" rel="noopener noreferrer">View</a>}
//             </li>
//           ))}
//         </ul>
//         <button onClick={handleSubmit}>Submit Post</button>
//       </div>


//       <div>
//       <h1>Posts</h1>
//       {posts.length === 0 ? (
//         <p>No posts available</p>
//       ) : (
//         <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" }}>
//           {posts.map((post) => (
//             <div
//               key={post._id}
//               style={{
//                 border: "1px solid #ccc",
//                 borderRadius: "5px",
//                 padding: "10px",
//                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3>{post.title}</h3>
//               {post.thumbnail && (
//                 <img
//                   src={post.thumbnail}
//                   alt={post.title}
//                   style={{ width: "100%", height: "150px", objectFit: "cover" }}
//                 />
//               )}
//               <p>{post.description}</p>
//               <p>
//                 <strong>Posted by:</strong> {post.poster.name}
//               </p>
//               <ul>
//                 {post.photos?.map((photo, idx) => (
//                   <li key={idx}>
//                     <a href={photo.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View Photo {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//                 {post.videos?.map((video, idx) => (
//                   <li key={idx}>
//                     <a href={video.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View Video {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//                 {post.pdfs?.map((pdf, idx) => (
//                   <li key={idx}>
//                     <a href={pdf.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View PDF {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//               </ul>
//               <button onClick={() => handleEditPost(post._id)}>edit post</button>
//               <button onClick={() => handleDeletePost(post._id)}>deletePost</button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//     </div>
//   );
// }

// export default UploadPostToAllLevels;













































 

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.h1`
  color: #2c3e50;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const AttachmentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;



















const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;


















 






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;






const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;
 

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;





const Sidebar = styled.div`
  width: 350px;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  z-index: 900;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  background: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #e8e8e8;
  }

  &.active {
    background: #27ae60;
    color: white;
  }
`;


const NotificationBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

const ChatIconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

 