import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const UIUXFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
           
            <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FUIUXFullWidthSlider%2F1365-original.webp?alt=media&token=b3db0dff-1645-4d99-bad9-0d3c481cce6c" alt="Slide 1" className="slider-image" />
 
                </div>

                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FUIUXFullWidthSlider%2Ffigma-new-icon.png?alt=media&token=0c7658b7-ee9f-4e30-b287-72251bd0847d" alt="Slide 1" className="slider-image" />
 
                </div>
            </Slider>
        </div>
    );
};

export default UIUXFullWidthSlider;
