import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function PostResources() {
    const {worldId, postId } = useParams();
  const [resources, setResources] = useState([]);
  const [post, setPost] = useState('')
 const skillerId = localStorage.getItem('skillerId')
  const [isCompleted, setIsCompleted] = useState(false);
 
 

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getPostResources`, {
          params: {  worldId, postId },
        });
        console.log('post to be shown ######################*********************** =======================================-=-=-=-=-=-=-=-=-=-=-=-> > >  > > > > > > >  ',response.data)
        setPost(response.data);
        setResources(response.data.resources)
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

 
    fetchResources();
  }, [postId, worldId]);

  const renderResource = (resource, index) => {
    return (
      <div
        key={index}
        className="resource-row"
        style={{
          width: '100%',
          marginBottom: '20px',
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          backgroundColor: '#f9f9f9',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {(() => {
          switch (resource.type) {
            case 'video':
              return (
                <>
                  <h3>Video</h3>
                  <video controls src={resource.url} style={{ width: '100%' }} />
                </>
              );
            case 'image':
              return (
                <>
                  <h3>Image</h3>
                  <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
                </>
              );
            case 'pdf':
              return (
                <>
                  <h3>PDF</h3>
                  <a href={resource.url} target="_blank" rel="noopener noreferrer">
                    View PDF
                  </a>
                </>
              );
            case 'text':
              return (
                <>
                  <h3>Text</h3>
                  <p>{resource.content}</p>
                </>
              );
            case 'quiz':
              return (
                <>
                  <h3>Quiz</h3>
                  <div>
                    <p>{resource.content.question}</p>
                    <ul>
                      {resource.content.choices.map((choice, i) => (
                        <li key={i}>{choice}</li>
                      ))}
                    </ul>
                  </div>
                </>
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', minHeight: '100vh', padding: '20px', boxSizing: 'border-box' }}>
          <Helmet>
      <title> Sell-Skill | {post.title}</title>
 
    </Helmet>
      <h1 style={{ textAlign: 'center', fontSize: '1.5rem', marginBottom: '20px' }}>Lesson Resources</h1>
      <div style={{ width: '100%' }}>
        {resources.map((resource, index) => renderResource(resource, index))}
      </div>
 
    </div>
  );
}

export default PostResources;







