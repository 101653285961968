  import axios from 'axios';
  import React, { useEffect, useState } from 'react';
  import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
  import { useDispatch } from 'react-redux';
  import { Link, useNavigate, useParams } from 'react-router-dom';
  import styled from 'styled-components';
  import { setAcheivementId, setWithId, setWorldId } from '../../reducers/reducers';
  import { v4 as uuidv4 } from 'uuid';
  import { FaArrowRight, FaChalkboardTeacher, FaCheckCircle, FaComments, FaFacebook, FaInstagram, FaLinkedin, FaPlayCircle, FaShoppingCart, FaStar, FaTwitter, FaUserFriends, FaUserGraduate } from 'react-icons/fa';

  const ProfileContainer = styled.div`
    font-family: 'Arial', sans-serif;
    color: #2c3e50;
    max-width: 1200px;
    margin: auto;
    background: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
      width: 100%; /* Ensure the cover spans full width */
    box-sizing: border-box;
  `;

  const CoverPhoto = styled.div`
    background-image: url(${props => props.coverImage});
    background-size: cover;
    background-position: center;
    height: 300px;
    position: relative;
    border-radius: 10px;
      width: 100%; /* Ensure the cover spans full width */
    box-sizing: border-box;
  `;

  const SocialMediaContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  a {
    color: #2c3e50; /* Default color */
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #27ae60; /* Highlight color on hover */
    }
  }
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

  const ProfilePicture = styled.img`
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 5px solid #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -75px;
    left: 20px;

  `;




  const ProfileDetails = styled.div`
    flex: 1;
    width: 100%; /* Ensure the cover spans full width */
    box-sizing: border-box;
    margin-left: 180px; /* Add left margin to accommodate the profile picture */
    @media (max-width: 768px) {
      margin-left: 0; /* Remove left margin for mobile devices */
      margin-top: 80px; /* Add space between profile picture and details */
      text-align: center; /* Center text alignment */
    }
  `;

  const Stats = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 10px;
    color: #7f8c8d;
      width: 100%; /* Ensure the cover spans full width */
    box-sizing: border-box;
  `;

  const ActionButtons = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 10px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
    }

    .add-mate-btn {
      background: #27ae60;
      color: white;
    }

    .message-btn {
      background: #2c3e50;
      color: white;
    }
  `;
 
 

  const Card = styled.div`

    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s;
    overflow: hidden;
    width: 100%; /* Ensure the cover spans full width */
    box-sizing: border-box;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
  `;
 

  function GProfile() {

    const skillerId = localStorage.getItem('skillerId')

    const [showMatesModal, setShowMatesModal] = useState(false);
    const [mates, setMates] = useState([]);
    const [loadingMates, setLoadingMates] = useState(false);
    const [matesError, setMatesError] = useState(null);
    const [certifications, setCertifications] = useState([])
    const [imageCertifications, setImageCertifications] = useState([])
    const [pdfCertifications, setPdfCertifications] = useState([])
    const [worlds, setWorlds] = useState([])
 
    // Function to fetch mates data from the backend
    const fetchMates = async () => {
      setLoadingMates(true);
      setMatesError(null);
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getCurrentMatesLength', { params: { userId: id } });
        setMates(response.data);
      } catch (error) {
        setMatesError(error.message || 'Failed to fetch mates.');
      } finally {
        setLoadingMates(false);
      }
    };
  


    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getProfile', {params: {id}});
          setProfile(response.data);
          console.log('response.data from getProfile ================-=-=-=-=-=-> > > > > > > >  ',response.data)
        } catch (err) {
 
        } finally {
          setLoading(false);
        }
      };

      fetchProfile();

 

      const fetchWorlds = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldsForProfile', {params: {id}});
          setWorlds(response.data);
          console.log('response.data from getWorldsForPRofile cheeeeck for published ===========-=-=-=-=-=-> > > > > >  ',response.data)
 
        }   catch(error){
 
          fetchWorlds();
      }finally{
          setLoading(false)
      }
        
      }
      fetchWorlds();

      /////////
 
      // const getCertifications = async () => {
      //   try {
      //     const response = await axios.get('https://sell-skill.com/api/endpoints/getCertifications', {params: {id}});
      //     setCertifications(response.data);

 
      //   } catch (err) {
 
      //   } finally {
      //     setLoading(false);
      //   }
      // }
      // getCertifications();

 

      const getImageCertifications = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getImageCertifications', {params: {id}});
          setImageCertifications(response.data);
          setCertifications([...certifications, ...response.data])
          console.log('response.data from getImageCertifications ======================-=-=-=-=-=-=-=> > > > >   ',response.data)
        } catch (err) {
 
        } finally {
          setLoading(false);
        }
      }
      getImageCertifications();


      const getPdfCertifications = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getPdfCertifications', {params: {id}});
          setPdfCertifications(response.data);
          setCertifications([...certifications, ...response.data])

          console.log('response.data from getPdfCertifications =======================-=-=-=-=-=-=-=-=-=-> > > > > > > >    ',response.data)
 
        } catch (err) {
 
        } finally {
          setLoading(false);
        }
      }
      getPdfCertifications();
   
    }, []);



    // Open the mates modal and fetch data
    const openMatesModal = () => {
      fetchMates();
      setShowMatesModal(true);
    };
  
    // Close the mates modal
    const closeMatesModal = () => {
      setShowMatesModal(false);
    };
    const [profile, setProfile] = useState(null);
    const { id } = useParams(); // This will extract the `id` from the URL
    const bufferToBase64 = (buffer) => {
      if (!Array.isArray(buffer)) return '';

      // Flatten the array if it contains nested arrays
      const flatArray = buffer.flat();

      // Process the flat array in manageable chunks
      const chunkSize = 10000; // Process 10,000 bytes at a time
      let base64String = '';
      for (let i = 0; i < flatArray.length; i += chunkSize) {
          const chunk = flatArray.slice(i, i + chunkSize);
          base64String += String.fromCharCode(...chunk);
      }

      return btoa(base64String);
  };


    const [showFullText, setShowFullText] = useState(false);
    const maxChars = 150; // Maximum number of characters to display initially

    const handleGetInWorld = (worldId) => {
      dispatch(setWorldId(worldId));
      navigate(`/levels/${worldId}`);
  };

  const handleViewWorldTrailer = (worldId) => {
      dispatch(setWorldId(worldId));
      navigate(`/world-trailer/${worldId}`);
  };

  const closeCommentsModal = () => {
    setShowCommentsModal(false);
  };






  const handleAddToCart = async (worldId) => {
      await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
  };
  const [selectedWorldComments, setSelectedWorldComments] = useState('')
  const [showCommentsModal, setShowCommentsModal] = useState(false)
  const handleWorldComments = (comments) => {
      setSelectedWorldComments(comments);
      setShowCommentsModal(true);
  };
    const toggleText = () => setShowFullText((prev) => !prev);
      const [isExpanded, setIsExpanded] = useState(false);
      const maxLength = 100; // Define the maximum length before truncation

      const toggleExpand = () => setIsExpanded(!isExpanded);
    const truncatedLetter =
      profile?.letter?.length > maxChars
        ? profile?.letter?.slice(0, maxChars) + "..."        
        : profile?.letter;
      

 
    const [loading, setLoading] = useState(true);        
    const [error, setError] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()



    const handleAddMate = async (mateId) => {
      const mateRequestId = uuidv4();
      await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId: id, mateRequestId, skillerId})
 

      // Add your logic here
  };
    if (loading) {
      return <div className="loading-spinner">Loading...</div>;
    }

 
 
    const handleGoToAcheivement = (acheivementId) => {
      dispatch(setAcheivementId(acheivementId))
      navigate('/acheivment')
      
    }

    const handleMessage = () => {
      setWithId(id)
      navigate('/chat')
    }


    
    return (
    <PageContainer>
      <ProfileContainer>
{    profile?.coverPicture?.picture?.data?.data &&    <CoverPhoto coverImage=        { `data:${profile?.coverPicture?.picture?.contentType};base64,${bufferToBase64(profile?.coverPicture?.picture?.data?.data)}` || 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a'}/>}

        <ProfileHeader>
{  profile?.picture?.picture?.data?.data && <ProfilePicture         src={ `data:${profile?.picture?.picture?.contentType};base64,${bufferToBase64(profile?.picture?.picture?.data?.data)}` || 'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a'} />}

  <ProfileDetails>
    <h1>{profile?.name} {profile?.surname}</h1>
    <CoverLetter>
      {showFullText ? profile?.letter : truncatedLetter}
      {profile?.letter?.length > maxChars && (
        <SeeMoreButton onClick={toggleText}>
          {showFullText ? "See Less" : "See More"}
        </SeeMoreButton>
      )}
    </CoverLetter>
    <Stats>
    <a href={`/current-mates/${id}`}> {/* Link to mates */}
            <FaUserFriends size={16} color="#2c3e50" style={{ marginRight: "5px" }} />
            Mates: {mates?.length || 0}
          </a>

{   profile?.cash &&     <p>Outcome: ${profile?.cash}</p>}
      </Stats>


{  skillerId &&    <ActionButtons>
  {         <button className="add-mate-btn" onClick={handleAddMate} >Add Mate</button>}
  {           <button className="message-btn" onClick={handleMessage}  >Message</button>}
            </ActionButtons>
}
            
    {/* Social Media Links */}
    <SocialMediaContainer>
      {profile?.facebook && (
        <a href={profile.facebook} target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
      )}
      {profile?.twitter && (
        <a href={profile.twitter} target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>  
      )}
      {profile?.linkedin && (
        <a href={profile.linkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      )}
      {profile?.instagram && (
        <a href={profile.instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      )}
    </SocialMediaContainer>
  </ProfileDetails>
</ProfileHeader>
        {profile?.definingVideo && (
          <Section>
            <SectionTitle>Defining Video</SectionTitle>
            <video controls style={{ width: '100%', borderRadius: '10px' }}>
              <source src={profile?.definingVideo} type="video/mp4" />
            </video>
          </Section>
        )}
        

   {   certifications?.length > 0 &&  
  <Section>
          <SectionTitle>Certifications</SectionTitle>
          <Grid>
            {certifications?.map((cert) => (
              
              <Card key={cert?._id}>
  {           cert?.thumbnail?.contentType && cert?.thumbnail?.data &&
    <Thumbnail src={cert.contentUrl} alt={cert?.name} />}
                {cert?.name}
              </Card>
            ))}
          </Grid>
        </Section>}


{   worlds.filter(w => w.publisher  && w.published )?.length> 0 &&  <Section>
          <SectionTitle>uploaded worlds</SectionTitle>
          <Grid>
            {worlds.filter(w => w.publisher && w.published )?.map((world) => (
      <CustomButton key={world._id} onClick={() => navigate(`/world-trailer/${world._id}`)}  >
      <StyledCard>
        <Thumbnail 
          src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`} 
          alt={world?.worldName} 
        />
        <CardContent>
          <h4>{world?.worldName}</h4>
          <p>{world?.worldDescription}</p>
        </CardContent>
      </StyledCard>
    </CustomButton>
            ))}
          </Grid>
        </Section>}


  {   worlds.filter(w => w.publisher)?.length > 0 &&    <Section>
          <SectionTitle>Advisor At</SectionTitle>
          <Grid>
            { worlds.filter(w => w.publisher && w.published)?.map((world) => (
      <CustomButton key={world._id} onClick={() => navigate(`/world-trailer/${world._id}`)}  >
      <StyledCard>
        <Thumbnail 
          src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`} 
          alt={world?.worldName} 
        />
        <CardContent>
          <h4>{world?.worldName}</h4>
          <p>{world?.worldDescription}</p>
        </CardContent>
      </StyledCard>
    </CustomButton>
            ))}
          </Grid>
        </Section>}

  {     worlds.filter(w => !w.publisher && w.published)?.length> 0 &&  <Section>
          <SectionTitle>Subscribed In</SectionTitle>
          <Grid>
            { worlds.filter(w => w.publisher)?.map((world) => (
      <CustomButton key={world._id} onClick={() => navigate(`/world-trailer/${world._id}`)}  >
      <StyledCard>
        <Thumbnail 
          src={`data:${world?.worldThumbnail?.contentType};base64,${bufferToBase64(world?.worldThumbnail?.data?.data)}`} 
          alt={world?.worldName} 
        />
        <CardContent>
          <h4>{world?.worldName}</h4>
          <p>{world?.worldDescription}</p>
        </CardContent>
      </StyledCard>
    </CustomButton>
            ))}
          </Grid>
        </Section>}



         
 

{
    loading && (

        <div
        className="loading-circle"
        style={{
            position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
            zIndex: 1000, // Optional: Ensure it stays on top of other elements
        }}
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#27ae60"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="90 150"
                strokeDashoffset="0"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
    )
}

 
      {/* Mates Modal */}
      <Modal show={showMatesModal} onHide={closeMatesModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingMates ? (
            <p>Loading mates...</p>
          ) : matesError ? (
            <p>{matesError}</p>
          ) : mates?.length > 0 ? (
            mates.map((mate) => (
              <MateCard key={mate._id}>
                       <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                <MateThumbnail
                  src={mate.picture}
                  alt={`${mate.name} ${mate.surname}`}
                />
                </a>
                <MateDetails>
                  <MateName>{mate.name} {mate.surname}</MateName>
                  <MateRate>⭐ {mate.rate || 'No rating'}</MateRate>
                </MateDetails>
              </MateCard>
            ))
          ) : (
            <p>No mates found.</p>
          )}
        </Modal.Body>
      </Modal>
 
 
      </ProfileContainer>
      </PageContainer>
    );
  }

  export default GProfile;





  const MateCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
`;

const MateThumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const MateDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const MateName = styled.p`
  font-weight: bold;
  margin: 0;
`;

const MateRate = styled.p`
  margin: 0;
  color: #7f8c8d;
`;


  const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box; /* Ensure consistent box sizing */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove any potential margin */

    /* No media queries needed as it will always match the screen size */
  `;



  const CoverLetter = styled.div`
    font-size: 1rem; /* Smaller font size */
    font-family: 'Georgia', serif; /* Elegant font style */
    color: #555; /* Subtle color for elegance */
    line-height: 1.5; /* Improve readability */
    text-align: justify; /* Align text for a clean format */
    margin: 10px 0; /* Add some spacing above and below */
    word-wrap: break-word; /* Prevent text from overflowing */
    max-width: 100%; /* Constrain to the available width */
    box-sizing: border-box; /* Ensure consistent sizing */
    white-space: pre-wrap; /* Preserve line breaks and wrap text */
    @media (max-width: 768px) {
      font-size: 0.9rem; /* Slightly smaller on mobile */
      margin: 10px 15px; /* Add some padding for small screens */
    }
  `;

  const SeeMoreButton = styled.button`
    background: none;
    border: none;
    color: #27ae60;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 5px;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: #2c3e50;
    }
  `;




  const ContentWrapper = styled.div`
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
    width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
    transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
    display: flex;
    flex-wrap: wrap; /* Ensure cards adjust dynamically */
    justify-content: space-between; /* Adjust cards in the available space */
  `;


      const CardContainer = styled.div`


      `;

      const CardImage = styled.img`
      width: 100%; /* Ensure full width */
      height: auto; /* Maintain aspect ratio */
      object-fit: cover;

      @media (max-width: 768px) {
          height: 200px; /* Set a fixed height for better visuals */
      }
      `;





      const Section = styled.div`
  margin: 40px 0;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  border-bottom: 3px solid #27ae60;
  display: inline-block;
  padding-bottom: 8px;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
`;

const StyledCard = styled.a`
  background: #f9f9f9;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const CardContent = styled.div`
  padding: 15px;
  h4 {
    font-size: 18px;
    color: #2c3e50;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    color: #7f8c8d;
  }
`;

 
const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`;
