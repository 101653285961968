import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaCheckCircle, FaChalkboardTeacher, FaUserGraduate, FaStar, FaCog, FaUpload, FaBars, FaBell, FaComments, FaSearch } from 'react-icons/fa';

import { useDispatch } from 'react-redux';
import { setWorldId } from '../reducers/reducers';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';


function AllUploads() {
    const [loading, setLoading] = useState(true)
    const [worlds, setWorlds] = useState([]);
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const [profilePictureSrc, setProfilePictureSrc] = useState('');
            const [sidebarVisible, setSidebarVisible] = useState(true);
            const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');





    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setSidebarVisible(false);
            } else {
                setSidebarVisible(true);
            }
        };
    
        handleResize(); // Set the initial state
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);






        const handleSearchIconClick = () => {
            setIsSearchVisible(!isSearchVisible);
        };

        const handleSearchChange = (event) => {
            setSearchQuery(event.target.value);
        };
        
        const fetchProfilePicture = async () => {
            try {
                const response = await axios.get(
                    'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                    { responseType: 'blob' }
                );
       
                console.log('Fetched blob:', response.data);
       
                if (response.data.size > 0) {
                    // Blob has data, create an object URL
                    const url = URL.createObjectURL(response.data);
                    setProfilePictureSrc(url);
                } else {
                    // Blob is empty, use the Firebase image URL
                    setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
                // Optionally set a fallback image if there's an error
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        };
        const [skillerId, setSkillerId] = useState('')
        useEffect(() => {
            const getSkillerId = async() => {
             const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
             setSkillerId(response.data)
             console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
            }
            getSkillerId();
          })
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        numberOfStudents: '',
        allowAdvisors: '',
        cashGenerated: '',
        rate: '',
        dateOfPublish: '',
    });
    const [filteredWorlds, setFilteredWorlds] = useState([]);

    const [expandedSection, setExpandedSection] = useState(null);
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const fetchWorlds = async () => {
            try {
                const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
                setWorlds(response.data);
                setLoading(false)
                setFilteredWorlds(response.data);
            } catch (error) {
                console.error('Error fetching worlds:', error);
            }
        };
        fetchWorlds();
    }, []);

    useEffect(() => {
        let results = worlds;

        if (filters.numberOfStudents) {
            results = results.filter(world => world.numberOdStudents >= parseInt(filters.numberOfStudents, 10));
        }
        if (filters.allowAdvisors !== '') {
            results = results.filter(world => world.isWorldAllowingAdvisors === (filters.allowAdvisors === 'yes'));
        }
        if (filters.cashGenerated) {
            results = results.filter(world => world.cashGenerated >= parseFloat(filters.cashGenerated));
        }
        if (filters.rate) {
            results = results.filter(world => {
                const totalRate = world.rate.reduce((sum, r) => sum + r.rate, 0);
                const averageRate = totalRate / world.rate.length || 0;
                return averageRate >= parseFloat(filters.rate);
            });
        }
        if (filters.dateOfPublish) {
            results = results.filter(
                world => new Date(world.dateOfPublish) >= new Date(filters.dateOfPublish)
            );
        }

        setFilteredWorlds(results);
    }, [filters, worlds]);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value,
        }));
    };

    const toggleSection = section => {
        setExpandedSection(expandedSection === section ? null : section);
    };
    const handelClickWorld = async (worldId) => {
        dispatch(setWorldId(worldId))
        navigate(`/edit-world/${worldId}`)
       const response =  await axios.patch('https://sell-skill.com/api/endpoints/setWorldIdInBack', {worldId})
    
    }

    const setWorldPublished = async(worldId) => {
        console.log('worldId from setWorldPublished ============-=-=-=-=-=->  > > ', worldId)
        await axios.patch('https://sell-skill.com/api/endpoints/setWorldPublished', {worldId})
    }

    const [showModal, setShowModal] = useState(false);
const [selectedWorldId, setSelectedWorldId] = useState(null);

const handleShowModal = (worldId) => {
    setSelectedWorldId(worldId);
    setShowModal(true);
};

const handleConfirmPublish = async () => {
    if (selectedWorldId) {
        await setWorldPublished(selectedWorldId);
        setShowModal(false);
        // Optionally, refresh data
        const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
        setWorlds(response.data);
        setFilteredWorlds(response.data);
    }
};

    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = async () => {
        // Clear localStorage
        console.log("Before clearing localStorage:", localStorage);
        localStorage.clear();
        console.log("After clearing localStorage:", localStorage);
    
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
        console.log("Cookies after clearing:", document.cookie);
    
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

    return (
        <PageContainer>
            <Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
                      <ContentContainer>
                {sidebarVisible && (
                    <Sidebar>
                        <FilterSection>
                            <h3>Filters</h3>

                            <Filter>
                                <label>Number of Students</label>
                                <input
                                    type="number"
                                    value={filters.numberOfStudents}
                                    onChange={e => handleFilterChange('numberOfStudents', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Allow Advisors</label>
                                <select
                                    value={filters.allowAdvisors}
                                    onChange={e => handleFilterChange('allowAdvisors', e.target.value)}
                                >
                                    <option value="">Any</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </Filter>

                            <Filter>
                                <label>Cash Generated (≥)</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={filters.cashGenerated}
                                    onChange={e => handleFilterChange('cashGenerated', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Rate (≥)</label>
                                <input
                                    type="number"
                                    step="0.1"
                                    value={filters.rate}
                                    onChange={e => handleFilterChange('rate', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Date of Publish (≥)</label>
                                <input
                                    type="date"
                                    value={filters.dateOfPublish}
                                    onChange={e => handleFilterChange('dateOfPublish', e.target.value)}
                                />
                            </Filter>
                        </FilterSection>
                    </Sidebar>
                )}

                <ToggleSidebarButton onClick={toggleSidebar}>
                    {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
                </ToggleSidebarButton>

                <MainContent>
              
         
                    <ContentWrapper sidebarVisible={sidebarVisible}>
        
        <Container fluid>
        <h1>My Uploads</h1>
            <Row>
      
    {filteredWorlds.map((world, index) => {
        const totalRate = world?.rate?.reduce((sum, r) => sum + r.rate, 0);
        const averageRate = totalRate / world?.rate?.length || 0;

        return (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <CardContainer>
         <Card style={{ overflow: 'hidden' }}>
       

                
 
                <SettingsIcon onClick={() => handelClickWorld(world._id)}>
                    <FaCog size={20} />
                </SettingsIcon>
                <Publisher>
                    <div>
                        <Rating>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
                            ))}
                        </Rating>
                    </div>
                </Publisher>
                <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
                <Info>
                    <h3>{world.worldName}</h3>
                    <p>{world.worldDescription}</p>
                    <p>{world.price} $</p>
                    <p>{world.advisorAmount}</p>
                    <p>
                        <FaUserGraduate /> {world.numberOdStudents}
                    </p>
                    <p>
                        <FaChalkboardTeacher /> {world.numberOfAdvisors}
                    </p>
                    {world.isWorldAllowingAdvisors && (
                        <p>
                            <FaCheckCircle color="green" /> Allowing Advisors
                        </p>   
                    )}
                    <p>Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}</p>
                </Info>
                {!Boolean(world.published) && (
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button
                                style={{
                                    backgroundColor: '#27ae60',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '20px',
                                    padding: '5px 15px',
                                    fontSize: '14px',
                                }}
                                onClick={() => handleShowModal(world._id)}
                            >
                                <FaUpload style={{ marginRight: '5px' }} /> Publish
                            </Button>
                        </div>
                    )}
           
            </Card>
            </CardContainer>
            </Col>
        );
    })}

</Row>
</Container>
</ContentWrapper>

<Modal show={showModal} onHide={() => setShowModal(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Publish</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to publish this world?</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmPublish}>
            Yes, Publish
        </Button>
    </Modal.Footer>
</Modal>
{
    loading && (
        <div
            className="loading-circle"
            style={{
                position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
                top: '50%', // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
                zIndex: 1000, // Optional: Ensure it stays on top of other elements
            }}
        >
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#27ae60"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeDasharray="90 150"
                    strokeDashoffset="0"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
    )
}


                </MainContent>
                </ContentContainer>
            </Container>
        </PageContainer>
    );
}

export default AllUploads;

// Styled Components






const FilterSection = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        margin-bottom: 20px;
        font-size: 18px;
        color: #2c3e50;
    }
`;

const Filter = styled.div`
    margin-bottom: 20px;

    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #34495e;
    }

    input,
    select {
        width: 100%;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 6px;
        transition: border-color 0.3s ease;

        &:focus {
            border-color: #3498db;
            outline: none;
        }
    }
`;




const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;



const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;








const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    position: relative;

    &:hover {
        transform: translateY(-10px);
    }
`;


const SettingsIcon = styled.div`
    cursor: pointer;
    color: #2c3e50;
    transition: color 0.3s ease;

    &:hover {
        color: #3498db;
    }
`;

































































const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;















const UploadButtonWrapper = styled.div`
    position: fixed; /* Makes the button stay in one position */
    top: 70px; /* Distance from the bottom of the viewport */
    right: 20px; /* Distance from the right of the viewport */
    z-index: 1000; /* Ensures it stays above other elements */
        @media (max-width: 480px) {
        top: auto; /* Reset top positioning */
        bottom: 20px; /* Position button at the bottom */
        right: 10px; /* Adjust right positioning */
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 130px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 200px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;