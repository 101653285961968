import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const DevFullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
 




                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FDevFullWidthSlider%2F5702682_1915.webp?alt=media&token=9dfb9b84-cf80-4205-a654-2ca65ba99461" alt="Slide 3" className="slider-image" />
 
                </div>




        
                <div className="slide">
                    <img src="https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/landing%20page%2FDevFullWidthSlider%2F5993822_2c2a_7.webp?alt=media&token=cc1b6851-ecde-420f-ae35-b965e228b97a" alt="Slide 3" className="slider-image" />
 
                </div>

 

            </Slider>
        </div>
    );
};

export default DevFullWidthSlider;
