import React, { useEffect, useState } from "react";
import { Button, Card, Container, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {  ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { storage } from "../../Firebase/Firebase";
import {v4 as uuidv4} from 'uuid'
const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
`;

const MainCard = styled(Card)`
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: none;

  &:hover {
    color: white;
  }
`;

const UploadButton = styled(StyledButton)`
  background-color: #27ae60;

  &:hover {
    background-color: #1e8449;
  }
`;

const SkipButton = styled(StyledButton)`
  background-color: #2c3e50;

  &:hover {
    background-color: #1a242f;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  text-align: center;
  color: #2c3e50;
  cursor: pointer;
`;

function DefiningVideo() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backendLoading, setBackendLoading] = useState(false);
  const skillerId = localStorage.getItem('skillerId')

  
  const navigate = useNavigate();

  useEffect(() => {

    const sendWelcomeNotification = async () => {
      await axios.post('https://sell-skill.com/api/endpoints/sendWelcomeNotification', {skillerId, notificationId: uuidv4()})
    }
    sendWelcomeNotification();
  }, [])

  const handleVideoUpload = async (file) => {
    try {
      setIsLoading(true);
  
      const storageRef = ref(storage, `videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading video:", error);
          setIsLoading(false);
        },
        async () => {
          const videoURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadSuccess(true);
          setIsLoading(false);

          // Proceed with backend upload
          setBackendLoading(true);
          try {
            await axios.post("https://sell-skill.com/api/endpoints/uploadVideo", { videoURL , skillerId});
            setBackendLoading(false);
            navigate("/worlds");
          } catch (error) {
            console.error("Error sending video URL to backend:", error);
            setBackendLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading video:", error);
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    navigate("/worlds");
  };

  return (
    <StyledContainer>
      <MainCard>
        <Title>Upload Your Video</Title>
        <p className="text-center">Choose a video to upload, or skip this step.</p>
        <ButtonGroup>
          <UploadButton as="label" htmlFor="file-upload">
            Choose Video
            <Input
              type="file"
              id="file-upload"
              accept="video/*"
              onChange={(e) => handleVideoUpload(e.target.files[0])}
            />
          </UploadButton>
          {isLoading && (
            <ProgressBar
              now={uploadProgress}
              label={`${Math.round(uploadProgress)}%`}
              animated
              striped
            />
          )}
          {uploadSuccess && !backendLoading && <Label>Video uploaded successfully!</Label>}
          {backendLoading && <Label>Finalizing upload, please wait...</Label>}
          <SkipButton onClick={handleNext}>Next</SkipButton>
        </ButtonGroup>
      </MainCard>
    </StyledContainer>
  );
}

export default DefiningVideo;
















// import React, { useState } from "react";
// import { Button, Card, Container, ProgressBar } from "react-bootstrap";
// import styled from "styled-components";
// import { useNavigate } from "react-router-dom";
// import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import axios from "axios";

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   padding: 20px;
//   background-color: #f9f9f9;
// `;

// const MainCard = styled(Card)`
//   background-color: white;
//   border-radius: 15px;
//   width: 100%;
//   max-width: 500px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
//   padding: 40px;
// `;

// const Title = styled.h2`
//   text-align: center;
//   color: #2c3e50;
//   margin-bottom: 20px;
//   font-size: 22px;
//   font-weight: bold;
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
//   margin-top: 20px;
// `;

// const StyledButton = styled(Button)`
//   padding: 12px;
//   font-size: 16px;
//   font-weight: bold;
//   border-radius: 5px;
//   border: none;

//   &:hover {
//     color: white;
//   }
// `;

// const UploadButton = styled(StyledButton)`
//   background-color: #27ae60;

//   &:hover {
//     background-color: #1e8449;
//   }
// `;

// const SkipButton = styled(StyledButton)`
//   background-color: #2c3e50;

//   &:hover {
//     background-color: #1a242f;
//   }
// `;

// const Input = styled.input`
//   display: none;
// `;

// const Label = styled.label`
//   display: block;
//   text-align: center;
//   color: #2c3e50;
//   cursor: pointer;
// `;

// function DefiningVideo() {
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const handleVideoUpload = async (file) => {
//     try {
//       setIsLoading(true);
//       const storage = getStorage();
//       const storageRef = ref(storage, `videos/${file.name}`);
//       const uploadTask = uploadBytesResumable(storageRef, file);

//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           setUploadProgress(progress);
//         },
//         (error) => {
//           console.error("Error uploading video:", error);
//           setIsLoading(false);
//         },
//         async () => {
//           const videoURL = await getDownloadURL(uploadTask.snapshot.ref);
//           await axios.post("https://sell-skill.com/api/endpoints/uploadVideo", { videoURL });

//           setUploadSuccess(true);
//           setIsLoading(false);
//         }
//       );
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       setIsLoading(false);
//     }
//   };

//   const handleNext = () => {
//     navigate("/worlds");
//   };

//   return (
//     <StyledContainer>
//       <MainCard>
//         <Title>Upload Your Video</Title>
//         <p className="text-center">Choose a video to upload, or skip this step.</p>
//         <ButtonGroup>
//           <UploadButton as="label" htmlFor="file-upload">
//             Choose Video
//             <Input
//               type="file"
//               id="file-upload"
//               accept="video/*"
//               onChange={(e) => handleVideoUpload(e.target.files[0])}
//             />
//           </UploadButton>
//           {isLoading && <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} animated striped />}
//           {uploadSuccess && <Label>Video uploaded successfully!</Label>}
//           <SkipButton onClick={handleNext}>Next</SkipButton>
//         </ButtonGroup>
//       </MainCard>
//     </StyledContainer>
//   );
// }

// export default DefiningVideo;
